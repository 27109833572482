import { FC, ReactNode, useRef } from 'react';
import classNames from 'classnames/bind';
import Panel from '@/components/common/Panel';
import LineLoader from '@/components/common/LineLoader';
import { themeOptions } from '@/types/theme';

import { TDefaultPageControlsConfig } from './PageControls/types';
import CollapsableHeader from './CollapsableHeader';
import { PagePanelContext } from './context';
import PageFooter from './PageFooter';

import styles from './PagePanel.module.scss';

const cn = classNames.bind(styles);

type TBasePagePanelProps = {
  title?: string;
  isLoading?: boolean;
  className?: string;
  children?: ReactNode;
  customPanelHeaderContent?: ReactNode;
};

type TPagePanelWithControlsConfigProps = TBasePagePanelProps & {
  config: TDefaultPageControlsConfig;
  customControls?: never;
};

type TPagePanelWithCustomControlsProps = TBasePagePanelProps & {
  customControls?: ReactNode;
  config?: never;
};

type TPagePanelProps = TPagePanelWithControlsConfigProps | TPagePanelWithCustomControlsProps;

type TPagePanelExtensions = {
  CollapsableHeader: typeof CollapsableHeader;
};

// TODO: NEWFLOW need remove this component after approve new flow
const PagePanel: FC<TPagePanelProps> & TPagePanelExtensions = ({
  title,
  className,
  children,
  isLoading = false,
  customControls = null,
  customPanelHeaderContent = null,
  config,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Panel className={cn('page-panel', className)} theme={themeOptions.light}>
      <Panel.Header className={cn('page-panel__header')} wrapClassName={cn('page-panel__header-wrap')}>
        {customPanelHeaderContent && customPanelHeaderContent}
        {!customPanelHeaderContent && (
          <>
            {title}
            <LineLoader isLoading={isLoading} className={cn('page-panel__loader')} />
          </>
        )}
      </Panel.Header>
      <PagePanelContext.Provider value={scrollRef}>
        <Panel.Content className={cn('page-panel__content')} innerRef={scrollRef}>
          {children}
        </Panel.Content>
      </PagePanelContext.Provider>
      <div className={cn('page-panel__footer')}>
        {customControls}
        <PageFooter config={config} />
      </div>
    </Panel>
  );
};

PagePanel.CollapsableHeader = CollapsableHeader;

export default PagePanel;
