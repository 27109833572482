import { FC } from 'react';
import classnames from 'classnames/bind';

import { formatDurationText } from '@/helpers/runDesigns/timeline';

import styles from './TimelineScanTime.module.scss';
import { TTiming } from './types';

const cn = classnames.bind(styles);

type TScanTypeProps = {
  className?: string;
  timing: TTiming;
};

const TimelineScanTime: FC<TScanTypeProps> = ({ className, timing }) => (
  <div className={cn('scan-time', className)}>
    <span className={cn('scan-time__min-sec', className)}>{formatDurationText({ duration: timing.duration })}</span>
    <span className={cn('scan-time__hours', className)}>{formatDurationText({ duration: timing.start })}</span>
  </div>
);
export default TimelineScanTime;
