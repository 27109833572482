import { FC } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import { TRenderSideComponentProps } from '@/components/runDesign/PanelWithFolding/types';

import styles from './FovSideBlock.module.scss';

const cn = classnames.bind(styles);

const FovSideBlockContent: FC<TRenderSideComponentProps> = () => (
  <div className={cn('fov-content')}>
    <div>FOV COUNT</div>
    <div className={cn('fov-content__info')}>
      <icons.FovFillIcon /> <span>300</span>
    </div>
  </div>
);

export default FovSideBlockContent;
