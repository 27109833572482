import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import styles from './LaneProgress.module.scss';

const cn = classnames.bind(styles);

type TLaneProgressProps = {
  laneId: string;
  laneLetter: string;
  progress: number;
  isSelected: boolean;
  disabled: boolean;
  className?: string;
  onClick?: (laneId: string, isSelected: boolean) => void;
  isShowPercentage?: boolean;
  isShowPin?: boolean;
};

const LaneProgress: FC<TLaneProgressProps> = ({
  laneId,
  laneLetter,
  onClick,
  disabled,
  isSelected,
  progress,
  className,
  isShowPercentage,
  isShowPin,
}) => {
  const handleClick = useCallback(() => {
    onClick?.(laneId, isSelected);
  }, [laneId, isSelected, onClick]);

  return (
    <button
      onClick={handleClick}
      className={cn(
        'lane-progress',
        {
          'lane-progress_selected': isSelected,
          'lane-progress_disabled': disabled,
        },
        className
      )}
    >
      <div className={cn('lane-progress__lane-letter')}>{laneLetter}</div>
      <div className={cn('lane-progress__bar-wrapper')}>
        <div className={cn('lane-progress__pin', { _hidden: !isShowPin })} />
        <div className={cn('lane-progress__progressbar', { '_full-width': !isShowPin })}>
          <div className={cn('lane-progress__progress')} style={{ width: `${progress}%` }} />
        </div>
        {isShowPercentage && <div className={cn('lane-progress__percentage')}>{`${progress}%`}</div>}
      </div>
    </button>
  );
};

export default memo(LaneProgress);
