import { SVGProps } from 'react';

const IncubationSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M23.3847 20.6914C23.3847 17.0509 23.3847 14.8997 23.3847 10.7828C23.3847 5.37943 18.9764 0.9991 13.5385 0.9991C8.10066 0.999101 3.69239 5.37943 3.69239 10.7828C3.69239 14.8997 3.69236 18.4161 3.69241 20.6914"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <circle
      cx="13.5379"
      cy="10.845"
      r="3.07692"
      transform="rotate(-90 13.5379 10.845)"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M7.38978 15.1601C7.38978 15.1601 7.39915 17.9034 8.76615 19.2891C10.1332 20.6747 12.8952 20.6654 12.8952 20.6654C12.8952 20.6654 12.8952 17.9127 11.5188 16.5364C10.1425 15.1601 7.38978 15.1601 7.38978 15.1601Z"
      fill="currentColor"
    />
    <path
      d="M19.6626 15.1494C19.6626 15.1494 19.6532 17.8972 18.284 19.2852C16.9147 20.6731 14.1481 20.6638 14.1481 20.6638C14.1481 20.6638 14.1481 17.9066 15.5267 16.5279C16.9053 15.1493 19.6626 15.1494 19.6626 15.1494Z"
      fill="currentColor"
    />
    <path d="M0 25L27.0769 25" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default IncubationSmallIcon;
