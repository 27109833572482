import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';
import ConsumableHeader from '@/components/runDesign/ConsumableComponent/ConsumableHeader';

import { isDefined } from '@/helpers/typeGuards';
import styles from './MinimizedCellType.module.scss';

const cn = classnames.bind(styles);

type TMinimizedCellType = {
  runDesignCardIndex: number;
  isExpandMode?: boolean;
};

const MinimizedCellType: FC<TMinimizedCellType> = ({ runDesignCardIndex, isExpandMode }) => {
  const cellTypes = useSelector(experimentRunDesignSelectors.selectCellTypeListByIndex(runDesignCardIndex));
  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);
  const showFullLabels = useMemo(() => isExpandMode || cellTypesCount === 1, [isExpandMode, cellTypesCount]);
  const maxPreLabelingCount = useMemo(() => {
    const res = cellTypes.map((cellType) => cellType?.preLabelings?.length ?? 0);
    return Math.max(...res);
  }, [cellTypes]);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('minimized-cell-type__table')}
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column className={cn('minimized-cell-type__header-title')}>Cell name</RunDesignTable.Column>
          {Array.from({ length: maxPreLabelingCount }, (_, index) => index).map((index) => (
            <RunDesignTable.Column key={index}>
              <ConsumableHeader
                className={cn({
                  'minimized-cell-type__header-title_no-uppercase': !showFullLabels,
                })}
              >
                {showFullLabels ? 'Pre-Labeling' : 'pLBL'}
                {maxPreLabelingCount ? ` ${index + 1}` : ''}
              </ConsumableHeader>
            </RunDesignTable.Column>
          ))}
        </RunDesignTable.Row>
      }
    >
      {cellTypes?.map((cellType, index) => {
        if (!cellType) {
          return (
            <RunDesignTable.Row key={index}>
              <RunDesignTable.Column className={cn('cell')} />
            </RunDesignTable.Row>
          );
        }

        return (
          <RunDesignTable.Row key={index}>
            <RunDesignTable.Column className={cn('cell')}>
              <div className={cn('cell__content', 'cell__content_name')}>
                <div className={cn('cell__text')}>{cellType?.cellIndex?.name}</div>
              </div>
            </RunDesignTable.Column>

            {Array.from({ length: maxPreLabelingCount }, (_, placementIndex) => {
              const correspondingPreLabeling = cellType?.preLabelings?.[placementIndex];

              return (
                <RunDesignTable.Column className={cn('cell')} key={placementIndex}>
                  {isDefined(correspondingPreLabeling) && isReagentConsumable(correspondingPreLabeling.consumable) && (
                    <ConsumableComponent
                      className={cn('cell__content')}
                      consumable={correspondingPreLabeling.consumable}
                    />
                  )}
                </RunDesignTable.Column>
              );
            })}
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default MinimizedCellType;
