import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useExperimentContext } from '@/hooks';

import { experimentSelectors } from '@/store/slices/experiment';

import LaneProgress from '@/components/common/LaneProgress';
import NoDataFound from '@/components/common/NoDataFound';

const Flowcell: FC = () => {
  const { changeCurrentAppLane, isAllLanesDataShow } = useExperimentContext();
  const allLaneList = useSelector(experimentSelectors.selectAllCagingLaneList);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const isLanesSelectingAvailable = useMemo(() => allLaneList.length > 1, [allLaneList]);

  const handleCurrentAppLane = (lane: TLane) => {
    if (!isLanesSelectingAvailable) {
      return;
    }
    changeCurrentAppLane(lane);
  };

  if (allLaneList.length === 0) {
    return <NoDataFound alignment="left" />;
  }

  return (
    <div>
      {allLaneList.map((lane) => (
        <LaneProgress
          key={lane.id}
          isSelected={isAllLanesDataShow ? false : lane.id === currentAppLane?.id}
          disabled={!isLanesSelectingAvailable}
          laneId={lane.id}
          laneLetter={lane.letterName}
          progress={lane.progress ?? 100}
          isShowPercentage
          onClick={() => handleCurrentAppLane(lane)}
        />
      ))}
    </div>
  );
};

export default Flowcell;
