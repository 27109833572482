import { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { MIN_INCUBATION_COUNT } from '@/helpers';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import { RunDesignContext } from '@/pages/experiment-run-design/context';
import CounterModule from './CounterModule';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

type TIncubationsTotalProps = {
  isChangeAllowed: boolean;
};

const IncubationsTotal: FC<TIncubationsTotalProps> = ({ isChangeAllowed }) => {
  const { setIncubationChangeTrigger } = useContext(RunDesignContext);

  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const incubationComponentList = useSelector(experimentRunDesignSelectors.selectIncubationComponentList);

  const incubationsCount = useMemo(() => incubationComponentList?.length ?? 0, [incubationComponentList]);

  const openSettingsModal = useCallback(() => {
    setIncubationChangeTrigger?.((prev) => !prev);
  }, []);

  return (
    <CounterModule
      className={cn('run-details__module')}
      count={incubationsCount}
      onIncrease={openSettingsModal}
      onDecrease={openSettingsModal}
      showControls={isChangeAllowed}
      description="Incubations"
      isLocked={someRunDesignCardIsEdit}
      minCount={MIN_INCUBATION_COUNT}
    />
  );
};

export default memo(IncubationsTotal);
