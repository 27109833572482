import {
  RunDesignFields,
  AddReagentStepParametersFields,
  LoadCellsStepParametersFields,
  AddTreatmentStepParametersFields,
  CCEStepParametersFields,
  CRISPRStepParametersFields,
  IncubateStepParametersFields,
  LoadCellsCceStepParametersFields,
  PauseStepParametersFields,
  ScanStepParametersFields,
  SuspendStepParametersFields,
  TimeLapseImagingStepParametersFields,
  WashStepParametersFields,
  StepBaseInfoFields,
} from './fragments';

export const createRunDesignMutation = /* GraphQL */ `
  mutation CreateRunDesign($input: RunDesignInput!) {
    createRunDesign(input: $input) {
      ...RunDesignFields
    }
  }
  ${RunDesignFields}
`;

export const updateRunDesignBaseInfoMutation = /* GraphQL */ `
  mutation UpdateRunDesignBaseInfo($id: ID!, $input: UpdateRunDesignBaseInfoInput!) {
    updateRunDesignBaseInfo(id: $id, input: $input) {
      ...RunDesignFields
    }
  }
  ${RunDesignFields}
`;

export const deleteRunDesignMutation = /* GraphQL */ `
  mutation DeleteRunDesign($id: ID!) {
    deleteRunDesign(id: $id)
  }
`;

export const addRunDesignStepMutation = /* GraphQL */ `
  mutation AddRunDesignStep($runDesignId: ID!, $input: RunDesignStepInput!) {
    addRunDesignStep(runDesignId: $runDesignId, input: $input) {
      ...StepBaseInfoFields
      ... on LoadCellsStep {
        ...LoadCellsStepParametersFields
      }
      ... on CCEStep {
        ...CCEStepParametersFields
      }
      ... on LoadCellsCCEStep {
        ...LoadCellsCceStepParametersFields
      }
      ... on IncubateStep {
        ...IncubateStepParametersFields
      }
      ... on AddReagentStep {
        ...AddReagentStepParametersFields
      }
      ... on AddTreatmentStep {
        ...AddTreatmentStepParametersFields
      }
      ... on WashStep {
        ...WashStepParametersFields
      }
      ... on ScanStep {
        ...ScanStepParametersFields
      }
      ... on TimeLapseImagingStep {
        ...TimeLapseImagingStepParametersFields
      }
      ... on CRISPRStep {
        ...CRISPRStepParametersFields
      }
      ... on PauseStep {
        ...PauseStepParametersFields
      }
      ... on SuspendStep {
        ...SuspendStepParametersFields
      }
    }
  }
  ${StepBaseInfoFields}
  ${AddReagentStepParametersFields}
  ${LoadCellsStepParametersFields}
  ${CCEStepParametersFields}
  ${LoadCellsCceStepParametersFields}
  ${IncubateStepParametersFields}
  ${AddTreatmentStepParametersFields}
  ${WashStepParametersFields}
  ${ScanStepParametersFields}
  ${TimeLapseImagingStepParametersFields}
  ${CRISPRStepParametersFields}
  ${PauseStepParametersFields}
  ${SuspendStepParametersFields}
`;

export const updateRunDesignStepMutation = /* GraphQL */ `
  mutation UpdateRunDesignStep($runDesignId: ID!, $stepId: ID!, $input: RunDesignStepInput!) {
    updateRunDesignStep(runDesignId: $runDesignId, stepId: $stepId, input: $input) {
      ...StepBaseInfoFields
      ... on LoadCellsStep {
        ...LoadCellsStepParametersFields
      }
      ... on CCEStep {
        ...CCEStepParametersFields
      }
      ... on LoadCellsCCEStep {
        ...LoadCellsCceStepParametersFields
      }
      ... on IncubateStep {
        ...IncubateStepParametersFields
      }
      ... on AddReagentStep {
        ...AddReagentStepParametersFields
      }
      ... on AddTreatmentStep {
        ...AddTreatmentStepParametersFields
      }
      ... on WashStep {
        ...WashStepParametersFields
      }
      ... on ScanStep {
        ...ScanStepParametersFields
      }
      ... on TimeLapseImagingStep {
        ...TimeLapseImagingStepParametersFields
      }
      ... on CRISPRStep {
        ...CRISPRStepParametersFields
      }
      ... on PauseStep {
        ...PauseStepParametersFields
      }
      ... on SuspendStep {
        ...SuspendStepParametersFields
      }
    }
  }
  ${StepBaseInfoFields}
  ${AddReagentStepParametersFields}
  ${LoadCellsStepParametersFields}
  ${CCEStepParametersFields}
  ${LoadCellsCceStepParametersFields}
  ${IncubateStepParametersFields}
  ${AddTreatmentStepParametersFields}
  ${WashStepParametersFields}
  ${ScanStepParametersFields}
  ${TimeLapseImagingStepParametersFields}
  ${CRISPRStepParametersFields}
  ${PauseStepParametersFields}
  ${SuspendStepParametersFields}
`;

export const deleteRunDesignStepMutation = /* GraphQL */ `
  mutation DeleteRunDesignStep($runDesignId: ID!, $stepId: ID!) {
    deleteRunDesignStep(runDesignId: $runDesignId, stepId: $stepId) {
      ...RunDesignFields
    }
  }
  ${RunDesignFields}
`;
