import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import styles from './TimelineItem.module.scss';
import TimelineScanTime from '../TimelineScanTime';
import { TTiming } from '../TimelineScanTime/types';

const cn = classnames.bind(styles);

type TTimelineItemProps = {
  className?: string;
  name: string;
  duration: number;
  start: number;
  isDraggable?: boolean;
  isDragging?: boolean;
  isSelected?: boolean;
};

// TODO: NEWFLOW need add new types of component with 2 or 3 subcomponents
// possibility need update type of props
const TimelineItem: FC<TTimelineItemProps> = ({
  duration,
  name,
  start,
  className,
  isDraggable,
  isSelected,
  isDragging,
}) => {
  const timing: TTiming = useMemo(() => ({ duration, start }), [duration, start, isDragging]);

  return (
    <div className={cn('item', className, { _selected: isSelected, _dragging: isDragging })}>
      <TimelineScanTime timing={timing} />
      <div className={cn('item__info')}>
        <span className={cn('item__name')}>{name}</span>
        {isDraggable && (
          <span className={cn('item__dnd-dots')}>
            <icons.DndDotsIcon />
          </span>
        )}
      </div>
    </div>
  );
};

export default memo(TimelineItem);
