import { FC } from 'react';

import IncubationHeader from './IncubationHeader';
import IncubationContent from './IncubationContent';

const IncubationComponent: FC = () => (
  <>
    <IncubationHeader />
    <IncubationContent />
  </>
);

export default IncubationComponent;
