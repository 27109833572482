import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import { TemperatureUnit } from '@/graphql/API';

import styles from '../IncubationBaseBlock.module.scss';
import TemperatureUnitInput from './TemperatureUnitInput';
import TemperatureValue from './TemperatureValue';

const cn = classnames.bind(styles);

type TTemperatureProps = {
  temperature: number;
  onChange: (value: number) => void;
  temperatureUnit: TemperatureUnit;
  onChangeUnit: (value: TemperatureUnit) => void;
};

const Temperature: FC<TTemperatureProps> = ({ temperature, onChange, temperatureUnit, onChangeUnit }) => {
  const onChangeTemperature = useCallback(
    (value: number) => {
      onChange(value);
    },
    [onChange]
  );

  const onChangeTemperatureUnit = useCallback(
    (value: TemperatureUnit) => {
      onChangeUnit(value);
    },
    [onChangeUnit]
  );
  return (
    <div className={cn('group', 'group_temperature')}>
      <div className={cn('group__title')}>Temperature</div>
      <div className={cn('group__inputs')}>
        <TemperatureValue temperature={temperature} onChange={onChangeTemperature} />
        <TemperatureUnitInput temperatureUnit={temperatureUnit} onChange={onChangeTemperatureUnit} />
      </div>
    </div>
  );
};

export default memo(Temperature);
