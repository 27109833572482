import { FC, memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useRunDesignLocationNewFlow } from '@/hooks/runDesign/useRunDesignLocationNewFlow';
import { runDesignGraphqlAPI } from '@/store/services/graphql/runDesign';

import { themeOptions } from '@/types/theme';
import Panel from '@/components/common/Panel';

import pagePanelStyles from '../PagePanel.module.scss';
import runInformationStyles from './RunInformation.modules.scss';
import TemplateHeader from '../components/TemplateHeader';
import RunInformationFooter from './components/RunInformationFooter';

const cn = classnames.bind({ ...pagePanelStyles, ...runInformationStyles });

const RunInformationFromTemplate: FC = () => {
  const { sourceId: templateId } = useParams();
  const { goToTemplateList, goToDraftList } = useRunDesignLocationNewFlow();
  const [fetchTemplate, { data: templateData }] = runDesignGraphqlAPI.useLazyFetchTemplateByIdQuery();

  useEffect(() => {
    if (!templateId) {
      return;
    }

    fetchTemplate({ templateId });
  }, [templateId]);

  const onClickBack = useCallback(() => {
    goToTemplateList();
  }, [goToTemplateList]);

  // TODO: NEWFLOW temp log
  const onClickContinue = useCallback(() => {
    console.log('continue');
  }, [goToTemplateList]);

  const onClickSaveAndExit = useCallback(() => {
    goToDraftList();
  }, [goToDraftList]);

  if (!templateData) {
    return null;
  }

  return (
    <Panel className={cn('page-panel')} theme={themeOptions.light}>
      <Panel.Header className={cn('run-information__header')} wrapClassName={cn('page-panel__header-wrap')}>
        <TemplateHeader templateName={templateData.name} />
      </Panel.Header>

      <Panel.Content className={cn('page-panel__content')}>Work in progress from template</Panel.Content>

      <div className={cn('page-panel__footer')}>
        <RunInformationFooter
          onClickBack={onClickBack}
          onClickContinue={onClickContinue}
          onClickSaveAndExit={onClickSaveAndExit}
        />
      </div>
    </Panel>
  );
};

export default memo(RunInformationFromTemplate);
