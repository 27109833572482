import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import Panel from '@/components/common/Panel';
import ComponentsSwiper from '@/components/runDesign/ComponentsSwiper';

import ComponentByType from './ComponentByType';
import DesignWorkflowFooter from './components/DesignWorkflowFooter';
import MenuBurger from './components/MenuBurger';
import AddComponent from './components/AddComponent';

import pagePanelStyles from '../PagePanel.module.scss';
import designWorkflowStyles from './DesignWorkflow.module.scss';

const cn = classnames.bind({ ...pagePanelStyles, ...designWorkflowStyles });

const DesignWorkflow: FC = () => (
  <Panel className={cn('page-panel')} theme={themeOptions.light}>
    <Panel.Header className={cn('step-settings__header')} wrapClassName={cn('page-panel__header-wrap')}>
      <MenuBurger className={cn('step-settings__header__burger')} />
      <div className={cn('step-settings__header__components')}>
        <ComponentsSwiper />
      </div>
      <AddComponent />
    </Panel.Header>

    <Panel.Content className={cn('page-panel__content')}>
      <ComponentByType />
    </Panel.Content>

    <div className={cn('page-panel__footer')}>
      <DesignWorkflowFooter />
    </div>
  </Panel>
);

export default memo(DesignWorkflow);
