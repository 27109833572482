import { FC } from 'react';

import RunDesignBlockWithLanesNewFlow from '@/components/runDesign/RunDesignBlockWithLanes/RunDesignBlockWithLanesNewFlow';

import SampleInformationHeaderNew from './SampleInformationHeaderNew';

const SampleInformationComponent: FC = () => (
  <>
    <SampleInformationHeaderNew />
    <RunDesignBlockWithLanesNewFlow isEditable>WORK IN PROGRESS</RunDesignBlockWithLanesNewFlow>
  </>
);

export default SampleInformationComponent;
