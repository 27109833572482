import { FC } from 'react';
import { isCellKillingComponent } from '@/helpers/runDesigns/typeGuards';
import CellKillingRenderer from './CellKillingRenderer';
import DefaultRenderer from './DefaultRenderer';
import { TCardData, TCellKillingCardData } from '../types';

type TTimelineCardProps = {
  data: TCardData;
  onClick: () => void;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  cardDataList: TCardData[];
};

const isCellKillingCardData = (cardData: TCardData): cardData is TCellKillingCardData =>
  isCellKillingComponent(cardData.component);

const TimelineCard: FC<TTimelineCardProps> = ({ data, onClick, position, cardDataList }) => {
  if (isCellKillingCardData(data)) {
    return <CellKillingRenderer data={data} position={position} onClick={onClick} cardDataList={cardDataList} />;
  }

  return <DefaultRenderer data={data} position={position} onClick={onClick} />;
};

export default TimelineCard;
