import classNames from 'classnames/bind';
import { OptionProps } from 'react-select';
import { ReactElement } from 'react';
import styles from './DefaultOption.module.scss';
import icons from '../../icons';

const cn = classNames.bind(styles);

type TDefaultOptionProps<T> = OptionProps<T>;

const DefaultOption = <T extends { label: ReactElement | string | number; value: string | number }>({
  data,
  innerProps,
  innerRef,
  isSelected,
}: TDefaultOptionProps<T>) => (
  <div ref={innerRef} {...innerProps} className={cn('default-option', { _selected: isSelected })}>
    <icons.SelectDefaultCheckmark className={cn('default-option__checkmark')} />
    <div className={cn('default-option__content')}>{data.label}</div>
  </div>
);

export default DefaultOption;
