import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { isDefined } from '@/helpers/typeGuards';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import MagnificationSwitch from '../MagnificationSwitch/MagnificationSwitch';

import styles from '../EditFields/EditFields.module.scss';

const cn = classnames.bind(styles);

type TMagnificationRowProps = {
  rowIndex: number;
  laneId: string;
};

const MagnificationRow: FC<TMagnificationRowProps> = ({ rowIndex, laneId }) => {
  const appDispatch = useAppDispatch();
  const magnification = useSelector(experimentRunDesignSelectors.selectLaneCagingSettingsMagnification(laneId));
  const handleChangeMagnification = useCallback(
    (input: { label: string; value: number }) => {
      appDispatch(
        experimentRunDesignActions.updateLaneCagingSettingsMagnification({ laneId, magnification: input.value })
      );
    },
    [laneId]
  );

  if (!isDefined(magnification)) {
    return <RunDesignTable.Row key={laneId} />;
  }

  return (
    <RunDesignTable.Row key={laneId}>
      <RunDesignTable.Column
        className={cn('selected-field', SELECTABLE_CLASS_NAME, 'magnification')}
        {...addSelectableAttribute({ columnIndex: 0, rowIndex, dataType: 'magnification' })}
      >
        <MagnificationSwitch
          firstValue={{ label: '4X', value: 4 }}
          secondValue={{ label: '10X', value: 10 }}
          defaultValue="first"
          onChangeValue={handleChangeMagnification}
          selectedValue={magnification === 10 ? 'second' : 'first'}
        />
      </RunDesignTable.Column>
    </RunDesignTable.Row>
  );
};

export default memo(MagnificationRow);
