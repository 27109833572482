import { SVGProps } from 'react';

const SelectDefaultCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 5.00003L3.42116 8.30297" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M3.42188 8.30295L8.00071 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default SelectDefaultCheckmark;
