import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons/iconList';
import { LANE_LETTER_NAME_LIST } from '@/helpers';
import LaneProgress from '@/components/common/LaneProgress';

import styles from './LanesPlate.module.scss';

const cn = classnames.bind(styles);

type TLanesPlateProps = {
  lanesMap: Record<string, string>;
  className?: string;
  laneClassName?: string;
  onLaneClick?: (laneId: string, isSelected: boolean) => void;
};

const LanesPlate: FC<TLanesPlateProps> = ({ lanesMap, className, laneClassName, onLaneClick }) => (
  <div className={cn('lanes-plate', className)}>
    <div className={cn('border-wrapper')}>
      <icons.RectangleLanePlateWrapperIcon />
    </div>
    {LANE_LETTER_NAME_LIST.map((letter) => (
      <LaneProgress
        onClick={onLaneClick}
        key={letter}
        isSelected={!!lanesMap[letter]}
        laneLetter={letter}
        laneId={letter}
        progress={100}
        disabled={false}
        isShowPin
        className={cn('lane-progress', laneClassName)}
      />
    ))}
  </div>
);

export default memo(LanesPlate);
