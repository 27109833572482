import { useCallback } from 'react';
import classnames from 'classnames/bind';

import DetailsSelectWithLabel from '../DesignDetails/components/DetailsSelectWithLabel';

import styles from './TypeOfSampleLoading.module.scss';

const cn = classnames.bind(styles);

const options: TOption[] = [
  { label: 'On-demand loading', value: 1 },
  { label: 'On-demand loaded', value: 2 },
];

const TypeOfSampleLoading = () => {
  const onChange = useCallback(() => null, []);

  return (
    <div className={cn('type-of-sample')}>
      <DetailsSelectWithLabel
        onChange={onChange}
        htmlFor="type_of_sample_loading"
        labelTitle="Type of sample loading"
        options={options}
      />
    </div>
  );
};

export default TypeOfSampleLoading;
