import { gql } from '@apollo/client';
import { runDesignApolloClient } from '@/services/runDesignApolloClient';
import {
  createRunDesignMutation,
  CreateRunDesignMutation,
  CreateRunDesignMutationVariables,
  runDesignsQuery,
  RunDesignsQuery,
  RunDesignsQueryVariables,
} from '@/graphql/runDesign';

import { TRunDesignGraphqlEndpointBuilder } from '../types';
import { TagTypesMap } from '../constants';
import { baseMerge } from '../helpers';

export const runDesignsEndpoints = (build: TRunDesignGraphqlEndpointBuilder) => ({
  fetchRunDesigns: build.query<RunDesignsQuery['runDesigns'], RunDesignsQueryVariables>({
    queryFn: async (args) => {
      try {
        const response = await runDesignApolloClient.query<RunDesignsQuery>({
          query: gql(runDesignsQuery),
          variables: args,
        });
        return {
          data: response.data.runDesigns,
        };
      } catch (error: any) {
        console.error(error?.message);
        return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
      }
    },
    merge: baseMerge,
    providesTags: [TagTypesMap.TemplateList],
  }),

  createRunDesign: build.mutation<CreateRunDesignMutation['createRunDesign'], CreateRunDesignMutationVariables>({
    queryFn: async (args) => {
      try {
        const response = await runDesignApolloClient.mutate<CreateRunDesignMutation>({
          mutation: gql(createRunDesignMutation),
          variables: args,
        });

        if (!response.data) {
          return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
        }

        return {
          data: response.data.createRunDesign,
        };
      } catch (error: any) {
        console.error(error?.message);
        return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
      }
    },
    invalidatesTags: [TagTypesMap.RunDesignList],
  }),
});
