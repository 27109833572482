import { FC, memo } from 'react';

import TitleHeader from '../../../components/TitleHeader';
import FlowCellSettings from '../FlowCellSettings';

const FlowCellSetupContent: FC = () => (
  <div>
    {/* TODO: NEWFLO temp empty string */}
    <TitleHeader title="Flow cell information" userName="" runDesignDate="" />
    <FlowCellSettings />
  </div>
);

export default memo(FlowCellSetupContent);
