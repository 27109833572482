import { CSSProperties, FC } from 'react';
import classnames from 'classnames/bind';

import { CAGE_SETTINGS_MAIN_SECTIONS } from '@/helpers/runDesigns/CagingSettings/constants';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import BaseSettingSections from '../../../BaseSettingSections';

import styles from '../../EditSettings.module.scss';
import TableContent from './TableContent';

const cn = classnames.bind(styles);

type TMagnificationTableProps = {
  className?: string;
};

const MagnificationTable: FC<TMagnificationTableProps> = ({ className }) => (
  <div className={cn('magnification-table-block')}>
    <RunDesignTable
      tableData={[]}
      className={cn('edit-settings__table', 'magnification-table', className)}
      headerClassName={cn('edit-settings__header')}
      style={
        {
          '--settings-count': 1,
        } as CSSProperties
      }
      header={
        <RunDesignTable.Row className={cn('edit-settings__header-row')}>
          <BaseSettingSections sectionsData={[CAGE_SETTINGS_MAIN_SECTIONS[0]]} />
        </RunDesignTable.Row>
      }
    >
      <TableContent />
    </RunDesignTable>
  </div>
);

export default MagnificationTable;
