import { SVGProps } from 'react';

const RectangleLanePlateWrapperIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="470" height="335" viewBox="0 0 470 335" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.0312 7.67589C32.6259 3.08125 38.8575 0.500002 45.3553 0.500002L455 0.50002C463.008 0.50002 469.5 6.99189 469.5 15L469.5 318C469.5 326.008 463.008 332.5 455 332.5L45.3553 332.5C38.8575 332.5 32.6258 329.919 28.0312 325.324L7.67588 304.969C3.08124 300.374 0.499987 294.142 0.499987 287.645L0.499998 45.3553C0.499998 38.8575 3.08124 32.6259 7.67588 28.0312L28.0312 7.67589Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

export default RectangleLanePlateWrapperIcon;
