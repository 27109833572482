import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { generateJwtToken } from '@/helpers';

import awsconfig from './aws-exports';

const appSyncConfig = {
  url: process.env.REACT_APP_APOLLO_GRAPHOS_API_URL ?? '',
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS | AUTH_TYPE.OPENID_CONNECT,
    jwtToken: generateJwtToken,
  },
};

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([createAuthLink(appSyncConfig), createSubscriptionHandshakeLink(appSyncConfig)]),
  cache: new InMemoryCache(),
  credentials: 'include',
});
