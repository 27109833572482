import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import Select from '@/components/common/Select';

import styles from '../IncubationBaseBlock.module.scss';

const cn = classnames.bind(styles);

type TTemperatureValueProps = {
  temperature: number;
  onChange: (value: number) => void;
};

const options = Array.from({ length: 100 }, (_, index) => ({
  value: index,
  label: String(index),
}));

const TemperatureValue: FC<TTemperatureValueProps> = ({ temperature, onChange }) => {
  const onChangeTemperature = useCallback(
    (value: number) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      options={options}
      value={temperature}
      onChange={onChangeTemperature}
      theme={themeOptions.light}
      className={cn('select', 'select_narrow')}
      controlClassName={cn('select-control')}
      menuClassName={cn('select-menu')}
      menuPosition="absolute"
      isPortalable
      menuListClassName={cn('select-menu-list')}
      customComponents={{ Option: Select.DefaultOption }}
    />
  );
};

export default memo(TemperatureValue);
