import { CSSProperties, FC, MouseEventHandler, ReactElement, useMemo } from 'react';
import classNames from 'classnames/bind';
import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';
import { ConnectDragSource } from 'react-dnd';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import styles from './DefaultRenderer.module.scss';
import schemaComponentStyles from '../SchemaComponent.module.scss';

const cn = classNames.bind({ ...schemaComponentStyles, ...styles });

type TDefaultRendererProps = {
  isCurrent: boolean;
  component: TRunDesignComponent;
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  scanLabelMap: Record<string, string>;
  dnd: {
    opacity: number;
    dragRef: ConnectDragSource;
    dropArea: ReactElement | false;
  };
};

const DefaultRenderer: FC<TDefaultRendererProps> = ({ dnd, position, component, isCurrent, onClick, scanLabelMap }) => {
  const abbreviationName = useMemo(() => getAbbreviationName(component), [component]);

  return (
    <button
      className={cn('schema-component', 'schema-default-component', {
        'schema-component_no-scan': !scanLabelMap[component.id],
        'schema-default-component_current': isCurrent,
      })}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
          '--column-end': position.columnEnd,
          opacity: dnd.opacity,
        } as CSSProperties
      }
      ref={dnd.dragRef}
    >
      {dnd.dropArea}
      <div className={cn('schema-component__wrap')}>
        <div className={cn('schema-component__step', 'schema-component__incubation')}>
          <span className={cn('schema-component__name')}>{component.name}</span>
          <span className={cn('schema-component__name', 'schema-component__name_abbreviation')}>
            {abbreviationName}
          </span>
          {isIncubationComponent(component) && component.hasTreatment && (
            <span>
              <br />
              +TRT
            </span>
          )}
        </div>
        {scanLabelMap[component.id] && (
          <div className={cn('schema-component__step', 'schema-component__scan')}>{scanLabelMap[component.id]}</div>
        )}
      </div>
    </button>
  );
};

export default DefaultRenderer;
