import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import type { TCreatableComponentTypeNewFlow } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';

import styles from './SelectComponent.module.scss';

const cn = classnames.bind(styles);

type TStepItemProps = {
  type: TCreatableComponentTypeNewFlow;
  name: string;
  tabIndex: number;
  onSelect: (type: TCreatableComponentTypeNewFlow) => void;
};

const StepItem: FC<TStepItemProps> = ({ name, tabIndex, type, onSelect }) => {
  const handleSelect = useCallback(() => {
    onSelect(type);
  }, [onSelect, type]);

  return (
    <li className={cn('select-component__add-step-elem')}>
      <button className={cn('select-component__add-step')} onClick={handleSelect} tabIndex={tabIndex}>
        <div className={cn('title')}>
          <icons.PlusIcon className={cn('select-component__add-step-trigger-icon')} />
          <span>Add {name}</span>
        </div>
      </button>
    </li>
  );
};

export default memo(StepItem);
