import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';

import { arrToMapByKeys, LANE_LETTER_NAME_LIST } from '@/helpers';
import { TRunDesignLane } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '../RunDesignTable';
import Label from '../LanesTable/Label';

import styles from './LanesBlock.module.scss';

const cn = classnames.bind(styles);

type TLanesBlockNewProps = {
  isEditable?: boolean;
  rowClassName?: string;
  tableClassName?: string;
  lanes: Nullable<TRunDesignLane[]>;
  onChangeLaneActive: (laneLetter: string, isActive: boolean) => void;
};

// TODO: NEWFLOW need to be replaced filename to index and remove old index
const LanesBlockNew: FC<TLanesBlockNewProps> = ({
  isEditable,
  rowClassName,
  tableClassName,
  lanes,
  onChangeLaneActive,
}) => {
  const lanesMap = useMemo(() => arrToMapByKeys(lanes ?? [], 'id'), [lanes]);

  return (
    <RunDesignTable tableData={[]} className={tableClassName}>
      {LANE_LETTER_NAME_LIST.map((laneLetter) => {
        const lane = lanesMap[laneLetter];
        const isSampleNameHidden = !lane && !isEditable;

        return (
          <RunDesignTable.Row key={laneLetter} className={cn('lanes-table__row', rowClassName)}>
            <RunDesignTable.Column tooltipMessage={isSampleNameHidden ? 'Activate lane on Sample loading' : ''}>
              <Label
                className={cn('lanes-table__cell', {
                  'lanes-table__label_disabled': isSampleNameHidden,
                })}
                laneLetter={laneLetter}
                isActivationAllowed={isEditable}
                isEditAllowed={isEditable}
                isActive={!!lane}
                onChangeActive={onChangeLaneActive}
              />
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default memo(LanesBlockNew);
