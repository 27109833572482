import { FC, memo, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './Tabs.module.scss';
import TabItem, { TTabItemProps } from './TabItem';

const cn = classnames.bind(styles);

export type TRenderItem = Omit<TTabItemProps, 'onSelect' | 'isSelected'>;

type TFooterTabsProps = {
  initSelectedIdTab: string;
  className?: string;
  renderList: TRenderItem[];
  onChange?: (id: string) => void;
};

const Tabs: FC<TFooterTabsProps> = ({ initSelectedIdTab, renderList, className, onChange }) => {
  const [selectedId, setSelectedId] = useState<string>(initSelectedIdTab);

  const handleSelect = useCallback((newTabId: string) => {
    setSelectedId(newTabId);
  }, []);

  useEffect(() => {
    onChange?.(selectedId);
  }, [selectedId]);

  return (
    <div className={cn('tabs', className)}>
      {renderList.map((renderInfo) => (
        <TabItem
          key={renderInfo.id}
          {...renderInfo}
          onSelect={handleSelect}
          isSelected={selectedId === renderInfo.id}
        />
      ))}
    </div>
  );
};

export default memo(Tabs);
