import { FC } from 'react';

import SurfaceReceptorHeader from './SurfaceReceptorHeader';

const SurfaceReceptorComponent: FC = () => (
  <>
    <SurfaceReceptorHeader />
    <b>WORK IN PROGRESS</b>
  </>
);

export default SurfaceReceptorComponent;
