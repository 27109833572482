import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './FooterButtons.module.scss';
import { TDefaultButtonProps } from './types';

const cn = classnames.bind(styles);

type TContinueProps = TDefaultButtonProps;

const Continue: FC<TContinueProps> = ({ onClick, customTitle, className, disabled }) => (
  <Button
    color="light"
    isOutlined
    className={cn('page-controls__button', 'page-controls__continue', className)}
    onClick={onClick}
    disabled={disabled}
  >
    <span className={cn('page-controls__continue__title')}>{customTitle ?? 'Continue'}</span>
    <icons.ArrowToIcon />
  </Button>
);

export default memo(Continue);
