import { SVGProps } from 'react';

const DndDotsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="2" height="32" viewBox="0 0 2 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="2" height="2" fill="currentColor" />
    <rect y="10" width="2" height="2" fill="currentColor" />
    <rect y="20" width="2" height="2" fill="currentColor" />
    <path d="M0 30H2V32H0V30Z" fill="currentColor" />
  </svg>
);

export default DndDotsIcon;
