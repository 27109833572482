import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import GlobalSettings from '../../../components/CagingSettings/components/GlobalSettings';
import CellCageLanesSettings from './components/CellCageLanesSettings';

const CellCageContent: FC = () => {
  const isGlobalSettingsView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);

  if (isGlobalSettingsView) {
    return <GlobalSettings />;
  }

  return <CellCageLanesSettings />;
};

export default memo(CellCageContent);
