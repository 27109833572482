import packageJson from '~/package.json';

const allowedTracingUrls = [];

if (process.env.REACT_APP_API_URL) {
  allowedTracingUrls.push(process.env.REACT_APP_API_URL);
}

if (process.env.REACT_APP_GRAPHQL_API_URL) {
  allowedTracingUrls.push(process.env.REACT_APP_GRAPHQL_API_URL);
}

const datadogConfig = {
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID ?? '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: process.env.REACT_APP_DATADOG_SITE ?? '',
  service: 'cloudplatform',
  env: process.env.REACT_APP_ENV ?? '',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: packageJson.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  allowedTracingUrls,
};

export default datadogConfig;
