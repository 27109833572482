import { SVGProps } from 'react';

const CellanomeSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6702 0.0189916C11.3414 -0.100261 11.9818 0.354495 12.101 1.03495C12.22 1.71519 11.7723 2.36332 11.1013 2.48257C10.4302 2.60182 9.78951 2.14706 9.67053 1.46682C9.55155 0.786366 9.999 0.138244 10.6702 0.0189916Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5012 21.5175C15.1724 21.3983 15.8129 21.853 15.9321 22.5335C16.051 23.2137 15.6034 23.8619 14.9324 23.9811C14.2612 24.1004 13.6206 23.6456 13.5016 22.9654C13.3826 22.2849 13.8301 21.6368 14.5012 21.5175Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64904 1.4326C7.239 1.08797 7.99407 1.29399 8.33563 1.89253C8.6772 2.49128 8.47601 3.25573 7.88605 3.60036C7.2963 3.94478 6.54123 3.73876 6.19967 3.14022C5.8579 2.54168 6.05929 1.77702 6.64904 1.4326Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7164 20.3998C18.3064 20.0554 19.0615 20.2612 19.403 20.8599C19.7446 21.4585 19.5434 22.223 18.9534 22.5676C18.3637 22.9121 17.6086 22.706 17.2671 22.1075C16.9253 21.5089 17.1267 20.7444 17.7164 20.3998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.34297 4.13679C3.78154 3.60731 4.56015 3.53797 5.08197 3.98173C5.604 4.42549 5.67181 5.21426 5.23324 5.74374C4.79468 6.27302 4.01607 6.34257 3.49424 5.89881C2.97221 5.45505 2.90441 4.66628 3.34297 4.13679Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3693 18.2564C20.8079 17.7269 21.5865 17.6576 22.1083 18.1014C22.6304 18.5451 22.6982 19.3339 22.2596 19.8634C21.821 20.3926 21.0424 20.4622 20.5206 20.0184C19.9986 19.5747 19.9308 18.7859 20.3693 18.2564Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21405 7.88545C1.44808 7.23616 2.15674 6.90049 2.79695 7.1356C3.43695 7.37093 3.76623 8.08782 3.532 8.73712C3.29798 9.38641 2.58932 9.72229 1.94911 9.48697C1.30911 9.25185 0.980032 8.53475 1.21405 7.88545Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 12.0008C0.5 11.31 1.05274 10.75 1.7345 10.75C2.41626 10.75 2.96879 11.31 2.96879 12.0008C2.96879 12.6915 2.41626 13.2516 1.7345 13.2516C1.05274 13.2516 0.5 12.6915 0.5 12.0008Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21405 16.1146C0.980032 15.4653 1.30911 14.7482 1.94911 14.5131C2.58932 14.2778 3.29798 14.6137 3.532 15.263C3.76623 15.9123 3.43695 16.6292 2.79695 16.8645C2.15674 17.0996 1.44808 16.7639 1.21405 16.1146Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.34297 19.8633C2.90441 19.3338 2.97221 18.545 3.49424 18.1013C4.01627 17.6575 4.79489 17.7271 5.23324 18.2564C5.67181 18.7858 5.604 19.5746 5.08197 20.0184C4.56015 20.4621 3.78154 20.3928 3.34297 19.8633Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3693 5.74367C19.9308 5.21419 19.9986 4.42542 20.5206 3.98166C21.0424 3.5379 21.821 3.60745 22.2596 4.13672C22.6982 4.66621 22.6304 5.45498 22.1083 5.89874C21.5865 6.3425 20.8079 6.27316 20.3693 5.74367Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64904 22.5675C6.05929 22.2229 5.8579 21.4584 6.19967 20.8599C6.54123 20.2613 7.2963 20.0553 7.88605 20.3997C8.47601 20.7444 8.6772 21.5088 8.33563 22.1076C7.99407 22.7061 7.239 22.9121 6.64904 22.5675Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7164 3.60021C17.1267 3.25558 16.9253 2.49113 17.2671 1.89259C17.6086 1.29405 18.3637 1.08803 18.9534 1.43245C19.5434 1.77708 19.7446 2.54153 19.403 3.14028C19.0615 3.73882 18.3064 3.94484 17.7164 3.60021Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6702 23.9811C9.999 23.8619 9.55155 23.2137 9.67053 22.5333C9.78951 21.853 10.4302 21.3983 11.1014 21.5175C11.7723 21.6368 12.22 22.2849 12.101 22.9651C11.9818 23.6456 11.3414 24.1004 10.6702 23.9811Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5012 2.48257C13.8301 2.36332 13.3826 1.71519 13.5016 1.03474C13.6206 0.354495 14.2612 -0.100261 14.9324 0.0189918C15.6034 0.138245 16.051 0.786366 15.9321 1.46661C15.8129 2.14707 15.1724 2.60182 14.5012 2.48257Z"
      fill="currentColor"
    />
    <path
      d="M12.6758 5.14189C12.6758 5.83268 12.1232 6.39267 11.4414 6.39267C10.7597 6.39267 10.207 5.83268 10.207 5.14189C10.207 4.45111 10.7597 3.89111 11.4414 3.89111C12.1232 3.89111 12.6758 4.45111 12.6758 5.14189Z"
      fill="currentColor"
    />
    <path
      d="M9.13872 6.90703C9.13872 7.59782 8.58606 8.15781 7.90432 8.15781C7.22258 8.15781 6.66992 7.59782 6.66992 6.90703C6.66992 6.21624 7.22258 5.65625 7.90432 5.65625C8.58606 5.65625 9.13872 6.21624 9.13872 6.90703Z"
      fill="currentColor"
    />
    <path
      d="M6.96001 10.1243C6.96001 10.8151 6.40735 11.3751 5.72561 11.3751C5.04387 11.3751 4.49121 10.8151 4.49121 10.1243C4.49121 9.43353 5.04387 8.87354 5.72561 8.87354C6.40735 8.87354 6.96001 9.43353 6.96001 10.1243Z"
      fill="currentColor"
    />
    <path
      d="M6.95908 14.0105C6.95908 14.7013 6.40642 15.2613 5.72468 15.2613C5.04294 15.2613 4.49028 14.7013 4.49028 14.0105C4.49028 13.3197 5.04294 12.7597 5.72468 12.7597C6.40642 12.7597 6.95908 13.3197 6.95908 14.0105Z"
      fill="currentColor"
    />
    <path
      d="M9.13872 17.2215C9.13872 17.9123 8.58606 18.4723 7.90432 18.4723C7.22258 18.4723 6.66992 17.9123 6.66992 17.2215C6.66992 16.5307 7.22258 15.9707 7.90432 15.9707C8.58606 15.9707 9.13872 16.5307 9.13872 17.2215Z"
      fill="currentColor"
    />
    <path
      d="M12.6758 18.8519C12.6758 19.5426 12.1232 20.1026 11.4414 20.1026C10.7597 20.1026 10.207 19.5426 10.207 18.8519C10.207 18.1611 10.7597 17.6011 11.4414 17.6011C12.1232 17.6011 12.6758 18.1611 12.6758 18.8519Z"
      fill="currentColor"
    />
    <path
      d="M16.543 18.7186C16.543 19.4093 15.9904 19.9693 15.3086 19.9693C14.6269 19.9693 14.0742 19.4093 14.0742 18.7186C14.0742 18.0278 14.6269 17.4678 15.3086 17.4678C15.9904 17.4678 16.543 18.0278 16.543 18.7186Z"
      fill="currentColor"
    />
    <path
      d="M19.792 16.5984C19.792 17.2892 19.2394 17.8492 18.5576 17.8492C17.8759 17.8492 17.3232 17.2892 17.3232 16.5984C17.3232 15.9076 17.8759 15.3477 18.5576 15.3477C19.2394 15.3477 19.792 15.9076 19.792 16.5984Z"
      fill="currentColor"
    />
    <path
      d="M16.543 5.40947C16.543 6.10026 15.9904 6.66025 15.3086 6.66025C14.6269 6.66025 14.0742 6.10026 14.0742 5.40947C14.0742 4.71868 14.6269 4.15869 15.3086 4.15869C15.9904 4.15869 16.543 4.71868 16.543 5.40947Z"
      fill="currentColor"
    />
    <path
      d="M19.792 7.40654C19.792 8.09733 19.2394 8.65732 18.5576 8.65732C17.8759 8.65732 17.3232 8.09733 17.3232 7.40654C17.3232 6.71575 17.8759 6.15576 18.5576 6.15576C19.2394 6.15576 19.792 6.71575 19.792 7.40654Z"
      fill="currentColor"
    />
  </svg>
);

export default CellanomeSmallIcon;
