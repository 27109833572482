import { FC } from 'react';
import classnames from 'classnames/bind';

import { TRenderBaseComponentProps } from '@/components/runDesign/PanelWithFolding/types';
import EditSettings from '@/pages/experiment-run-design-new-flow/components/CagingSettings/components/EditSettings';

import styles from './CellCageBaseBlock.module.scss';

const cn = classnames.bind(styles);

const CellCageBaseBlockContent: FC<TRenderBaseComponentProps> = () => (
  <div className={cn('cell-cage-content-block')}>
    <EditSettings />
  </div>
);

export default CellCageBaseBlockContent;
