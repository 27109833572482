import { createContext, useContext } from 'react';

export type TPanelWithFoldingContext = {
  expandedId: Nullable<string>;
  sideBlocksOpenStatusMap: Record<string, boolean>;
  toggleSide: (key: string, value: boolean, index: number) => void;
  changeExpandedId: (newValue: Nullable<string>) => void;
  collapseAllSideBlocks: () => void;
  showingSideBlocks: boolean[];
  baseBlocksCount: number;
  sideBlocksCount: number;
  expandableCount: number;
};

export const PanelWithFoldingContext = createContext({} as TPanelWithFoldingContext);

export const usePanelWithFoldingContext = (): TPanelWithFoldingContext => useContext(PanelWithFoldingContext);
