import { FC, memo, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import { TSwiperItem } from './types';
import styles from './Swiper.module.scss';
import { getItemId } from './helpers';

const cn = classnames.bind(styles);

type TSwiperItemProps = {
  swiperId: string;
  item: TSwiperItem;
  isSelected: boolean;
  handleSelectItem: (id: string) => void;
};

const SwiperItem: FC<TSwiperItemProps> = ({ item, isSelected, handleSelectItem, swiperId }) => {
  const itemId = useMemo(() => getItemId(swiperId, item.id), [swiperId, item.id]);

  const onSelect = useCallback(() => {
    handleSelectItem(item.id);
  }, [item.id, handleSelectItem]);

  return (
    <button onClick={onSelect} id={itemId} className={cn('swiper-item', 'swiper__btn', item.className)}>
      {item.render({ isSelected, onSelect })}
    </button>
  );
};
export default memo(SwiperItem);
