export const PaginationFields = /* GraphQL */ `
  fragment PaginationFields on PaginationInfo {
    nextToken
    total
  }
`;

export const TemplateFields = /* GraphQL */ `
  fragment TemplateFields on Template {
    organizationId
    id
    name
    description
    visibility
    activeVersion {
      templateId
      version
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    tags {
      key
      value
    }
  }
`;

export const RunDesignFields = /* GraphQL */ `
  fragment RunDesignFields on RunDesign {
    organizationId
    id
    name
    description
    projectId
    investigatorId
    flowcell {
      type
      coating
      maxNumberOfLanes
      lanes {
        id
        enabled
        sampleName
        metadata
      }
    }
    status
    organisms
    externalLinks
    tags {
      key
      value
    }
    createdAt
    updatedAt
    completedAt
    templateId
    templateVersion
  }
`;

export const StepBaseInfoFields = /* GraphQL */ `
  fragment StepBaseInfoFields on RunDesignStep {
    id
    type
    timing {
      previousStepId
      expectedDuration
    }
    label
    __typename
  }
`;

export const LoadCellsStepParametersFields = /* GraphQL */ `
  fragment LoadCellsStepParametersFields on LoadCellsStep {
    loadCellsStepParameters: parameters {
      onDevice
      lanes {
        id
        cells {
          cellIndex {
            id
            name
            cellLineOrPrimary
            organisms
            morphology
            tissue
            phenotype
            growthProperties
            vendor
          }
          function
          otherFunction
          preLabelings {
            id
            name
            type
            concentration
            concentrationUnit
            volume
            volumeUnit
          }
          preTreatments {
            id
            name
            type
            concentration
            concentrationUnit
            volume
            volumeUnit
          }
        }
      }
    }
  }
`;

export const CCEStepParametersFields = /* GraphQL */ `
  fragment CCEStepParametersFields on CCEStep {
    cceStepParameters: parameters {
      magnification
      lanes {
        id
        cceMode
        parameters
      }
    }
  }
`;

export const LoadCellsCceStepParametersFields = /* GraphQL */ `
  fragment LoadCellsCceStepParametersFields on LoadCellsCCEStep {
    loadCellsCceStepParameters: parameters {
      loadCells {
        onDevice
        lanes {
          id
          cells {
            cellIndex {
              id
              name
              cellLineOrPrimary
              organisms
              morphology
              tissue
              phenotype
              growthProperties
              vendor
            }
            function
            otherFunction
            preLabelings {
              id
              name
              type
              concentration
              concentrationUnit
              volume
              volumeUnit
            }
            preTreatments {
              id
              name
              type
              concentration
              concentrationUnit
              volume
              volumeUnit
            }
          }
        }
      }
      cceFormation {
        magnification
        lanes {
          id
          cceMode
          parameters
        }
      }
    }
  }
`;

export const IncubateStepParametersFields = /* GraphQL */ `
  fragment IncubateStepParametersFields on IncubateStep {
    incubateStepParameters: parameters {
      duration
      temperature
      temperatureUnit
      onDevice
    }
  }
`;

export const AddReagentStepParametersFields = /* GraphQL */ `
  fragment AddReagentStepParametersFields on AddReagentStep {
    addReagentStepParameters: parameters {
      loadingSpeed
      lanes {
        id
        consumable {
          id
          name
          type
          concentration
          concentrationUnit
          volume
          volumeUnit
        }
      }
    }
  }
`;

export const AddTreatmentStepParametersFields = /* GraphQL */ `
  fragment AddTreatmentStepParametersFields on AddTreatmentStep {
    addTreatmentStepParameters: parameters {
      lanes {
        id
        consumable {
          id
          name
          type
          concentration
          concentrationUnit
          volume
          volumeUnit
        }
      }
    }
  }
`;

export const WashStepParametersFields = /* GraphQL */ `
  fragment WashStepParametersFields on WashStep {
    washStepParameters: parameters {
      lanes {
        id
        washTimes
        washMedia
        loadingSpeed
      }
    }
  }
`;

export const ScanStepParametersFields = /* GraphQL */ `
  fragment ScanStepParametersFields on ScanStep {
    scanStepParameters: parameters {
      direction
      lanes {
        id
        fov {
          rows {
            number
            ranges {
              from
              to
            }
          }
        }
        settings {
          exChannel
          emFilter
          intensity
          exposure
          zOffset
          consumableReference {
            consumableId
            stepId
          }
          enabled
        }
      }
    }
  }
`;

export const TimeLapseImagingStepParametersFields = /* GraphQL */ `
  fragment TimeLapseImagingStepParametersFields on TimeLapseImagingStep {
    timeLapseImagingStepParameters: parameters {
      timesToRepeat
      incubation {
        duration
        temperature
        temperatureUnit
        onDevice
      }
      scan {
        direction
        lanes {
          id
          fov {
            rows {
              number
              ranges {
                from
                to
              }
            }
          }
          settings {
            exChannel
            emFilter
            intensity
            exposure
            zOffset
            consumableReference {
              consumableId
              stepId
            }
            enabled
          }
        }
      }
    }
  }
`;

export const CRISPRStepParametersFields = /* GraphQL */ `
  fragment CRISPRStepParametersFields on CRISPRStep {
    crisprStepParameters: parameters {
      lanes {
        id
        reference
      }
    }
  }
`;

export const PauseStepParametersFields = /* GraphQL */ `
  fragment PauseStepParametersFields on PauseStep {
    pauseStepParameters: parameters {
      userInstructions
    }
  }
`;

export const SuspendStepParametersFields = /* GraphQL */ `
  fragment SuspendStepParametersFields on SuspendStep {
    suspendStepParameters: parameters {
      userInstructions
    }
  }
`;
