import { FC, MouseEventHandler } from 'react';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

type TExpandButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isExpanded: boolean;
  className?: string;
};

const ExpandButton: FC<TExpandButtonProps> = ({ isExpanded, onClick, className }) => (
  <Button isFitContent isBgLight type="button" color="dark" onClick={onClick} className={className}>
    {isExpanded ? <icons.CollapseV2Icon /> : <icons.ExpandIcon />}
  </Button>
);

export default ExpandButton;
