import { FC } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import styles from './TimelineArrow.modules.scss';

const cn = classnames.bind(styles);

type TTimelineArrowProps = {
  isNext?: boolean;
  onClick?: () => void;
};

const TimelineArrow: FC<TTimelineArrowProps> = ({ isNext, onClick }) => (
  <button onClick={onClick} aria-label={`timeline-${isNext ? 'next' : 'prev'}-arrow`} className={cn('timeline-arrow')}>
    {isNext ? <icons.TimelineArrowNext width={20} height={20} /> : <icons.TimelineArrowPrev width={20} height={20} />}
  </button>
);

export default TimelineArrow;
