import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';
import RunInformationFromTemplate from './RunInformationFromTemplate';
import RunInformationDefault from './RunInformationDefault';

const RunInformation: FC = () => {
  const { sourceType } = useParams();

  if (sourceType === ERunDesignSourceType.template) {
    return <RunInformationFromTemplate />;
  }

  return <RunInformationDefault />;
};

export default memo(RunInformation);
