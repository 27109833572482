import { FC } from 'react';

import icons from '@/components/common/icons';
import { TRenderSideComponentProps } from '@/components/runDesign/PanelWithFolding/types';
import PanelWithFolding from '@/components/runDesign/PanelWithFolding';

const WashSideBlockHeader: FC<TRenderSideComponentProps> = ({ isOpen, toggleOpen }) => (
  <PanelWithFolding.Title Icon={icons.WashIcon} title="Wash" onClick={toggleOpen} isShowTitle={isOpen} />
);

export default WashSideBlockHeader;
