import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { templatesEndpoints, runDesignsEndpoints, runDesignStepsEndpoints } from './endpoints';
import { TagTypesMap } from './constants';

export const runDesignGraphqlAPI = createApi({
  reducerPath: 'runDesignGraphqlAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({}),
  tagTypes: Object.values(TagTypesMap),
  endpoints: (build) => ({
    ...templatesEndpoints(build),
    ...runDesignsEndpoints(build),
    ...runDesignStepsEndpoints(build),
  }),
});
