import { FC, Reducer, useContext, useEffect, useReducer, useState } from 'react';
import classNames from 'classnames/bind';

import { RunDesignContext } from '@/pages/experiment-run-design/context';

import { Title } from '@/components/runDesign/customComponentWrapper';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { TEditReducerAction } from './reducer/types';
import { editReducer } from './reducer';
import { useEditDesignData } from '../hooks/useEditDesignData';

import SettingsModal from './components/SettingsModal';
import Timeline from './components/Timeline';

import styles from './DesignTimeline.module.scss';

const cn = classNames.bind(styles);

const DesignTimeline: FC = () => {
  const { overrideCustomFooterConfig, clearCustomFooterConfig } = useContext(RunDesignContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { isLoading, isError } = useEditDesignData();
  const [currentComponentId, setCurrentComponentId] = useState<Nullable<string>>(null);
  const [componentList, dispatchComponentListAction] = useReducer<
    Reducer<Nullable<TRunDesignComponent[]>, TEditReducerAction>
  >(editReducer, null);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      edit: {
        clickHandler: () => {
          setIsEditModalOpen(true);
        },
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig]);

  const handleDialogCloseCLick = () => {
    setIsEditModalOpen(false);
  };

  const handleTimelineCardSelect = (id: string) => {
    setCurrentComponentId(id);
    setIsEditModalOpen(true);
  };

  return (
    <div className={cn('design-timeline')}>
      <Title>Experiment timeline</Title>
      {!isLoading && !isError && <Timeline onCardSelect={handleTimelineCardSelect} componentList={componentList} />}
      {!isLoading && !isError && (
        <SettingsModal
          open={isEditModalOpen}
          currentComponentId={currentComponentId}
          setCurrentComponentId={setCurrentComponentId}
          onClose={handleDialogCloseCLick}
          componentList={componentList}
          dispatchComponentListAction={dispatchComponentListAction}
        />
      )}
    </div>
  );
};

export default DesignTimeline;
