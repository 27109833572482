import {
  AddReagentStepParametersFields,
  LoadCellsStepParametersFields,
  AddTreatmentStepParametersFields,
  CCEStepParametersFields,
  CRISPRStepParametersFields,
  IncubateStepParametersFields,
  LoadCellsCceStepParametersFields,
  PauseStepParametersFields,
  ScanStepParametersFields,
  SuspendStepParametersFields,
  TimeLapseImagingStepParametersFields,
  WashStepParametersFields,
  RunDesignFields,
  TemplateFields,
  StepBaseInfoFields,
  PaginationFields,
} from './fragments';

export const templatesQuery = /* GraphQL */ `
  query Templates($name: String, $visibility: TemplateVisibility, $nextToken: String, $limit: Int) {
    templates(name: $name, visibility: $visibility, nextToken: $nextToken, limit: $limit) {
      items {
        ...TemplateFields
      }
      pagination {
        ...PaginationFields
      }
    }
  }
  ${TemplateFields}
  ${PaginationFields}
`;

export const templateQuery = /* GraphQL */ `
  query Template($templateId: ID!) {
    template(id: $templateId) {
      ...TemplateFields
    }
  }
  ${TemplateFields}
`;

export const runDesignsQuery = /* GraphQL */ `
  query RunDesigns($name: String, $investigatorId: ID, $status: RunDesignStatus, $nextToken: String, $limit: Int) {
    runDesigns(name: $name, investigatorId: $investigatorId, status: $status, nextToken: $nextToken, limit: $limit) {
      items {
        ...RunDesignFields
      }
      pagination {
        ...PaginationFields
      }
    }
  }
  ${RunDesignFields}
  ${PaginationFields}
`;

export const runDesignQuery = /* GraphQL */ `
  query RunDesign($runDesignId: ID!) {
    runDesign(id: $runDesignId) {
      ...RunDesignFields
    }
  }
  ${RunDesignFields}
`;

export const runDesignStepsQuery = /* GraphQL */ `
  query RunDesignSteps($runDesignId: ID!) {
    runDesign(id: $runDesignId) {
      id
      steps {
        ...StepBaseInfoFields
        ... on LoadCellsStep {
          ...LoadCellsStepParametersFields
        }
        ... on CCEStep {
          ...CCEStepParametersFields
        }
        ... on LoadCellsCCEStep {
          ...LoadCellsCceStepParametersFields
        }
        ... on IncubateStep {
          ...IncubateStepParametersFields
        }
        ... on AddReagentStep {
          ...AddReagentStepParametersFields
        }
        ... on AddTreatmentStep {
          ...AddTreatmentStepParametersFields
        }
        ... on WashStep {
          ...WashStepParametersFields
        }
        ... on ScanStep {
          ...ScanStepParametersFields
        }
        ... on TimeLapseImagingStep {
          ...TimeLapseImagingStepParametersFields
        }
        ... on CRISPRStep {
          ...CRISPRStepParametersFields
        }
        ... on PauseStep {
          ...PauseStepParametersFields
        }
        ... on SuspendStep {
          ...SuspendStepParametersFields
        }
      }
    }
  }
  ${StepBaseInfoFields}
  ${AddReagentStepParametersFields}
  ${LoadCellsStepParametersFields}
  ${CCEStepParametersFields}
  ${LoadCellsCceStepParametersFields}
  ${IncubateStepParametersFields}
  ${AddTreatmentStepParametersFields}
  ${WashStepParametersFields}
  ${ScanStepParametersFields}
  ${TimeLapseImagingStepParametersFields}
  ${CRISPRStepParametersFields}
  ${PauseStepParametersFields}
  ${SuspendStepParametersFields}
`;
