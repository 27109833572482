import { z, ZodTypeAny } from 'zod';

import { arrToMapByKeys, capitalizeFirstLetter, isKeyOf, LANE_LETTER_NAME_LIST, isNumber } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { CagingSettingsItem, CCEType, LaneCagingSettings } from '@/graphql/API';

import type {
  TCagingSettingNumberField,
  TRunDesignLane,
  TRunDesignLaneCagingSettings,
} from '@/store/slices/experimentRunDesign';

import {
  ADVANCED_SETTINGS,
  CAGE_SETTINGS_MAIN_SECTIONS,
  DEFAULT_GLOBAL_SETTINGS,
  DEFAULT_MAGNIFICATION,
} from './constants';

import type { TSettingsValidationConfig } from './types';

const FOV_SIZE_BY_MAGNIFICATION: Record<string, number> = {
  '4': 2750,
  '10': 1100,
  '20': 1100,
};

// based on wiki: https://cellanome.atlassian.net/wiki/spaces/~7120209b519b0eacc44d9bb6fe3afb63f5becc/pages/644481058/Caging+Specifications#Default-Settings
export function prepareValidationConfigForAdvancedSettings(
  lane?: TRunDesignLaneCagingSettings,
  globalSettings?: CagingSettingsItem
): Nullable<Record<TCagingSettingNumberField, TSettingsValidationConfig>> {
  const magnification = lane?.magnification ?? DEFAULT_MAGNIFICATION;
  const settings = lane?.overrideSettings ?? globalSettings;

  if (!settings) return null;
  const cageSize = Math.PI * (settings?.cageRadius ?? DEFAULT_GLOBAL_SETTINGS.cageRadius) ** 2;

  return {
    cageRadius: {
      min: 10,
      max: magnification === 4 ? 1000 : 500,
      placeholder: 'Cage radius range',
      valueType: 'um',
    },
    cageWallThickness: {
      min: 2,
      max: cageSize / 2, // 1/2 of the cage size
      placeholder: 'Cage wall thickness',
      valueType: 'um',
      validationPlaceholder: `2 - 1/2 of the cage size`,
    },
    cageToCageDistance: {
      min: 15,
      max: FOV_SIZE_BY_MAGNIFICATION[`${magnification}`],
      placeholder: 'Cage to cage distance range',
      valueType: 'um',
      validationPlaceholder: `15 - size of FOV`,
    },
    cellToCageWallMinDistance: {
      min: 0,
      max: settings?.cageRadius ?? DEFAULT_GLOBAL_SETTINGS.cageRadius,
      placeholder: 'Cell to cage wall min distance',
      valueType: 'um',
      validationPlaceholder: `0 - radius of the cage`,
    },
    cageWallToOtherObjsMinDistance: {
      min: 0,
      max: FOV_SIZE_BY_MAGNIFICATION[`${magnification}`],
      placeholder: 'Cage wall to other objects min distance',
      validationPlaceholder: `0 - FOV max`,
    },
    minCellSize: {
      min: 1,
      max: cageSize * 0.9, // (90% of the cage size)
      placeholder: 'Min cell size',
      valueType: 'um',
      validationPlaceholder: `1 - 90% of CCE size`,
    },
    maxCellSize: {
      min: 1,
      max: cageSize * 0.9, // (90% of the cage size)
      placeholder: 'Max cell size',
      valueType: 'um',
      validationPlaceholder: `1 - 90% of CCE size`,
    },
    maxNumberOfControlCages: {
      min: 1,
      max: magnification === 4 ? 1000 : 330,
      placeholder: 'Max number of control cages',
      validationPlaceholder: `1 - 1000(4X) or 330(10X)`,
    },
    maxCageAreaFraction: {
      min: 0.25,
      max: 0.4,
      placeholder: 'Max cage area fraction',
    },
    dmdExposure: {
      min: 0.5,
      max: 30,
      placeholder: 'DMD Exposure',
      validationPlaceholder: '0.5ms - 30s',
    },
    dmdIntensity: {
      min: 91,
      max: 570,
      placeholder: 'DMD Intensity',
    },
    autoFocusCurrent: {
      min: 0,
      max: 100,
      placeholder: 'Auto-focus current',
      valueType: '%',
    },
    autoFocusExposureTime: {
      min: 0.03,
      max: 50,
      placeholder: 'Auto-focus exposure time',
      valueType: 'ms',
    },
    zOffset: {
      min: -50,
      max: 50,
      placeholder: 'Z Offset',
    },
  };
}

export function validateCagingSettings(
  lane?: TRunDesignLaneCagingSettings,
  globalSettings?: CagingSettingsItem
): { isValid: boolean; errors: Record<TCagingSettingNumberField, string> } {
  let isValid = true;
  const errors: Record<string, string> = {};

  try {
    const limitsBySetting = prepareValidationConfigForAdvancedSettings(lane, globalSettings);

    if (!limitsBySetting) return { isValid, errors };

    const validateLaneCagingAdvancedSettingSchema = z.object({
      cageRadius: z
        .number()
        .min(
          limitsBySetting.cageRadius.min,
          `Cage radius should be greater or equal then ${limitsBySetting.cageRadius.min}`
        )
        .max(
          limitsBySetting.cageRadius.max,
          `Cage radius should be lower or equal then ${limitsBySetting.cageRadius.max}`
        ),
      cageWallThickness: z
        .number()
        .min(
          limitsBySetting.cageWallThickness.min,
          `Cage thickness should be greater or equal then ${limitsBySetting.cageWallThickness.min}`
        )
        .max(
          limitsBySetting.cageWallThickness.max,
          `Cage thickness should be lower or equal then ${limitsBySetting.cageWallThickness.max}`
        ),
      cageToCageDistance: z
        .number()
        .min(
          limitsBySetting.cageToCageDistance.min,
          `Cage to cage distance should be greater or equal then ${limitsBySetting.cageToCageDistance.min}`
        )
        .max(
          limitsBySetting.cageToCageDistance.max,
          `Cage to cage distance should be lower or equal then ${limitsBySetting.cageToCageDistance.max}`
        ),
      cellToCageWallMinDistance: z
        .number()
        .min(
          limitsBySetting.cellToCageWallMinDistance.min,
          `Cage wall distance should be greater or equal then ${limitsBySetting.cellToCageWallMinDistance.min}`
        )
        .max(
          limitsBySetting.cellToCageWallMinDistance.max,
          `Cage wall distance should be lower or equal then ${limitsBySetting.cellToCageWallMinDistance.max}`
        ),
      cageWallToOtherObjsMinDistance: z
        .number()
        .min(
          limitsBySetting.cageWallToOtherObjsMinDistance.min,
          `Cage Wall to other objects should be greater or equal then ${limitsBySetting.cageWallToOtherObjsMinDistance.min}`
        )
        .max(
          limitsBySetting.cageWallToOtherObjsMinDistance.max,
          `Cage Wall to other objects should be lower or equal then ${limitsBySetting.cageWallToOtherObjsMinDistance.max}`
        ),
      minCellSize: z
        .number()
        .min(
          limitsBySetting.minCellSize.min,
          `Min Cell Size should be greater or equal then ${limitsBySetting.minCellSize.min}`
        )
        .max(
          limitsBySetting.minCellSize.max,
          `Min Cell Size should be lower or equal then ${limitsBySetting.minCellSize.max}`
        ),
      maxCellSize: z
        .number()
        .min(
          limitsBySetting.maxCellSize.min,
          `Max Cell Size should be greater or equal then ${limitsBySetting.maxCellSize.min}`
        )
        .max(
          limitsBySetting.maxCellSize.max,
          `Max Cell Size should be lower or equal then ${limitsBySetting.maxCellSize.max}`
        ),
      maxNumberOfControlCages: z
        .number()
        .min(
          limitsBySetting.maxNumberOfControlCages.min,
          `Max number of Control Cages should be greater or equal then ${limitsBySetting.maxNumberOfControlCages.min}`
        )
        .max(
          limitsBySetting.maxNumberOfControlCages.max,
          `Max number of Control Cages should be lower or equal then ${limitsBySetting.maxNumberOfControlCages.max}`
        ),
      maxCageAreaFraction: z
        .number()
        .min(
          limitsBySetting.maxCageAreaFraction.min,
          `Max Cage Area Fraction should be greater or equal then ${limitsBySetting.maxCageAreaFraction.min}`
        )
        .max(
          limitsBySetting.maxCageAreaFraction.max,
          `Max Cage Area Fraction should be lower or equal then ${limitsBySetting.maxCageAreaFraction.max}`
        ),
      dmdExposure: z
        .number()
        .min(
          limitsBySetting.dmdExposure.min,
          `DMD exposure should be greater or equal then ${limitsBySetting.dmdExposure.min}`
        )
        .max(
          limitsBySetting.dmdExposure.max,
          `DMD exposure should be lower or equal then ${limitsBySetting.dmdExposure.max}`
        ),
      dmdIntensity: z
        .number()
        .min(
          limitsBySetting.dmdIntensity.min,
          `DMD intensity should be greater or equal then ${limitsBySetting.dmdIntensity.min}`
        )
        .max(
          limitsBySetting.dmdIntensity.max,
          `DMD intensity should be lower or equal then ${limitsBySetting.dmdIntensity.max}`
        ),
      autoFocusCurrent: z
        .number()
        .min(
          limitsBySetting.autoFocusCurrent.min,
          `Auto-focus current time should be greater or equal then ${limitsBySetting.autoFocusCurrent.min}`
        )
        .max(
          limitsBySetting.autoFocusCurrent.max,
          `Auto-focus current time should be lower or equal then ${limitsBySetting.autoFocusCurrent.max}`
        ),
      autoFocusExposureTime: z
        .number()
        .min(
          limitsBySetting.autoFocusExposureTime.min,
          `Auto-focus exposure time should be greater or equal then ${limitsBySetting.autoFocusExposureTime.min}`
        )
        .max(
          limitsBySetting.autoFocusExposureTime.max,
          `Auto-focus exposure time should be lower or equal then ${limitsBySetting.autoFocusExposureTime.max}`
        ),
      zOffset: z
        .number()
        .min(limitsBySetting.zOffset.min, `Z offset should be greater or equal then ${limitsBySetting.zOffset.min}`)
        .max(limitsBySetting.zOffset.max, `Z offset should be lower or equal then ${limitsBySetting.zOffset.max}`),
    } as Record<TCagingSettingNumberField, ZodTypeAny>);

    if (lane) {
      validateLaneCagingAdvancedSettingSchema.parse(lane.overrideSettings);
    }

    if (globalSettings) {
      validateLaneCagingAdvancedSettingSchema.parse(globalSettings);
    }
  } catch (err) {
    isValid = false;
    if (err instanceof z.ZodError) {
      err.issues.forEach((issue) => {
        issue.path.forEach((pathName) => {
          errors[pathName] = issue.message;
        });
      });
    }
  }
  return { isValid, errors };
}

export const getValidationInfo = (config?: Record<string, string | number>) => {
  if (config?.validationPlaceholder) return config.validationPlaceholder;

  return isNumber(config?.min) && isNumber(config?.max) ? `${config.min} - ${config.max}` : '';
};

export const formatCCETypeLabel = (cceType: CCEType) => {
  const parsedStr = cceType.replaceAll('_', ' ').toLowerCase();
  return capitalizeFirstLetter(parsedStr).replaceAll('cce', 'CCE');
};

export const getDefaultHeaderSections = (isSomeSubtractiveCCE: boolean) => {
  if (isSomeSubtractiveCCE) return CAGE_SETTINGS_MAIN_SECTIONS;

  return CAGE_SETTINGS_MAIN_SECTIONS.map((section) => {
    if (section.key === 'cellsToTarget') {
      const spliced = section.sections.toSpliced(3, 2);
      return {
        key: section.key,
        sections: spliced,
      };
    }
    return section;
  });
};

export const getHeaderSectionsForEditMode = (isSomeSubtractiveCCE: boolean, isViewMoreActive?: boolean) => {
  const defaultSections = getDefaultHeaderSections(isSomeSubtractiveCCE);
  const sections = defaultSections.toSpliced(defaultSections.length - 1, 1);

  if (isViewMoreActive) {
    sections.push({
      key: 'fullAdvanced',
      sections: [...ADVANCED_SETTINGS],
    });
  }

  sections.push({
    key: 'resetToDefault',
    sections: [
      {
        title: 'Reset to default',
        key: 'resetToDefault',
      },
    ],
  });

  return sections;
};

export const cceTypeOptions = Object.values(CCEType).map((value) => ({
  label: formatCCETypeLabel(value),
  value,
}));

export const prepareLanesCagingSettingsData = (newLaneCagingSettings: Nullable<TRunDesignLaneCagingSettings>[]) => {
  let isSomeError = false;
  const errorsByLane: Record<string, Partial<Record<TCagingSettingNumberField, string>>> = {};
  const preparedLanesData = newLaneCagingSettings.reduce((acc: TRunDesignLaneCagingSettings[], laneInfo) => {
    if (!laneInfo) {
      return acc;
    }
    errorsByLane[laneInfo.laneId] = {};

    const { isValid, errors } = validateCagingSettings(laneInfo);
    if (!isValid) {
      isSomeError = true;
      errorsByLane[laneInfo.laneId] = errors;
    }

    const { laneId, overrideSettings, cellToCage, magnification, cellToSubtract, cceType } = laneInfo;

    acc.push({
      laneId,
      magnification,
      cceType,
      cellToCage: cellToCage ?? null,
      cellToSubtract: cellToSubtract ?? null,
      overrideSettings: overrideSettings ? { ...overrideSettings } : null,
      __typename: 'LaneCagingSettings',
    });

    return acc;
  }, []);

  return { preparedLanesData, errorsByLane, isSomeError };
};

export const updateLaneSettingsWhenGlobalChanged = (
  currentSettings: Nullable<TRunDesignLaneCagingSettings>[],
  fieldToUpdate: TCagingSettingNumberField,
  value: number
) => {
  const errorsByLane: Record<string, Partial<Record<TCagingSettingNumberField, string>>> = {};

  const updatedSettings = currentSettings.map((settings) => {
    if (!settings) {
      return null;
    }
    if (!errorsByLane[settings.laneId]) {
      errorsByLane[settings.laneId] = {};
    }

    if (isDefined(settings?.overrideSettings?.[fieldToUpdate])) {
      settings.overrideSettings[fieldToUpdate] = value;
      const { isValid, errors } = validateCagingSettings(settings);

      if (!isValid) {
        errorsByLane[settings.laneId] = errors;
      }
    }

    return settings;
  });

  return { updatedSettings };
};

export const getLanesCagingSettingsFromSaved = (
  globalCagingSettings: CagingSettingsItem,
  laneLetterMap: Record<string, TRunDesignLane>,
  lanesCagingSettings?: LaneCagingSettings[]
) => {
  const settingsByLaneMap = arrToMapByKeys(lanesCagingSettings ?? [], 'laneId');
  const settingsByLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
    if (!laneLetterMap[letter]) {
      return null;
    }
    const result: TRunDesignLaneCagingSettings = {
      ...settingsByLaneMap[letter],
      overrideSettings: {
        ...globalCagingSettings,
        ...settingsByLaneMap[letter]?.overrideSettings,
      },
      laneId: letter,
    };
    return result;
  });

  return settingsByLaneList;
};

export const getChangedLaneSettings = (
  overrideSettings?: Nullable<CagingSettingsItem>,
  globalSettings?: CagingSettingsItem
) => {
  const res = Object.keys(DEFAULT_GLOBAL_SETTINGS).reduce<Record<string, number>>((acc, key) => {
    const currentLaneSetting = isKeyOf(key, overrideSettings ?? {}) ? overrideSettings?.[key] : null;
    const currentGlobalSetting = isKeyOf(key, globalSettings ?? {}) ? globalSettings?.[key] : null;
    if (!isDefined(currentGlobalSetting) || !isDefined(currentLaneSetting)) return acc;

    if (currentGlobalSetting === currentLaneSetting) return acc;

    acc[key] = currentLaneSetting;

    return acc;
  }, {});

  return res;
};
