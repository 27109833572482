import { FC, memo } from 'react';
import { LANE_LETTER_NAME_LIST } from '@/helpers';
import MagnificationRow from './MagnificationRow';

const TableContent: FC = () => (
  <>
    {LANE_LETTER_NAME_LIST.map((laneId, index) => (
      <MagnificationRow key={laneId} laneId={laneId} rowIndex={index} />
    ))}
  </>
);

export default memo(TableContent);
