import { ChangeEventHandler, forwardRef, ForwardRefRenderFunction, memo, useCallback } from 'react';
import classNames from 'classnames/bind';
import TextInput, { TTextInputProps } from '@/components/common/TextInput';
import { getEnum, TEnumValue } from '@/helpers/enum';
import styles from './TimeInput.module.scss';

const cn = classNames.bind(styles);

const timeUnitVariants = ['day', 'hour', 'minute', 'second'] as const;
export const ETimeInputUnit = getEnum(timeUnitVariants);
export type TTimeInputUnit = TEnumValue<typeof ETimeInputUnit>;

type TTimeInputProps = Omit<TTextInputProps, 'onChange' | 'type' | 'value'> & {
  onChange: (value: number) => void;
  unit: TTimeInputUnit;
  value: number;
};

const TimeInput: ForwardRefRenderFunction<HTMLInputElement, TTimeInputProps> = (
  { children, onChange, value, unit, className, inputClassName, ...props },
  ref
) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ currentTarget: { value: newValue } }) => {
      const cleanValue = newValue.replace(/\D/, '');
      const maxLength = 4;
      onChange(Number(cleanValue.slice(0, maxLength)));
    },
    [onChange]
  );

  return (
    <TextInput
      ref={ref}
      onChange={handleChange}
      value={String(value)}
      className={cn(
        'time-input',
        {
          _day: unit === ETimeInputUnit.day,
          _hour: unit === ETimeInputUnit.hour,
          _minute: unit === ETimeInputUnit.minute,
          _second: unit === ETimeInputUnit.second,
        },
        className
      )}
      inputClassName={cn('time-input__input', inputClassName)}
      {...props}
    >
      {children}
    </TextInput>
  );
};

export default memo(forwardRef(TimeInput));
