import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import isEqual from 'lodash.isequal';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { DEFAULT_GLOBAL_SETTINGS } from '@/helpers/runDesigns/CagingSettings/constants';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import PanelWithFolding from '@/components/runDesign/PanelWithFolding';
import icons from '@/components/common/icons';

import styles from './GlobalSettings.module.scss';

import GlobalSettingTables from './table';

const cn = classnames.bind(styles);

const GlobalSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);

  // TODO: NEWFLOW temp disabled by new flow
  const _isSomeGlobalSettingsChanged = useMemo(() => {
    if (!globalCagingSettings) return false;

    return !isEqual(
      {
        __typename: 'CagingSettingsItem',
        ...DEFAULT_GLOBAL_SETTINGS,
      },
      globalCagingSettings
    );
  }, [globalCagingSettings]);

  // TODO: NEWFLOW temp disabled by new flow
  const _resetGlobalSettingsToDefault = useCallback(() => {
    appDispatch(
      experimentRunDesignActions.updateGlobalCagingSettings({
        ...globalCagingSettings,
        ...DEFAULT_GLOBAL_SETTINGS,
        __typename: 'CagingSettingsItem',
      })
    );

    const _laneSettings = {
      __typename: 'CagingSettingsItem',
      ...DEFAULT_GLOBAL_SETTINGS,
    };
  }, [globalCagingSettings]);

  return (
    <div className={cn('global-settings')}>
      <div className={cn('global-settings__header')}>
        <PanelWithFolding.Title title="CCE formation, global settings" Icon={icons.CellanomeSmallIcon} />
        {/* {isSomeGlobalSettingsChanged && (
          <button className={cn('global-settings__reset-btn')} onClick={resetGlobalSettingsToDefault}>
            <icons.ReloadIcon className={cn('global-settings__button-icon')} />
            <span className={cn('global-settings__button-substring')}>Reset to default</span>
          </button>
        )} */}
      </div>
      <div className={cn('global-settings__content')}>
        <GlobalSettingTables />
      </div>
    </div>
  );
};

export default GlobalSettings;
