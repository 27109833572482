import { FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';
import { removeDuplicates } from '@/helpers';
import { CAGING_SETTINGS_WAVES_DEFAULT_REAGENT } from '@/pages/experiment-run-design/CagingSettings/constants';

import { experimentRunDesignSelectors, TRunDesignCellType } from '@/store/slices/experimentRunDesign';
import { CCEType } from '@/graphql/API';

import Select from '@/components/common/Select';

import ReagentSelectedValue from './ReagentSelectedValue';

import styles from '../../EditFields.module.scss';
import ReagentOption from './ReagentOption';
import { TLaneCagingSettings } from '../../../../types';

const cn = classnames.bind(styles);

type TReagentSelector = {
  laneCagingSettings: TLaneCagingSettings;
  setTableData: (data: SetStateAction<Nullable<TLaneCagingSettings>[]>) => void;
  laneIndex: number;
  type: 'cellToCage' | 'cellToSubtract';
  className?: string;
};

const ReagentSelector: FC<TReagentSelector> = ({
  laneCagingSettings,
  setTableData,
  laneIndex,
  type,
  className,
}: TReagentSelector) => {
  const lane = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneCagingSettings.laneId));

  const selectedConsumable = useMemo(
    () => laneCagingSettings?.[type]?.preLabelings?.[0]?.consumable,
    [laneCagingSettings, type]
  );

  const selectedReagent: TBasicOption = useMemo(
    () =>
      selectedConsumable
        ? {
            label: selectedConsumable.name ?? '',
            value: selectedConsumable.id ?? '',
            customData: {
              type: 'Pre-labeled',
              reagent: selectedConsumable,
            },
          }
        : CAGING_SETTINGS_WAVES_DEFAULT_REAGENT,
    [selectedConsumable]
  );

  const optionList = useMemo(() => {
    const reagentConsumablesFromLaneCellTypes: TBasicOption[] = [CAGING_SETTINGS_WAVES_DEFAULT_REAGENT];

    lane?.cellTypes?.forEach((cellType) => {
      cellType?.preLabelings?.forEach((preLabeling) => {
        if (isReagentConsumable(preLabeling?.consumable)) {
          const data = {
            label: preLabeling.consumable.name,
            value: preLabeling.consumable.id,
            customData: {
              type: 'Pre-labeled',
              reagent: preLabeling.consumable,
            },
          };
          reagentConsumablesFromLaneCellTypes.push(data);
        }
      });
    });

    return removeDuplicates(reagentConsumablesFromLaneCellTypes, 'value');
  }, [lane?.cellTypes]);

  const handleChangeCellsToCageReagent = useCallback(
    (value: string) => {
      const currentOption = optionList.find((option) => value === option.value);
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const laneData = newSettings[laneIndex];

        if (!currentOption?.customData?.reagent || !laneData?.[type]) {
          return prev;
        }

        const dataSource = type === 'cellToCage' ? laneData?.cellToCage : laneData?.cellToSubtract;

        if (!dataSource?.preLabelings?.[0]?.consumable) {
          laneData[type] = {
            ...laneData[type],
            preLabelings: [
              {
                __typename: 'ConsumableToUse',
                consumable: { ...currentOption.customData.reagent },
              },
            ],
          } as TRunDesignCellType;
        } else {
          dataSource.preLabelings[0].consumable = { ...currentOption.customData.reagent };
        }

        return newSettings;
      });
    },
    [optionList, laneCagingSettings]
  );

  useEffect(() => {
    if (laneCagingSettings.cceType === CCEType.FLUORECENT_CELLS && selectedReagent?.customData?.type === 'Default') {
      const automatedSelectedOption = optionList.find((option) => option?.customData?.type !== 'Default');

      if (!automatedSelectedOption?.value) return;
      handleChangeCellsToCageReagent(String(automatedSelectedOption.value));
    }
  }, [laneCagingSettings.cceType, optionList]);

  return (
    <Select
      value={selectedReagent}
      customComponents={{
        Option: ReagentOption as any,
        SingleValue: () => (<ReagentSelectedValue selectedValue={selectedReagent} />) as any,
      }}
      onChange={handleChangeCellsToCageReagent}
      placeholder=""
      options={optionList}
      theme={themeOptions.light}
      valueContainerClassName={cn('select__waves-value-container')}
      className={cn('select', className)}
      controlClassName={cn('select__control')}
      menuClassName={cn('select__menu', 'select__menu_waves')}
      optionClassName={cn('select__option')}
      menuListClassName={cn('select__waves-menu-list')}
      disabled={!laneCagingSettings?.[type]}
      menuPosition="absolute"
      isPortalable={false}
    />
  );
};

export default ReagentSelector;
