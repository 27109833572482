import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { isDefined } from '@/helpers/typeGuards';

const checkIsComponentInValidOrder = (
  component: TRunDesignComponent,
  componentList: TRunDesignComponent[]
): { isValid: true } | { isValid: false; relation: 'before' | 'after'; componentNameList: string[]; type: string } => {
  const sameTypeComponentList = componentList.filter(({ type }) => type === component.type);
  const componentIndexAcrossSameComponentList = sameTypeComponentList.findIndex(({ name }) => name === component.name);
  const prevSameTypeComponent = sameTypeComponentList[componentIndexAcrossSameComponentList - 1] ?? null;
  const nextSameTypeComponent = sameTypeComponentList[componentIndexAcrossSameComponentList + 1] ?? null;

  const getComponentOrderFromName = (name: string) => {
    const orderWithoutSpecification = 1;
    let order = Number(name.match(/\d+$/)?.[0]);

    if (!isDefined(order)) {
      order = orderWithoutSpecification;
    }

    return order;
  };

  const componentOrder = getComponentOrderFromName(component.name);

  if (prevSameTypeComponent) {
    const prevComponentOrder = getComponentOrderFromName(prevSameTypeComponent.name);

    if (prevComponentOrder && componentOrder && prevComponentOrder > componentOrder) {
      return {
        isValid: false,
        relation: 'after',
        componentNameList: [component.name, prevSameTypeComponent.name],
        type: component.type,
      };
    }
  }

  if (nextSameTypeComponent) {
    const nextComponentOrder = getComponentOrderFromName(nextSameTypeComponent.name);

    if (nextComponentOrder && componentOrder && nextComponentOrder < componentOrder) {
      return {
        isValid: false,
        relation: 'before',
        componentNameList: [component.name, nextSameTypeComponent.name],
        type: component.type,
      };
    }
  }

  return {
    isValid: true,
  };
};

export default checkIsComponentInValidOrder;
