/**
 * TODO: Icons optimizations:
 * - Create autoimport script for all icons and generate sprite
 * - Change <icons.[iconName] /> to find needed icon with <use xlink:href
 */

import AssaySurfaceReceptor from './AssaySurfaceReceptor';
import AssayCellKilling from './AssayCellKilling';
import AssayMRnaExpression from './AssayMRnaExpression';
import AssayCiteSeq from './AssayCiteSeq';
import AssayGuideSeq from './AssayGuideSeq';
import ReagentSearchIcon from './ReagentSearchIcon';
import FilterIcon from './FilterIcon';
import CardsViewIcon from './CardsViewIcon';
import TickIcon from './TickIcon';
import ChangeViewIcon from './ChangeViewIcon';
import AlignmentIcon from './AlignmentIcon';
import CloseIcon from './CloseIcon';
import ChartViewIcon from './ChartViewIcon';
import TableViewIcon from './TableViewIcon';
import PlayIcon from './PlayIcon';
import PauseIcon from './PauseIcon';
import DownloadIcon from './DownloadIcon';
import PrintIcon from './PrintIcon';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';
import ArrowStartIcon from './ArrowStartIcon';
import DotIcon from './DotIcon';
import ArrowBackIcon from './ArrowBackIcon';
import ArrowDropdownIcon from './ArrowDropdownIcon';
import ArrowNavigatorIcon from './ArrowNavigatorIcon';
import ArrowTimeIcon from './ArrowTimeIcon';
import ArrowUnprocessedIcon from './ArrowUnprocessedIcon';
import CheckMarkIcon from './CheckMarkIcon';
import TimelineIcon from './TimelineIcon';
import SettingsIcon from './SettingsIcon';
import GridPlus from './GridPlus';
import HamburgerIcon from './HamburgerIcon';
import MinusDashboard from './MinusDashboard';
import NavigatorIcon from './NavigatorIcon';
import ThreeDotIcon from './ThreeDotIcon';
import SearchIcon from './SearchIcon';
import CheckboxCheckedIcon from './CheckboxCheckedIcon';
import CheckboxDashIcon from './CheckboxDashIcon';
import ReagentsListIcon from './ReagentsListIcon';
import ReagentsGridIcon from './ReagentsGridIcon';
import Loading from './Loading';
import LoadingCheckmark from './LoadingCheckmark';
import ReloadIcon from './ReloadIcon';
import ArrowTimeLeftIcon from './ArrowTimeLeftIcon';
import SnapshotIcon from './SnapshotIcon';
import ArrowNavigatorFocusIcon from './ArrowNavigatorFocusIcon';
import DotsIcon from './DotsIcon';
import FullScreenIcon from './FullScreenIcon';
import ExpandScreenIcon from './ExpandScreenIcon';
import CollapseIcon from './CollapseIcon';
import ArrowWhiteIcon from './ArrowWhiteIcon';
import DatasetIcon from './DatasetIcon';
import NavigatorCircleIcon from './NavigatorCircleIcon';
import FileIcon from './FileIcon';
import LaneChoosedIcon from './LaneChoosedIcon';
import UnknownIcon from './UnknownIcon';
import AssayCytokineSecretion from './AssayCytokineSecretion';
import AssayCellProliferation from './AssayCellProliferation';
import PersonIcon from './PersonIcon';
import ClockIcon from './ClockIcon';
import ArrowRightIcon from './ArrowRightIcon';
import ExpandMore from './ExpandMore';
import CameraIcon from './CameraIcon';
import FilledClockIcon from './FilledClockIcon';
import AxisSwitchIcon from './AxisSwitchIcon';
import SlashIcon from './SlashIcon';
import SquareIcon from './SquareIcon';
import PolygonIcon from './PolygonIcon';
import CircleIcon from './CircleIcon';
import PolarIcon from './PolarIcon';
import ImportGateIcon from './ImportGateIcon';
import ShowAxisIcon from './ShowAxisIcon';
import AppliedToAllIcon from './AppliedToAllIcon';
import VisibilityOffIcon from './VisibilityOffIcon';
import VisibilityOnIcon from './VisibilityOnIcon';
import DeleteIcon from './DeleteIcon';
import DeleteChildrensIcon from './DeleteChildrensIcon';
import TimelineArrowPrev from './TimelineArrowPrev';
import TimelineArrowNext from './TimelineArrowNext';
import GateOnParentIcon from './GateOnParentIcon';
import PencilIcon from './PencilIcon';
import ViewerShrinkIcon from './ViewerShrinkIcon';
import ViewerStretchIcon from './ViewerStretchIcon';
import OpenFileIcon from './OpenFileIcon';
import SaveIcon from './SaveIcon';
import FolderIcon from './FolderIcon';
import ProjectCardArrowIcon from './ProjectCardArrowIcon';
import AttachIcon from './AttachIcon';
import DetachIcon from './DetachIcon';
import TouchIcon from './TouchIcon';
import PreprocessingIcon from './PreprocessingIcon';
import PreprocessingLargeIcon from './PreprocessingLargeIcon';
import LinkIcon from './LinkIcon';
import EditIcon from './EditIcon';
import AttentionIcon from './AttentionIcon';
import ArrowsUpIcon from './ArrowsUpIcon';
import CheckboxInCircleIcon from './CheckboxInCircleIcon';
import RangeGateIcon from './RangeGateIcon';
import SplitGateIcon from './SplitGateIcon';
import ExportStatisticsIcon from './ExportStatisticsIcon';
import ApplyGateToIcon from './ApplyGateToIcon';
import ResetGateToGlobalIcon from './ResetGateToGlobalIcon';
import DetailsIcon from './DetailsIcon';
import DisableIcon from './DisableIcon';
import QRCodeIcon from './QRCodeIcon';
import BigPlus from './BigPlus';
import ExpandIcon from './ExpandIcon';
import CollapseV2Icon from './CollapseV2Icon';
import LockIcon from './LockIcon';
import UsersIcon from './UsersIcon';
import TeamsIcon from './TeamsIcon';
import JobsIcon from './JobsIcon';
import ProjectsIcon from './ProjectsIcon';
import BackArrowIcon from './BackArrowIcon';
import CagingIcon from './CagingIcon';
import ScanningIcon from './ScanningIcon';
import IncubationOffDeviceIcon from './IncubationOffDeviceIcon';
import ReceptorIcon from './ReceptorIcon';
import SecretionIcon from './SecretionIcon';
import RetentionIcon from './RetentionIcon';
import IncubationIcon from './IncubationIcon';
import CellKillingIcon from './CellKillingIcon';
import TimelineCellKillingIcon from './TimelineCellKillingIcon';
import ScanningStackedIcon from './ScanningStackedIcon';
import WashIcon from './WashIcon';
import ArrowToIcon from './ArrowToIcon';
import ManifestIcon from './ManifestIcon';
import MRnaIcon from './MRnaIcon';
import CopyIcon from './CopyIcon';
import PasteIcon from './PasteIcon';
import ArrowPointerIcon from './ArrowPointerIcon';
import CageRadiusIcon from './CageRadiusIcon';
import CageWallThicknessIcon from './CageWallThicknessIcon';
import CageToCageDistanceIcon from './CageToCageDistanceIcon';
import CellToCageWallMinDistanceIcon from './CellToCageWallMinDistanceIcon';
import CageWallToOtherObjectsMinDistanceIcon from './CageWallToOtherObjectsMinDistanceIcon';
import MinCellSizeIcon from './MinCellSizeIcon';
import MaxCellSizeIcon from './MaxCellSizeIcon';
import MaxNumberOfControlCagesIcon from './MaxNumberOfControlCagesIcon';
import CageSettingsIcon from './CageSettingsIcon';
import ExclamationIcon from './ExclamationIcon';
import InstrumentIcon from './InstrumentIcon';
import InstrumentsDashboardIcon from './InstrumentsDashboardIcon';
import ExperimentStatus from './ExperimentStatus';
import ExperimentPipelineStatus from './ExperimentPipelineStatus';
import MenuIcon from './MenuIcon';
import StainIcon from './StainIcon';
import CellKillingStainIcon from './CellKillingStainIcon';
import InfoIcon from './InfoIcon';
import InfoRectangularIcon from './InfoRectangularIcon';
import InfoRectangularMiniIcon from './InfoRectangularMiniIcon';
import AddAntibodyIcon from './AddAntibodyIcon';
import AddMediaIcon from './AddMediaIcon';
import CellLysisIcon from './CellLysisIcon';
import ElutionIcon from './ElutionIcon';
import SegmentIcon from './SegmentIcon';
import RunDesignClockIcon from './RunDesignClockIcon';
import SelectDefaultCheckmark from './SelectDefaultCheckmark';
import FovIcon from './FovIcon';
import ScanningSmallIcon from './ScanningSmallIcon';
import CellanomeSmallIcon from './CellanomeSmallIcon';
import FovFillIcon from './FovFillIcon';
import RevertIcon from './RevertIcon';
import BinIcon from './BinIcon';
import DndDotsIcon from './DndDotsIcon';
import PlusInCircleIcon from './PlusInCircleIcon';
import IncubationSmallIcon from './IncubationSmallIcon';
import RectangleLanePlateWrapperIcon from './RectangleLanePlateWrapperIcon';
import SlashBigIcon from './SlashBigIcon';
import SlashSmallIcon from './SlashSmallIcon';

export default {
  // general
  FolderIcon,
  ArrowsUpIcon,
  MenuIcon,
  InfoIcon,

  // projects page
  ProjectCardArrowIcon,

  // experiment page
  CloseIcon,
  PlayIcon,
  PauseIcon,

  // samples page
  DownloadIcon,
  PrintIcon,
  ReagentsListIcon,
  ReagentsGridIcon,
  Loading,
  LoadingCheckmark,

  // start page
  PlusIcon,
  MinusIcon,
  ArrowStartIcon,
  DotIcon,

  //
  ArrowBackIcon,
  ArrowDropdownIcon,
  ArrowNavigatorIcon,
  ArrowTimeIcon,
  ArrowUnprocessedIcon,
  CheckMarkIcon,
  TimelineIcon,
  SettingsIcon,
  GridPlus,
  HamburgerIcon,
  MinusDashboard,
  NavigatorIcon,
  ThreeDotIcon,
  SearchIcon,
  CheckboxCheckedIcon,
  CheckboxDashIcon,
  ReloadIcon,
  ArrowTimeLeftIcon,
  SnapshotIcon,
  ArrowNavigatorFocusIcon,
  DotsIcon,
  FullScreenIcon,
  ExpandScreenIcon,
  CollapseIcon,
  ArrowWhiteIcon,
  DatasetIcon,
  NavigatorCircleIcon,
  FileIcon,
  LaneChoosedIcon,
  ShowAxisIcon,
  AppliedToAllIcon,
  VisibilityOffIcon,
  VisibilityOnIcon,
  DeleteIcon,
  DeleteChildrensIcon,
  GateOnParentIcon,
  PencilIcon,
  OpenFileIcon,
  AttachIcon,
  DetachIcon,
  LinkIcon,
  EditIcon,
  CheckboxInCircleIcon,
  DisableIcon,
  QRCodeIcon,
  BigPlus,
  ExpandIcon,
  CollapseV2Icon,
  LockIcon,
  BackArrowIcon,
  CopyIcon,
  PasteIcon,

  // run design
  CagingIcon,
  ScanningIcon,
  IncubationOffDeviceIcon,
  ReceptorIcon,
  SecretionIcon,
  RetentionIcon,
  IncubationIcon,
  CellKillingIcon,
  TimelineCellKillingIcon,
  ScanningStackedIcon,
  WashIcon,
  ArrowToIcon,
  ManifestIcon,
  MRnaIcon,
  StainIcon,
  CellKillingStainIcon,
  AddAntibodyIcon,
  AddMediaIcon,
  ReagentSearchIcon,
  SelectDefaultCheckmark,
  FovIcon,
  ScanningSmallIcon,
  CellanomeSmallIcon,
  FovFillIcon,
  RevertIcon,
  BinIcon,
  DndDotsIcon,
  PlusInCircleIcon,
  IncubationSmallIcon,
  RectangleLanePlateWrapperIcon,
  SlashBigIcon,
  SlashSmallIcon,

  // run design caging settings
  CageRadiusIcon,
  CageWallThicknessIcon,
  CageToCageDistanceIcon,
  CellToCageWallMinDistanceIcon,
  CageWallToOtherObjectsMinDistanceIcon,
  MinCellSizeIcon,
  MaxCellSizeIcon,
  MaxNumberOfControlCagesIcon,
  CageSettingsIcon,
  RunDesignClockIcon,

  // experiment fluidics
  ChartViewIcon,
  TableViewIcon,

  UnknownIcon,
  AssaySurfaceReceptor,
  AssayCytokineSecretion,
  AssayCellKilling,
  AssayMRnaExpression,
  AssayCiteSeq,
  AssayGuideSeq,
  AssayCellProliferation,
  PersonIcon,
  ChangeViewIcon,
  ClockIcon,
  AlignmentIcon,
  ArrowRightIcon,
  TickIcon,
  CardsViewIcon,
  ExpandMore,
  CameraIcon,
  FilledClockIcon,
  AxisSwitchIcon,
  SlashIcon,
  SaveIcon,
  AttentionIcon,
  ArrowPointerIcon,

  // plotly modebar icons
  SquareIcon,
  PolygonIcon,
  CircleIcon,
  PolarIcon,
  ImportGateIcon,
  TouchIcon,
  RangeGateIcon,
  SplitGateIcon,

  // navigator
  TimelineArrowPrev,
  TimelineArrowNext,

  ViewerStretchIcon,
  ViewerShrinkIcon,

  // datasets
  PreprocessingIcon,
  PreprocessingLargeIcon,

  ExportStatisticsIcon,

  ApplyGateToIcon,
  ResetGateToGlobalIcon,

  DetailsIcon,
  UsersIcon,
  TeamsIcon,
  JobsIcon,
  ProjectsIcon,

  ExclamationIcon,

  InstrumentIcon,
  InstrumentsDashboardIcon,
  ExperimentStatus,
  ExperimentPipelineStatus,

  InfoRectangularIcon,
  InfoRectangularMiniIcon,
  FilterIcon,
  CellLysisIcon,
  ElutionIcon,
  SegmentIcon,
};
