import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import { SubCompIncubation, TemperatureUnit } from '@/graphql/API';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions } from '@/store/slices/experimentRunDesign';

import styles from './IncubationBaseBlock.module.scss';
import Duration from './Duration';
import Temperature from './Temperature';
import OnDeviceInput from './OnDeviceInput';

const cn = classnames.bind(styles);

type TIncubationBaseBlockProps = Omit<SubCompIncubation, '__typename'> & { componentId: string };

const IncubationBaseBlock: FC<TIncubationBaseBlockProps> = ({
  duration,
  temperature,
  temperatureUnit,
  onDevice,
  componentId,
}) => {
  const appDispatch = useAppDispatch();

  const onChangeDuration = useCallback(
    (value: number) => {
      appDispatch(
        experimentRunDesignActions.updateSubCompIncubation({ id: componentId, updatedData: { duration: value } })
      );
    },
    [componentId]
  );

  const onChangeTemperature = useCallback(
    (value: number) => {
      appDispatch(
        experimentRunDesignActions.updateSubCompIncubation({ id: componentId, updatedData: { temperature: value } })
      );
    },
    [componentId]
  );

  const onChangeTemperatureUnit = useCallback(
    (value: TemperatureUnit) => {
      appDispatch(
        experimentRunDesignActions.updateSubCompIncubation({ id: componentId, updatedData: { temperatureUnit: value } })
      );
    },
    [componentId]
  );

  const onChangeOnDevice = useCallback(
    (value: boolean) => {
      appDispatch(
        experimentRunDesignActions.updateSubCompIncubation({ id: componentId, updatedData: { onDevice: value } })
      );
    },
    [componentId]
  );

  return (
    <div className={cn('incubation-base-block')}>
      <div className={cn('incubation-base-block__column')}>
        <Duration initDuration={duration} onChange={onChangeDuration} />
        <Temperature
          temperature={temperature}
          onChange={onChangeTemperature}
          temperatureUnit={temperatureUnit}
          onChangeUnit={onChangeTemperatureUnit}
        />
      </div>
      <div className={cn('incubation-base-block__column')}>
        <OnDeviceInput isOnDevice={onDevice} toggleSwitch={onChangeOnDevice} />
      </div>
    </div>
  );
};

export default memo(IncubationBaseBlock);
