import { FC } from 'react';
import classNames from 'classnames/bind';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { TScanTotal } from '../types';
import styles from '../MainBlock.module.scss';
import { scanSelectorOptions } from '../constants';

const cn = classNames.bind(styles);

type TNoScanContentProps = {
  scanTotal: {
    value: string;
    onChange: (value: TScanTotal) => void;
  };
};

const NoScanContent: FC<TNoScanContentProps> = ({ scanTotal }) => (
  <div className={cn('main-block__content')}>
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title')}>scans total</div>
          <Select
            options={scanSelectorOptions}
            value={scanTotal.value}
            onChange={scanTotal.onChange}
            theme={themeOptions.light}
            placeholder="Select scan total..."
            className={cn('main-block__select')}
            controlClassName={cn('main-block__select-control')}
            menuClassName={cn('main-block__select-menu')}
            menuListClassName={cn('main-block__select-menu-list')}
            menuPosition="absolute"
            isPortalable={false}
            customComponents={{ Option: Select.DefaultOption }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default NoScanContent;
