import { v4 as uuidv4 } from 'uuid';
import { CellKilling, CytokineSecretion, Incubation, mRNA, Placement, ScanComp, SurfaceReceptor } from '@/graphql/API';
import getInitialWash from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getInitialWash';
import getInitialScanConfig from './getInitialScanConfig';
import getInitialIncubation from './getInitialIncubation';

export const getInitialIncubationComponent = (counterInName: number, relativeTo?: string): Incubation => ({
  __typename: 'Incubation',
  id: uuidv4(),
  name: counterInName > 1 ? `Incubation ${counterInName}` : 'Incubation',
  type: 'Incubation',
  performedOnLanes: [],
  performedOnLanesWithMedia: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
});

export const getInitialSurfaceReceptorComponent = (counterInName: number, relativeTo?: string): SurfaceReceptor => ({
  __typename: 'SurfaceReceptor',
  id: uuidv4(),
  name: counterInName > 1 ? `Surface Receptor ${counterInName}` : 'Surface Receptor',
  type: 'SurfaceReceptor',
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialCytokineSecretionComponent = (
  counterInName: number,
  relativeTo?: string
): CytokineSecretion => ({
  __typename: 'CytokineSecretion',
  id: uuidv4(),
  name: counterInName > 1 ? `Cytokine Secretion ${counterInName}` : 'Cytokine Secretion',
  type: 'CytokineSecretion',
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialMRNAComponent = (counterInName: number, relativeTo?: string): mRNA => ({
  __typename: 'mRNA',
  id: uuidv4(),
  name: counterInName > 1 ? `mRNA ${counterInName}` : 'mRNA',
  type: 'mRNA',
  performedOnLanes: [],
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

export const getInitialCellKillingComponent = (counterInName: number, relativeTo?: string): CellKilling => ({
  __typename: 'CellKilling',
  id: uuidv4(),
  name: counterInName > 1 ? `Cell Killing ${counterInName}` : 'Cell Killing',
  type: 'CellKilling',
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.SIMULTANEOUS,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialScanComponent = (partOf?: string, relativeTo?: string): ScanComp => ({
  __typename: 'ScanComp',
  id: uuidv4(),
  name: 'Scan',
  type: 'ScanComp',
  duration: 90 * 60,
  partOf,
  pauseAfterCompletion: null,
  performedOnLanes: [],
  tags: null,
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

// TODO: NEWFLOW use this instead getInitialScanComponent after approve
export const getInitialScanComponentNewFlow = (counterInName: number, relativeTo?: string): ScanComp => ({
  __typename: 'ScanComp',
  id: uuidv4(),
  name: counterInName > 1 ? `Scan ${counterInName}` : 'Scan',
  type: 'ScanComp',
  duration: 90 * 60,
  pauseAfterCompletion: null,
  performedOnLanes: [],
  tags: null,
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});
