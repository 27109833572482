import { FC } from 'react';
import classnames from 'classnames/bind';

import ComponentHeaderWrapper from '../../../components/ComponentHeaderWrapper';

import styles from './CellCageComponent.module.scss';
import SwitchView from '../../components/SwitchView';

const cn = classnames.bind(styles);

const title = 'CellCage™ Enclosure settings';

const CellCageHeader: FC = () => (
  <ComponentHeaderWrapper title={title} showDivide={false} contentClassName={cn('header')}>
    <SwitchView />
  </ComponentHeaderWrapper>
);

export default CellCageHeader;
