import { FC, memo } from 'react';

import RunDesignBlockWithLanesNewFlow from '@/components/runDesign/RunDesignBlockWithLanes/RunDesignBlockWithLanesNewFlow';

import CellCageHeader from './CellCageHeader';
import CellCageContent from './CellCageContent';

const CellCageComponent: FC = () => (
  <>
    <CellCageHeader />
    <RunDesignBlockWithLanesNewFlow>
      <CellCageContent />
    </RunDesignBlockWithLanesNewFlow>
  </>
);

export default memo(CellCageComponent);
