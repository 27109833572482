import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { DEFAULT_LANES_COUNT, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isCellKillingComponent } from '@/helpers/runDesigns/typeGuards';
import { dndReorder } from '@/helpers/dnd';
import { renameByTypeOrder } from '@/helpers/runDesigns/components/updateOnAddAndRemove';
import { validateCagingSettings } from '@/helpers/runDesigns/CagingSettings';

import {
  AdvancedOpticsSettings,
  CCEType,
  Lane,
  LaneConsumablesWithMedia,
  OpticsSettingsItem,
  Reagent,
  RunDesign,
  RunDesignTemplate,
  SubCompIncubation,
} from '@/graphql/API';
import { DEFAULT_CELL_TYPE, DEFAULT_GLOBAL_SETTINGS } from '@/helpers/runDesigns/CagingSettings/constants';

import type { TReagentListByLaneList } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsCard/types';

import {
  EditFieldsType,
  isIncubationComponent,
  isSubCompIncubation,
  TCagingSettingNumberField,
  TExperimentRunDesignState,
  TRunDesignCagingSettings,
  TRunDesignComponent,
  TRunDesignConsumableToUse,
  TRunDesignEditFields,
} from './types';

import {
  updateLanesSettings,
  getParsedCagingSettings,
  getParsedLanesData,
  updateSampleNames,
  clearGeneratedSampleNames,
  updateCurrentOpticSettings,
  getParsedPerformedOnLanesMediaData,
  addNewLaneHelper,
  removeLaneHelper,
  addNewCellTypeHelper,
  removeCellTypeHelper,
  updateLaneHelper,
  updateCellTypesHelper,
  addIncubationComponentHelper,
  removeIncubationComponentHelper,
  updateComponentsLanesReagentsHelper,
  updateMediaSettingsByLanesHelper,
  removeComponentHelper,
} from './helpers';
import { graphqlAPI } from '../../services/graphql';

export const DEFAULT_WIZARD_STEP = 0;

const initialFieldsState = {
  name: '',
  description: '',
  investigatorId: '',
  projectId: '',
  externalLinks: [],
  organisms: [],
  flowcellType: undefined, // here undefined instead of null, because it is optional field in graphql
  schema: null,
};

const initialState: TExperimentRunDesignState = {
  source: {
    type: null,
    id: null,
  },
  data: {
    templateId: null,
    templateName: null,
    runDesignId: null,
    runDesignDate: null,
    wizardStep: DEFAULT_WIZARD_STEP,
  },
  editFields: {
    initial: initialFieldsState,
    current: initialFieldsState,
  },
  addData: {
    runDesignCardIndexEdit: -1,
    runDesignCardIndexExpand: -1,
    isAdvancedMode: false,
    isGlobalSettingsView: true,
    isAutoGenerateSampleNames: false,
    isSampleNamesGenerated: false,
    isEditFieldsChanged: false,
  },
  cagingStoreData: {
    laneErrors: {},
    globalErrors: {},
  },
};

const experimentRunDesignSlice = createSlice({
  name: 'experimentRunDesign',
  initialState,
  reducers: {
    clear: (state) => {
      state.source = {
        type: null,
        id: null,
      };
      state.data = {
        templateId: null,
        templateName: null,
        runDesignId: null,
        runDesignDate: null,
        wizardStep: DEFAULT_WIZARD_STEP,
      };
      state.editFields = {
        initial: initialFieldsState,
        current: initialFieldsState,
      };
      state.addData = initialState.addData;
    },

    setSource: (state, action: PayloadAction<{ sourceType?: string; sourceId?: string }>) => {
      const { sourceType, sourceId } = action.payload;
      state.source = {
        type: (sourceType as ERunDesignSourceType) ?? null,
        id: sourceId ?? null,
      };
    },

    setData: (state, action: PayloadAction<{ template?: RunDesignTemplate; runDesign?: RunDesign }>) => {
      const { template, runDesign } = action.payload;
      state.data = {
        templateId: template?.id ?? null,
        templateName: template?.name ?? null,
        runDesignId: runDesign?.id ?? null,
        runDesignDate: runDesign?.createdAt ?? null,
        wizardStep: runDesign?.wizardStep ?? DEFAULT_WIZARD_STEP,
      };
    },

    setEditFieldsFromTemplate: (state, action: PayloadAction<RunDesignTemplate>) => {
      const { name, description } = action.payload;
      const initialFields = {
        ...structuredClone(initialFieldsState),
        name,
        description: description ?? initialFieldsState.description,
        schema: initialFieldsState.schema,
      };
      state.editFields = {
        initial: initialFields,
        current: initialFields,
      };
    },

    setEditFieldsFromRunDesign: (state, action: PayloadAction<RunDesign>) => {
      const { name, description, investigatorId, projectId, externalLinks, organisms, schema, flowcellType } =
        action.payload;
      const initialFields = {
        name,
        description: description ?? initialFieldsState.description,
        investigatorId,
        projectId,
        externalLinks: externalLinks ?? initialFieldsState.externalLinks,
        organisms: organisms ?? initialFieldsState.organisms,
        schema: structuredClone(schema) ?? initialFieldsState.schema,
        flowcellType: flowcellType ?? initialFieldsState.flowcellType,
      };

      // TODO: delete when the backend sends the correct position for cell killing by default
      initialFields.schema.components = initialFields.schema.components?.map((component: any) => ({
        ...component,
        timing: {
          ...component.timing,
          placement: isCellKillingComponent(component) ? 'SIMULTANEOUS' : component.timing.placement,
          relativeTo: isCellKillingComponent(component)
            ? initialFields.schema.components?.find((comp) => component.deliveryStainsAt?.includes(comp.id))?.id ??
              initialFields?.schema?.components?.[0]?.id
            : component.timing.relativeTo,
        },
      }));

      if (initialFields?.schema && !initialFields?.schema.cagingSettings.global) {
        initialFields.schema.cagingSettings = {
          __typename: 'CagingSettings',
          global: {
            __typename: 'CagingSettingsItem',
            ...DEFAULT_GLOBAL_SETTINGS,
          },
          perLane: initialFields.schema.cagingSettings.perLane ?? [],
        };
      } else {
        initialFields.schema.cagingSettings.global = getParsedCagingSettings(
          initialFields?.schema.cagingSettings.global
        );
      }

      // Set one lane with one cell type by default
      if (initialFields.schema?.lanes?.length === 0) {
        for (let i = 0; i < DEFAULT_LANES_COUNT; i++) {
          initialFields.schema.lanes.push({
            __typename: 'Lane',
            id: LANE_LETTER_NAME_LIST[i],
            sample: '',
            cellTypes: [null],
          });
        }
      } else {
        initialFields.schema.lanes = getParsedLanesData(initialFields.schema.lanes);
      }

      state.editFields = {
        initial: initialFields,
        current: structuredClone(initialFields),
      };
      if (!initialFields?.schema?.cagingSettings?.perLane.length) {
        updateLanesSettings(state);
      }
      updateCurrentOpticSettings(state, initialFields.schema.opticsSettings);
      updateCurrentOpticSettings(state, initialFields.schema.opticsSettings, EditFieldsType.initial);
      getParsedPerformedOnLanesMediaData(state);
      getParsedPerformedOnLanesMediaData(state, EditFieldsType.initial);
    },

    setEditFields: (state, action: PayloadAction<Partial<TRunDesignEditFields>>) => {
      state.editFields.current = { ...state.editFields.current, ...action.payload };
      updateLanesSettings(state);
    },

    addNewLane: (state, action: PayloadAction<string | undefined>) => {
      addNewLaneHelper(state, action);
    },

    removeLane: (state, action: PayloadAction<string | undefined>) => {
      removeLaneHelper(state, action);
    },

    addNewCellType: (state) => {
      addNewCellTypeHelper(state);
    },

    removeCellType: (state) => {
      removeCellTypeHelper(state);
    },

    updateLane: (state, action: PayloadAction<{ laneId: string; updatedData: Partial<Lane> }>) => {
      updateLaneHelper(state, action);
    },

    updateCellTypes: (state, action: PayloadAction<Lane[]>) => {
      updateCellTypesHelper(state, action);
    },

    // TODO: NEWFLOW need remove this after approve
    setRunDesignCardIndexEdit: (state, action: PayloadAction<number>) => {
      state.addData.runDesignCardIndexEdit = action.payload;
    },

    // TODO: NEWFLOW need remove this after approve
    setRunDesignCardIndexExpand: (state, action: PayloadAction<number>) => {
      state.addData.runDesignCardIndexExpand = action.payload;
    },

    setIsAdvancedMode: (state, action: PayloadAction<boolean>) => {
      state.addData.isAdvancedMode = action.payload;
    },
    toggleAdvancedMode: (state) => {
      state.addData.isAdvancedMode = !state.addData.isAdvancedMode;
    },

    updateComponents: (state, action: PayloadAction<TRunDesignComponent[]>) => {
      if (!state.editFields.current.schema) {
        state.editFields.current.schema = { __typename: 'RunDesignSchema' };
      }
      const renamedComponents = renameByTypeOrder(action.payload);
      state.editFields.current.schema.components = renamedComponents;
    },

    dndComponent: (state, action: PayloadAction<{ dragIndex: number; dropIndex: number; isBefore: boolean }>) => {
      const { dragIndex, dropIndex, isBefore } = action.payload;
      if (!state.editFields.current.schema?.components?.length) {
        return;
      }

      const { components } = state.editFields.current.schema;
      const reorderedComponents = dndReorder(current(components), dragIndex, dropIndex, isBefore);
      const renamedComponents = renameByTypeOrder(reorderedComponents);
      state.editFields.current.schema.components = renamedComponents;
    },

    removeComponent: (state, action: PayloadAction<{ id: string }>) => {
      removeComponentHelper(state, action);
      state.addData.currentComponentId = null;
    },

    addIncubationComponent: (state, action: PayloadAction<{ id: string }>) => {
      addIncubationComponentHelper(state, action);
    },

    removeIncubationComponent: (state) => {
      removeIncubationComponentHelper(state);
    },

    updateComponentsLanesReagents: (state, action: PayloadAction<TReagentListByLaneList>) => {
      updateComponentsLanesReagentsHelper(state, action);
    },

    updateMediaSettingsByLanes: (state, action: PayloadAction<Record<string, LaneConsumablesWithMedia>>) => {
      updateMediaSettingsByLanesHelper(state, action);
    },

    updateGlobalCagingSettings: (state, action: PayloadAction<TRunDesignCagingSettings['global']>) => {
      if (state.editFields.current.schema?.cagingSettings?.global) {
        state.editFields.current.schema.cagingSettings.global = action.payload;
      }
    },

    updateGlobalCagingSettingsNumberField: (
      state,
      action: PayloadAction<{ field: TCagingSettingNumberField; value: number }>
    ) => {
      const { field, value } = action.payload;
      const globalSettings = state.editFields.current.schema?.cagingSettings?.global;
      if (!globalSettings) {
        return;
      }

      globalSettings[field] = value;

      const lanes = state.editFields.current.schema?.cagingSettings?.perLane;

      lanes?.forEach((laneData) => {
        if (!laneData?.overrideSettings) {
          return;
        }
        laneData.overrideSettings[field] = value;
        const { errors } = validateCagingSettings(laneData);
        state.cagingStoreData.laneErrors[laneData.laneId] = errors;
      });
    },

    updateLanesCagingSettings: (state, action: PayloadAction<TRunDesignCagingSettings['perLane']>) => {
      if (state.editFields.current.schema?.cagingSettings) {
        state.editFields.current.schema.cagingSettings.perLane = action.payload;
      }
    },

    updateLaneCagingSettingsNumberField: (
      state,
      action: PayloadAction<{ laneId: string; field: TCagingSettingNumberField; value: number }>
    ) => {
      const { field, laneId, value } = action.payload;
      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );
      if (!laneData) {
        return;
      }
      const globalSettings = state.editFields.current.schema?.cagingSettings?.global;
      if (!globalSettings) {
        return;
      }

      const laneSettings = laneData.overrideSettings;

      const newLaneSettings = {
        ...globalSettings,
        ...laneSettings,
      };

      newLaneSettings[field] = value;
      laneData.overrideSettings = newLaneSettings;

      const { errors } = validateCagingSettings(laneData);
      state.cagingStoreData.laneErrors[laneData.laneId] = errors;
    },

    updateLaneCagingSettingsCCEType: (state, action: PayloadAction<{ laneId: string; cceType: CCEType }>) => {
      const { laneId, cceType } = action.payload;
      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );
      if (!laneData) {
        return;
      }

      laneData.cceType = cceType;
    },

    updateLaneCagingSettingsMagnification: (
      state,
      action: PayloadAction<{ laneId: string; magnification: number }>
    ) => {
      const { laneId, magnification } = action.payload;
      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );
      if (!laneData) {
        return;
      }

      laneData.magnification = magnification;
    },

    updateLaneCagingSettingsCellName: (
      state,
      action: PayloadAction<{ laneId: string; type: 'cellToCage' | 'cellToSubtract'; cellIndexId: string }>
    ) => {
      const { type, laneId, cellIndexId } = action.payload;
      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );
      if (!laneData) {
        return;
      }

      const cellIndex = state.editFields.current.schema?.lanes
        ?.find((lane) => lane.id === laneId)
        ?.cellTypes?.find((cellType) => cellType?.cellIndex?.id === cellIndexId)?.cellIndex;

      if (!cellIndex) {
        return;
      }

      const dataSource = type === 'cellToCage' ? laneData?.cellToCage : laneData?.cellToSubtract;

      if (!dataSource?.cellIndex) {
        laneData[type] = {
          ...DEFAULT_CELL_TYPE,
          cellIndex,
        };
      } else {
        dataSource.cellIndex = cellIndex;
      }
    },

    updateLaneCagingSettingsPreLabelingReagent: (
      state,
      action: PayloadAction<{ laneId: string; type: 'cellToCage' | 'cellToSubtract'; consumable: Reagent }>
    ) => {
      const { type, laneId, consumable } = action.payload;

      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );

      if (!laneData?.[type]) {
        return;
      }

      const consumableToUse: TRunDesignConsumableToUse = {
        __typename: 'ConsumableToUse',
        consumable,
      };

      const preLabelings = laneData[type]?.preLabelings;

      if (preLabelings?.[0]) {
        preLabelings[0] = consumableToUse;
      } else {
        // @ts-ignore
        laneData[type].preLabelings = [consumableToUse];
      }
    },

    resetLaneCagingSettings: (state, action: PayloadAction<{ laneId: string }>) => {
      const { laneId } = action.payload;
      const laneData = state.editFields.current.schema?.cagingSettings?.perLane?.find(
        (lane) => lane?.laneId === laneId
      );

      const globalSettings = state.editFields.current?.schema?.cagingSettings?.global;
      if (!laneData || !globalSettings) {
        return;
      }

      delete state.cagingStoreData.laneErrors[laneData.laneId];

      laneData.overrideSettings = { ...globalSettings };
    },

    recheckAllCagingSettings: (state) => {
      const lanes = state.editFields.current.schema?.cagingSettings?.perLane;
      if (!lanes?.length) {
        return;
      }

      state.cagingStoreData.laneErrors = {};

      lanes.forEach((laneData) => {
        if (!laneData) {
          return;
        }
        const { errors } = validateCagingSettings(laneData);
        state.cagingStoreData.laneErrors[laneData.laneId] = errors;
      });

      const globalSettings = state.editFields.current.schema?.cagingSettings?.global;

      const { errors } = validateCagingSettings(undefined, globalSettings);
      state.cagingStoreData.globalErrors = errors;
    },

    updateGlobalOpticalSettings: (state, action: PayloadAction<OpticsSettingsItem[]>) => {
      if (state.editFields.current.schema?.opticsSettings) {
        state.editFields.current.schema.opticsSettings.global = action.payload;
      }
    },

    updateMatrixOpticalSettings: (state, action: PayloadAction<AdvancedOpticsSettings[]>) => {
      if (state.editFields.current.schema?.opticsSettings) {
        state.editFields.current.schema.opticsSettings.advanced = action.payload;
      }
    },

    updateLanesOpticalSettings: (_state, _action: PayloadAction<OpticsSettingsItem[]>) => {
      // empty code, because type structure is not correct
    },
    setIsGlobalSettingsView: (state, action: PayloadAction<boolean>) => {
      state.addData.isGlobalSettingsView = action.payload;
    },
    toggleGlobalSettingsView: (state) => {
      state.addData.isGlobalSettingsView = !state.addData.isGlobalSettingsView;
    },
    toggleAutoGenerateSampleNameSwitch: (state) => {
      const newValue = !state.addData.isAutoGenerateSampleNames;
      state.addData.isAutoGenerateSampleNames = newValue;

      if (!newValue || !state.editFields.current.schema?.lanes?.length) {
        clearGeneratedSampleNames(state);
        return;
      }
      updateSampleNames(state);
    },
    setIsAutoGenerateSampleNameSwitch: (state, action: PayloadAction<boolean>) => {
      state.addData.isAutoGenerateSampleNames = action.payload;
    },
    generateSampleNamesForNextSteps: (state) => {
      if (!state.editFields.current.schema?.lanes?.length) {
        return;
      }
      updateSampleNames(state);
      state.addData.isSampleNamesGenerated = true;
    },
    setIsSampleNamesGenerated: (state, action: PayloadAction<boolean>) => {
      state.addData.isSampleNamesGenerated = action.payload;
    },
    setCurrentComponentId: (state, action: PayloadAction<string | null>) => {
      state.addData.currentComponentId = action.payload;
    },
    updateSubCompIncubation: (
      state,
      action: PayloadAction<{ id: string; updatedData: Partial<SubCompIncubation> }>
    ) => {
      const { id, updatedData } = action.payload;
      const incubationComponent = state.editFields.current.schema?.components?.find(
        (component) => component?.id === id
      );

      if (!incubationComponent || !isIncubationComponent(incubationComponent)) {
        return;
      }

      const { incubation } = incubationComponent;

      if (!isSubCompIncubation(incubation)) {
        return;
      }

      incubationComponent.incubation = {
        ...incubation,
        ...updatedData,
      };
    },
    revertUnsaved: (state) => {
      const currentUnsavedEditFields = current(state.editFields.initial);
      const currentEditFields = current(state.editFields.current);
      if (isEqual(currentEditFields, currentUnsavedEditFields)) {
        return;
      }
      state.editFields.current = structuredClone(current(state.editFields.initial));
    },
    setIsEditFieldsChanged: (state, action: PayloadAction<boolean>) => {
      state.addData.isEditFieldsChanged = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(graphqlAPI.endpoints.fetchRunDesign.matchFulfilled, (state, action) => {
      const { id } = action.payload;
      if (state.source.type === ERunDesignSourceType.draft && id === state.source.id) {
        experimentRunDesignSlice.caseReducers.setEditFieldsFromRunDesign(state, action);
      }
    });
  },
});

export default experimentRunDesignSlice;
