import { SVGProps } from 'react';

const PlusInCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="35" height="35" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="6.24927" y="11.875" width="12.5" height="0.9375" fill="currentColor" />
    <rect x="13.1234" y="6.25" width="12.5" height="0.9375" transform="rotate(90 13.1234 6.25)" fill="currentColor" />
    <rect
      x="0.75"
      y="-0.75"
      width="23.4985"
      height="23.5"
      rx="11.7493"
      transform="matrix(1 0 0 -1 0 23.5)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default PlusInCircleIcon;
