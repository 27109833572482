import { CSSProperties, memo, MutableRefObject, ReactNode, useRef } from 'react';
import classnames from 'classnames/bind';

import styles from './RunDesignTable.module.scss';

import Row from './Row';
import Column from './Column';

import ReactSelectable from '../../common/ReactSelectable';

const cn = classnames.bind(styles);

type TRunDesignTable<T> = {
  className?: string;
  children?: ReactNode;
  header?: ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  style?: CSSProperties;
  isEdit?: boolean;
  tableData: T;
  setTableData?: (data: T) => void;
  innerTableRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

function RunDesignTable<T>({
  className,
  children,
  header,
  headerClassName,
  bodyClassName,
  style,
  setTableData,
  tableData,
  isEdit,
  innerTableRef,
}: TRunDesignTable<T>) {
  const tableRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef(null);

  return (
    <>
      <table
        ref={(ref) => {
          tableRef.current = ref;
          if (innerTableRef) {
            innerTableRef.current = ref;
          }
        }}
        className={cn('table', className)}
        style={style}
      >
        <thead className={cn('table__head', headerClassName)}>{header}</thead>
        <tbody className={cn('table__body', bodyClassName)}>{children}</tbody>
      </table>
      {isEdit && <ReactSelectable tableRef={tableRef} setTableData={setTableData} tableData={tableData} />}
    </>
  );
}

RunDesignTable.Row = memo(Row);
RunDesignTable.Column = memo(Column);
export default RunDesignTable;
