import Back from './Back';
import Continue from './Continue';
import Group from './Group';
import Revert from './Revert';
import Save from './Save';
import SaveAndExit from './SaveAndExit';
import Tabs from './Tabs';
import Wrapper from './Wrapper';

const FooterControls = {
  Continue,
  SaveAndExit,
  Save,
  Revert,
  Group,
  Wrapper,
  Tabs,
  Back,
};

export default FooterControls;
