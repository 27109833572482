import { TMergeItems } from './types';

export const baseMerge = (cacheList: TMergeItems, newList: TMergeItems): void => {
  const newListClone = structuredClone(newList);
  if (!cacheList.pagination) {
    cacheList.pagination = { nextToken: undefined, total: cacheList.items.length ?? 0 };
  }
  if (!newListClone.pagination) {
    newListClone.pagination = { nextToken: undefined, total: cacheList.items.length ?? 0 };
  }
  cacheList.pagination.nextToken = newListClone.pagination.nextToken;
  cacheList.pagination.total = newListClone.pagination.total ?? 0;
  const mergedList = [
    ...new Map(
      cacheList.items.concat(newListClone.items).map((item) => {
        const itemKey = item.id;
        return [itemKey, item];
      })
    ).values(),
  ];

  cacheList.items = mergedList.toSorted((a: TMergeItems['items'][0], b: TMergeItems['items'][0]) =>
    new Date(b.createdAt).getTime() <= new Date(a.createdAt).getTime() ? -1 : 1
  );
};
