import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { isKeyOf } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { ADVANCED_SETTINGS, DEFAULT_GLOBAL_SETTINGS } from '@/helpers/runDesigns/CagingSettings/constants';
import { getValidationInfo, prepareValidationConfigForAdvancedSettings } from '@/helpers/runDesigns/CagingSettings';

import {
  experimentRunDesignSelectors,
  experimentRunDesignActions,
  TCagingSettingNumberField,
} from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import SettingInput from '../EditSettings/components/SettingsInput/SettingsInput';

import styles from './GlobalSettings.module.scss';

const cn = classnames.bind(styles);

const GlobalSettingTables = () => {
  const appDispatch = useAppDispatch();
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const lanesCagingSettings = useSelector(experimentRunDesignSelectors.selectLanesCagingSettings);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const globalCagingSettingsErrors = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettingsErrors);

  useEffect(() => {
    appDispatch(experimentRunDesignActions.recheckAllCagingSettings());
  }, [globalCagingSettings]);

  const cageSettingsInputValidationConfig = useMemo(
    () => prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings),
    [globalCagingSettings]
  );

  const editableSettingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const settingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && !setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      appDispatch(experimentRunDesignActions.updateGlobalCagingSettingsNumberField({ field, value }));
    },
    []
  );

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  return (
    <div
      role="presentation"
      onClick={handleCardContentClick}
      className={cn('container', { container_clickable: !isEditMode })}
    >
      <RunDesignTable
        tableData={[]}
        className={cn('table')}
        headerClassName={cn('table__header')}
        header={
          <RunDesignTable.Row>
            <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
            <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
            <RunDesignTable.Column />
          </RunDesignTable.Row>
        }
      >
        {editableSettingsList.map(({ key, title, icon, customdata = '' }) => {
          const isMixed = lanesCagingSettings?.some((settings) => {
            if (!globalCagingSettings?.[key] || !settings) return false;
            return settings?.overrideSettings?.[key] !== globalCagingSettings?.[key];
          });
          return (
            <RunDesignTable.Row key={key} className={cn('table__row')}>
              <RunDesignTable.Column className={cn('table__column')}>
                <div className={cn('table__column-content')}>
                  {title} {customdata}
                </div>
                <div className={cn('table__column-content_light')}>
                  {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                </div>
              </RunDesignTable.Column>

              <RunDesignTable.Column className={cn('table__column')}>
                <SettingInput
                  onChangeNumberField={handleChangeNumberField}
                  isMixed={isMixed}
                  value={globalCagingSettings?.[key] ?? 0}
                  field={key}
                  error={globalCagingSettingsErrors[key]}
                  defaultValue={DEFAULT_GLOBAL_SETTINGS[key] ?? undefined}
                  isEditMode={isEditMode}
                  cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
                />
              </RunDesignTable.Column>
              <RunDesignTable.Column className={cn('table__column', 'table__column_icon')}>
                {icon}
              </RunDesignTable.Column>
            </RunDesignTable.Row>
          );
        })}
      </RunDesignTable>
      <div>
        <RunDesignTable
          tableData={[]}
          className={cn('table')}
          headerClassName={cn('table__header')}
          header={
            <RunDesignTable.Row>
              <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
              <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
              <RunDesignTable.Column />
            </RunDesignTable.Row>
          }
        >
          {settingsList.map(({ key, title, icon, customdata = '' }, index) => (
            <RunDesignTable.Row key={key} className={cn('table__row')}>
              <RunDesignTable.Column
                className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
              >
                <div className={cn('table__column-content')}>
                  {title} {customdata} *
                </div>
                <div className={cn('table__column-content_light')}>
                  {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                </div>
              </RunDesignTable.Column>
              <RunDesignTable.Column
                className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
              >
                <span>{globalCagingSettings?.[key]}</span>
              </RunDesignTable.Column>
              <RunDesignTable.Column
                className={cn(
                  'table__column',
                  { table__column_bordered: index === settingsList.length - 1 },
                  'table__column_icon'
                )}
              >
                {icon}
              </RunDesignTable.Column>
            </RunDesignTable.Row>
          ))}
        </RunDesignTable>
        <div className={cn('info')}>
          <span> *</span>
          <span className={cn('info__text')}>Default values for settings are displayed but cannot be edited.</span>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettingTables;
