import { CSSProperties, FC, useMemo } from 'react';
import classNames from 'classnames/bind';

import { TCardData } from '@/pages/experiment-run-design/DesignTimeline/components/Timeline/types';

import { iconIdList } from '@/helpers/runDesigns/constants';
import { isCellKillingComponent, isComponentWithScan, isScanComponent } from '@/helpers/runDesigns/typeGuards';
import {
  getComponentDuration,
  getComponentPosition,
  getScanDuration,
  getScanLabelsMap,
} from '@/helpers/runDesigns/timeline';

import { CellKilling, Placement, ScanComp } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import { getComponentIcon, getTimeText } from './helpers';

import TimelineCard from './TimelineCard';

import styles from './Timeline.module.scss';

const cn = classNames.bind(styles);

type TTimelineProps = {
  onCardSelect: (id: string) => void;
  componentList: Nullable<TRunDesignComponent[]>;
};

const Timeline: FC<TTimelineProps> = ({ onCardSelect, componentList }) => {
  const timelineComponentList = useMemo(
    () => componentList?.filter((component) => !isScanComponent(component)) ?? [],
    [componentList]
  );

  const scanComponentList = useMemo(() => componentList?.filter<ScanComp>(isScanComponent) ?? [], [componentList]);

  const cellKillingDeliveryStainsAt = useMemo(() => {
    const cellKillingComponent = componentList?.find((component) => isCellKillingComponent(component)) as CellKilling;
    return cellKillingComponent?.deliveryStainsAt ?? [];
  }, [componentList]);

  const firstRowComponentList = useMemo(
    () => timelineComponentList?.filter((component) => component.timing.placement !== Placement.SIMULTANEOUS) ?? [],
    [timelineComponentList]
  );

  const firstRowRunsCount = useMemo(() => firstRowComponentList.length, [firstRowComponentList]);

  const timeRecordList = useMemo(
    () =>
      firstRowComponentList?.reduce<{ id: string; start: number; duration: number }[]>((acc, component) => {
        const lastStartTime = acc.at(-1)?.start ?? 0;
        const lastDuration = acc.at(-1)?.duration ?? 0;

        acc.push({
          id: component.id,
          start: lastStartTime + lastDuration,
          duration: getComponentDuration(component, scanComponentList),
        });

        return acc;
      }, []) ?? [],
    [firstRowComponentList]
  );

  const timelineCardDataList: TCardData[] = useMemo(() => {
    if (!timelineComponentList) {
      return [];
    }

    const scanLabelsMap = getScanLabelsMap(timelineComponentList ?? []);
    let simultaneousRowIndex = 2;

    return timelineComponentList.map((component) => {
      const isMultiScan = ((isComponentWithScan(component) && component.scanConfig?.numberOfScans) || 1) > 1;

      let rowIndex = 1;
      if (component.timing.placement === Placement.SIMULTANEOUS) {
        rowIndex = simultaneousRowIndex;
        simultaneousRowIndex += 1;
      }

      return {
        component,
        durationText: {
          duration: getComponentDuration(component),
          // comment: component.incubation?.onDevice ? 'on-device' : 'off-device',
        },
        iconCollection: getComponentIcon(component),
        scan:
          isComponentWithScan(component) && component?.scanConfig?.numberOfScans
            ? {
                durationText: {
                  duration: getScanDuration(component, componentList),
                },
                title: scanLabelsMap[component.id],
                iconCollection: isMultiScan ? [iconIdList.iconScanningStacked] : [iconIdList.iconScanning],
              }
            : undefined,
        position: getComponentPosition(firstRowComponentList, component, rowIndex),
      };
    });
  }, [firstRowComponentList, cellKillingDeliveryStainsAt]);

  const handleCardClickFactory = (id: string) => () => {
    onCardSelect(id);
  };

  return (
    <div className={cn('timeline')} style={{ '--column-count': Math.max(1, firstRowRunsCount) } as CSSProperties}>
      <div className={cn('timeline__heading')}>
        {timeRecordList.map((timeRecord) => (
          <div className={cn('timeline__time-block')} key={timeRecord.id}>
            <div className={cn('timeline__time')}>{getTimeText(timeRecord.start)}</div>
          </div>
        ))}
      </div>
      <div className={cn('timeline__body')}>
        {timelineCardDataList.map((cardData) => (
          <TimelineCard
            key={cardData.component.id}
            data={cardData}
            cardDataList={timelineCardDataList}
            onClick={handleCardClickFactory(cardData.component.id)}
            position={cardData.position}
          />
        ))}
      </div>
    </div>
  );
};

export default Timeline;
