import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import DialogModal from '@/components/common/DialogModal';

import styles from './RemoveStep.module.scss';

const cn = classnames.bind(styles);

type TConfirmRemoveModal = {
  title: string;
  isOpen: boolean;
  name: string;
  dialogClassName?: string;
  confirmationClassName?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmRemoveModal: FC<TConfirmRemoveModal> = ({
  isOpen,
  name,
  title,
  dialogClassName,
  confirmationClassName,
  onCancel,
  onConfirm,
}) => (
  <DialogModal title={title} onClose={onCancel} open={isOpen} className={cn('dialog', dialogClassName)}>
    <div className={cn('confirmation', confirmationClassName)}>
      <div className={cn('confirmation__text-wrap')}>
        <div className={cn('confirmation__title')}>
          You are removing <div className={cn('confirmation__tag', 'confirmation__tag_current')}>{name}</div>
        </div>
        <span className={cn('confirmation__text')}>Are you sure?</span>
      </div>
      <div className={cn('confirmation__controls')}>
        <Button color="light" onClick={onCancel} className={cn('confirmation__button', 'confirmation__button_cancel')}>
          Cancel
        </Button>
        <Button
          color="yellow"
          onClick={onConfirm}
          className={cn('confirmation__button', 'confirmation__button_confirm')}
        >
          Confirm
        </Button>
      </div>
    </div>
  </DialogModal>
);

export default memo(ConfirmRemoveModal);
