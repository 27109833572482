import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import { THeaderSideItemProps } from '../../types';

import { usePanelWithFoldingContext } from '../../context';

import styles from './Header.module.scss';

const cn = classnames.bind(styles);

const HeaderSideItem: FC<THeaderSideItemProps> = ({ id, renderHeader, blockClassNameFn, blockIndex }) => {
  const { sideBlocksOpenStatusMap, toggleSide, collapseAllSideBlocks } = usePanelWithFoldingContext();

  const isOpen = useMemo(() => !!sideBlocksOpenStatusMap[id], [sideBlocksOpenStatusMap, id]);

  const toggleOpen = useCallback(() => {
    toggleSide(id, !isOpen, blockIndex);
  }, [id, blockIndex, isOpen]);

  // Computes the class name for the header block based on the provided function.
  const blockClassName: string | undefined = useMemo(
    () => blockClassNameFn?.({ isOpen, toggleOpen, collapseAllSideBlocks }),
    [isOpen, toggleOpen, blockClassNameFn, collapseAllSideBlocks]
  );

  return (
    <div className={cn('header-side', blockClassName)}>
      {renderHeader({ toggleOpen, isOpen, collapseAllSideBlocks })}
    </div>
  );
};

export default HeaderSideItem;
