import { Amplify } from 'aws-amplify';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';

import { getAwsClientMetadata } from '@/helpers';

import datadogConfig from './datadog-exports';
import awsconfig from './aws-exports';

export const initializeAmplify = () => {
  Amplify.configure({
    ...awsconfig,
    Auth: {
      ...awsconfig.Auth,
      clientMetadata: getAwsClientMetadata(),
    },
  });

  if (process.env.REACT_APP_DATADOG_APPLICATION_ID) {
    datadogRum.init({
      ...datadogConfig,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    });
  }
};
