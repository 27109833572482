import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { uiReducer } from './slices/ui';
import { appAPI } from './services/app';
import { intAPI } from './services/intsvc';
import { cdnAPI } from './services/cdnData';
import { antnAPI } from './services/annotation';
import { authAPI } from './services/auth';
import { graphqlAPI } from './services/graphql';
import { runDesignGraphqlAPI } from './services/graphql/runDesign';
import { gatesReducer } from './slices/gates';
import { gatesAutoPreprocessingReducer } from './slices/gatesAutoPreprocessing';
import { scatterplotsReducer } from './slices/scatterplots';
import { chartDataReducer } from './slices/chartData';
import { chartSettingsReducer } from './slices/chartSettings';
import { headerReducer } from './slices/header';
import { experimentReducer } from './slices/experiment';
import { experimentRunDesignReducer } from './slices/experimentRunDesign';
import { viewerReducer } from './slices/viewer';
import { navigatorReducer } from './slices/navigator';
import { datasetsReducer } from './slices/datasets';
import { preprocessingReducer } from './slices/preprocessing';
import { histogramSettingsReducer } from './slices/histogramSettings';
import { violinReducer } from './slices/violin';
import { awsReducer } from './slices/aws';

export const store = configureStore({
  reducer: {
    experiment: experimentReducer,
    experimentRunDesign: experimentRunDesignReducer,
    gates: gatesReducer,
    gatesAutoPreprocessing: gatesAutoPreprocessingReducer,
    scatterplots: scatterplotsReducer,
    chartData: chartDataReducer,
    chartSettings: chartSettingsReducer,
    header: headerReducer,
    [appAPI.reducerPath]: appAPI.reducer,
    [cdnAPI.reducerPath]: cdnAPI.reducer,
    [intAPI.reducerPath]: intAPI.reducer,
    [antnAPI.reducerPath]: antnAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [graphqlAPI.reducerPath]: graphqlAPI.reducer,
    [runDesignGraphqlAPI.reducerPath]: runDesignGraphqlAPI.reducer,
    viewer: viewerReducer,
    violin: violinReducer,
    ui: uiReducer,
    aws: awsReducer,
    navigator: navigatorReducer,
    datasets: datasetsReducer,
    preprocessing: preprocessingReducer,
    histogramSettings: histogramSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      appAPI.middleware,
      cdnAPI.middleware,
      intAPI.middleware,
      antnAPI.middleware,
      authAPI.middleware,
      graphqlAPI.middleware,
      runDesignGraphqlAPI.middleware
    ),
});

// A utility used to enable refetchOnFocus and refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
