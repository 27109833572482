import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API as awsAPI } from 'aws-amplify';

import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import {
  CreateRunDesignFromTemplateInput,
  GenerateReagentManifestResult,
  RunDesign,
  RunDesignEdge,
  RunDesignsQueryVariables,
  RunDesignTemplate,
  RunDesignTemplateVersion,
  UpdateRunDesignInput,
} from '@/graphql/API';
import { isNumber, parseJSON } from '@/helpers/common';

// TODO: NEWFLOW possible need remove when migration to new graphql api
export const graphqlAPI = createApi({
  reducerPath: 'graphqlAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['RunDesign', 'RunDesignList'],
  endpoints: (build) => ({
    fetchTemplate: build.query<RunDesignTemplate, string | undefined>({
      // @ts-ignore
      queryFn: async (templateId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesignTemplate,
            variables: {
              id: templateId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesignTemplate };
        } catch (error) {
          return { error };
        }
      },
    }),

    fetchTemplateVersion: build.query<RunDesignTemplateVersion, string | undefined>({
      // @ts-ignore
      queryFn: async (templateId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesignTemplateVersion,
            variables: {
              id: templateId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesignTemplateVersion };
        } catch (error) {
          return { error };
        }
      },
    }),

    fetchRunDesign: build.query<RunDesign, string | undefined>({
      // @ts-ignore
      queryFn: async (runDesignId) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesign,
            variables: {
              id: runDesignId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          return { data: response?.data.runDesign };
        } catch (error) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: 'RunDesign', id }],
    }),

    createRunDesign: build.mutation<string, CreateRunDesignFromTemplateInput>({
      // @ts-ignore
      queryFn: async (input) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.createRunDesignFromTemplate,
            variables: { input },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const createdRunDesignId = response?.data?.createRunDesignFromTemplate.id;
          if (createdRunDesignId) {
            return { data: createdRunDesignId };
          }
          return { error: 'Error creating run design' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ['RunDesignList'],
    }),

    updateRunDesign: build.mutation<RunDesign, { id: string; input: UpdateRunDesignInput }>({
      // @ts-ignore
      queryFn: async ({ id, input }) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.updateRunDesign,
            variables: { id, input },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { updateRunDesign } = response?.data ?? {};
          if (updateRunDesign) {
            return { data: updateRunDesign };
          }
          return { error: 'Error updating run design' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'RunDesign', id }],
      onQueryStarted: async ({ id, input }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          // @ts-ignore
          graphqlAPI.util.updateQueryData('fetchRunDesigns', undefined, (draft) => {
            const runDesign = draft.list.find((item) => item.node.id === id);
            if (runDesign?.node && isNumber(input?.wizardStep)) {
              const parsedInputSchema = parseJSON(input.schema);

              runDesign.node = {
                ...runDesign.node,
                wizardStep: input.wizardStep,
                name: input?.name ? input?.name : runDesign?.node.name,
                investigatorId: input?.investigatorId ? input?.investigatorId : runDesign?.node.investigatorId,
                schema: parsedInputSchema ?? runDesign?.node?.schema ?? null,
              };
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    publishRunDesign: build.mutation<RunDesign, string>({
      // @ts-ignore
      queryFn: async (id) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.publishRunDesign,
            variables: { id },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { publishRunDesign } = response?.data ?? {};
          if (publishRunDesign) {
            return { data: publishRunDesign };
          }
          return { error: 'Error publishing run design' };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'RunDesign', id }],
    }),

    generateReagentManifest: build.mutation<GenerateReagentManifestResult, string>({
      // @ts-ignore
      queryFn: async (id) => {
        try {
          const response = await awsAPI.graphql({
            query: mutations.generateReagentManifest,
            variables: { generateReagentManifestId: id },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { generateReagentManifest } = response?.data ?? {};

          if (generateReagentManifest) {
            return { data: generateReagentManifest };
          }
          return { error: 'Error generating manifest' };
        } catch (error) {
          return { error };
        }
      },
    }),
    fetchRunDesigns: build.query<{ list: RunDesignEdge[]; nextToken: string }, RunDesignsQueryVariables>({
      // @ts-ignore
      queryFn: async ({ limit, nextToken }) => {
        try {
          const response = await awsAPI.graphql({
            query: queries.runDesigns,
            variables: { input: { limit, after: nextToken } },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          });
          // @ts-ignore
          const { runDesigns } = response?.data ?? {};

          if (runDesigns) {
            // response conversion for ease of use useFetchNextToken hook
            return {
              data: {
                list: runDesigns?.edges ?? [],
                nextToken: runDesigns?.pageInfo.endCursor,
              },
            };
          }
          return { error: 'Error generating manifest' };
        } catch (error) {
          return { error };
        }
      },
      merge: (currentCache, newItems) => {
        currentCache.nextToken = newItems.nextToken;
        const mergedList = [
          ...new Map(
            currentCache.list.concat(newItems.list).map((item) => {
              const itemKey = item.node.id;
              return [itemKey, item];
            })
          ).values(),
        ];

        currentCache.list = mergedList.toSorted((a: RunDesignEdge, b: RunDesignEdge) =>
          new Date(b.node.createdAt).getTime() <= new Date(a.node.createdAt).getTime() ? -1 : 1
        );
      },
      serializeQueryArgs: () => 'fetchRunDesigns',
      providesTags: ['RunDesignList'],
    }),
  }),
});
