import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';

import { CCEType, LaneCagingSettings, OpticsSettingsItem } from '@/graphql/API';

import { arrToMapByKeys, isNumber, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';
import { isAssayComponent } from '@/helpers/runDesigns/typeGuards';

import type { RootState } from '@/store';

import { EComponentTypes, TRunDesignCellType } from './types';
import { getMatrixOpticalSettings } from './helpers';

export const selectSource = (state: RootState) => state.experimentRunDesign.source;

export const selectData = (state: RootState) => state.experimentRunDesign.data;

export const selectTemplateName = (state: RootState) => state.experimentRunDesign.data.templateName;

export const selectRunDesignDate = (state: RootState) => state.experimentRunDesign.data.runDesignDate;

export const selectInitialEditFields = (state: RootState) => state.experimentRunDesign.editFields.initial;

export const selectCurrentEditFields = (state: RootState) => state.experimentRunDesign.editFields.current;

export const selectComponentList = (state: RootState) =>
  state.experimentRunDesign.editFields.current?.schema?.components ?? null;

export const selectComponentListWithoutScan = createSelector([selectComponentList], (componentList) =>
  (componentList ?? []).filter((el) => el.type !== EComponentTypes.ScanComponent)
);

export const selectScanComponentList = createSelector([selectComponentList], (componentList) =>
  (componentList ?? []).filter((el) => el.type === EComponentTypes.ScanComponent)
);

export const selectOpticalMatrixSettings = createSelector(
  [selectComponentList, selectCurrentEditFields],
  (componentList, editFields) => getMatrixOpticalSettings(componentList ?? [], editFields)
);

export const selectIncubationComponentList = createSelector([selectComponentList], (componentList) =>
  (componentList ?? []).filter((component) => component.type === 'Incubation')
);

export const selectAssayComponentList = createSelector([selectComponentList], (componentList) =>
  (componentList ?? []).filter((component) => isAssayComponent(component))
);

export const selectComponentById = (componentId: string) =>
  createSelector([selectComponentList], (componentList) =>
    (componentList ?? []).find((component) => component.id === componentId)
  );

export const selectComponentByTypeName = (typename: string) =>
  createSelector([selectComponentList], (componentList) =>
    (componentList ?? []).find((component) => component.__typename === typename)
  );

export const selectAssayComponentsCount = createSelector(
  [selectAssayComponentList],
  (assayComponentList) => assayComponentList.length
);

export const selectCurrentLanes = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.lanes ?? null;

export const selectCurrentLaneLetterMap = createSelector([selectCurrentLanes], (laneList) =>
  arrToMapByKeys(laneList ?? [], 'id')
);

export const selectCountOfCellTypes = (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  return lanes?.[0]?.cellTypes?.length ?? 0;
};

export const selectIsEditFieldsHaveChanges = createSelector(
  [selectInitialEditFields, selectCurrentEditFields],
  (initialEditFields, currentEditFields) => !isEqual(initialEditFields, currentEditFields)
);

export const selectCurrentLaneById = (laneId: string) => (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  const lane = lanes?.find((el) => laneId === el.id);
  return lane;
};

export const selectSomeRunDesignCardIsEdit = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexEdit >= 0;

export const selectSomeRunDesignCardIsExpand = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexExpand >= 0;

export const selectRunDesignCardIndexEdit = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexEdit;
export const selectRunDesignCardIndexExpand = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexExpand;
export const selectIsAdvancedMode = (state: RootState) => state.experimentRunDesign.addData.isAdvancedMode;

export const selectIsAllLanesHasPreLabelingEX = (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  return (
    lanes?.every((lane) =>
      lane.cellTypes?.some((cellType) => !!cellType?.preLabelings?.some((preLabeling) => preLabeling?.consumable))
    ) ?? false
  );
};

export const selectIsPreLabelingEX = (cellTypeIndex?: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    if (isNumber(cellTypeIndex)) {
      // select is PreLabeling in specific cellType
      return (
        lanes?.some(
          (lane) =>
            !!lane.cellTypes?.find(
              (cellType, index) =>
                index === cellTypeIndex && !!cellType?.preLabelings?.find((preLabeling) => preLabeling?.consumable)
            )
        ) ?? false
      );
    }
    return (
      lanes?.some((lane) =>
        lane.cellTypes?.some((cellType) => !!cellType?.preLabelings?.find((preLabeling) => preLabeling?.consumable))
      ) ?? false
    );
  });

export const selectIsPreTreatmentEX = (cellTypeIndex?: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    if (isNumber(cellTypeIndex)) {
      // select is PreTreatment in specific cellType
      return (
        lanes?.some(
          (lane) =>
            !!lane.cellTypes?.find(
              (cellType, index) =>
                index === cellTypeIndex && !!cellType?.preTreatments?.find((preTreatment) => preTreatment?.consumable)
            )
        ) ?? false
      );
    }
    return (
      lanes?.some((lane) =>
        lane.cellTypes?.some((cellType) => !!cellType?.preTreatments?.find((preTreatment) => preTreatment?.consumable))
      ) ?? false
    );
  });

export const selectCellTypeListByIndex = (index: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    const lanesMap = arrToMapByKeys(lanes ?? [], 'id');
    return LANE_LETTER_NAME_LIST.map((laneLetter) => lanesMap[laneLetter]?.cellTypes?.[index] ?? null);
  });

export const selectCagingSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.cagingSettings;

export const selectLanesCagingSettings = createSelector(
  [selectCagingSettings],
  (cagingSettings) => cagingSettings?.perLane?.filter((el) => isDefined(el)) as LaneCagingSettings[] | undefined
);

export const selectLaneCagingSettings = (laneId: string) =>
  createSelector(
    [selectLanesCagingSettings],
    (lanesCagingSettings) => lanesCagingSettings?.find((el) => el.laneId === laneId) ?? null
  );

export const selectLaneCagingSettingsMagnification = (laneId: string) =>
  createSelector([selectLaneCagingSettings(laneId)], (lane) => lane?.magnification ?? null);

export const selectGlobalCagingSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.cagingSettings?.global;

export const selectAllSelectedCellTypes = createSelector([selectCurrentLanes], (lanes) => {
  const listOfCellTypes: TRunDesignCellType[] = [];
  lanes?.forEach((lane) => {
    lane.cellTypes?.forEach((cellType) => {
      if (!cellType) {
        return;
      }
      listOfCellTypes.push(cellType);
    });
  });

  return listOfCellTypes;
});

export const selectGlobalOpticalSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.opticsSettings?.global;

export const selectLanesOpticalSettings = createSelector([], () => [] as Array<OpticsSettingsItem>);

export const selectIsGlobalSettingsView = (state: RootState) => state.experimentRunDesign.addData.isGlobalSettingsView;

export const selectIsAutoGenerateSampleNames = (state: RootState) =>
  state.experimentRunDesign.addData.isAutoGenerateSampleNames;

export const isSomeSamplesWithoutName = createSelector([selectCurrentLanes], (laneList) => {
  if (!laneList?.length) return false;
  return laneList.some((lane) => !lane.sample?.trim());
});

export const selectIsSampleNamesGenerated = (state: RootState) =>
  state.experimentRunDesign.addData.isSampleNamesGenerated;

export const selectFlowcellType = (state: RootState) => state.experimentRunDesign.editFields.current.flowcellType;

export const selectCurrentComponentId = (state: RootState) => state.experimentRunDesign.addData.currentComponentId;

export const selectCurrentComponentInfo = (state: RootState) =>
  state.experimentRunDesign.editFields.current?.schema?.components?.find(
    (component) => component.id === state.experimentRunDesign.addData.currentComponentId
  );

export const selectIsSomeUnsaved = (state: RootState) => state.experimentRunDesign.addData.isEditFieldsChanged;

export const selectLaneCagingSettingsErrors =
  (laneId: string) =>
  (state: RootState): Nullable<Record<string, string>> =>
    state.experimentRunDesign.cagingStoreData.laneErrors[laneId];

export const selectGlobalCagingSettingsErrors = (state: RootState) =>
  state.experimentRunDesign.cagingStoreData.globalErrors;

export const selectISomeSubtractiveCCE = createSelector(
  [selectLanesCagingSettings],
  (lanesCagingSettings) => lanesCagingSettings?.some((el) => el.cceType === CCEType.SUBTRACTIVE_CCE) ?? false
);
