import { FC } from 'react';

import CytokineSecretionHeader from './CytokineSecretionHeader';

const CytokineSecretionComponent: FC = () => (
  <>
    <CytokineSecretionHeader />
    <b>WORK IN PROGRESS</b>
  </>
);

export default CytokineSecretionComponent;
