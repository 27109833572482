import { FC, MutableRefObject, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import isEqual from 'lodash.isequal';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';

import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import styles from './GlobalSettings.module.scss';
import globalSettingsStyles from '../CageSettingsInfoModal/CageSettingsInfoModal.module.scss';

import { DEFAULT_GLOBAL_SETTINGS } from '../../constants';
import GlobalSettingTables from './table';

const cn = classnames.bind({ ...globalSettingsStyles, ...styles });

type TGlobalSettings = {
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  openTimelineSettingsModal: () => void;
};

const GlobalSettings: FC<TGlobalSettings> = ({ cardRef, openTimelineSettingsModal }) => {
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const { newGlobalSettings, setNewGlobalSettings, resetLaneSettingsToGlobal } = useCagingSettingsContext();

  const isSomeGlobalSettingsChanged = useMemo(() => {
    if (!globalCagingSettings) return false;

    return !isEqual(
      {
        __typename: 'CagingSettingsItem',
        ...DEFAULT_GLOBAL_SETTINGS,
      },
      globalCagingSettings
    );
  }, [globalCagingSettings]);

  const resetGlobalSettingsToDefault = useCallback(() => {
    setNewGlobalSettings((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        ...DEFAULT_GLOBAL_SETTINGS,
      };
    });

    resetLaneSettingsToGlobal({
      __typename: 'CagingSettingsItem',
      ...DEFAULT_GLOBAL_SETTINGS,
    });
  }, [globalCagingSettings, newGlobalSettings]);

  return (
    <div className={cn('global-settings')} ref={cardRef}>
      <div className={cn('global-settings__header')}>
        <span className={cn('global-settings__header-title')}>Review and edit global CCE settings as needed</span>
        <div className={cn('global-settings__header-buttons')}>
          <button
            type="button"
            onClick={openTimelineSettingsModal}
            className={cn('global-settings__button', 'global-settings__button_timeline')}
          >
            <icons.RunDesignClockIcon className={cn('global-settings__button-icon')} height={30} width={30} />{' '}
          </button>
          {isSomeGlobalSettingsChanged && isEditMode && (
            <button
              type="button"
              onClick={resetGlobalSettingsToDefault}
              className={cn('global-settings__button', 'global-settings__button_reset')}
            >
              <icons.ReloadIcon className={cn('modal__button-icon')} />
              <span className={cn('global-settings__button-substring')}>Reset to default</span>
            </button>
          )}
        </div>
      </div>
      <GlobalSettingTables />
    </div>
  );
};

export default GlobalSettings;
