import { ReactNode } from 'react';

import icons from '@/components/common/icons';

import { getEnum, TEnumValue } from '../enum';

// TODO: NEWFLOW need to use NewFlow variables logic of steps in new flow and remove postfix NewFlow
const designStepVariants = [
  'details',
  'timeline',
  'sample',
  'reagents',
  'incubation',
  'cagingSettings',
  'opticalSettings',
] as const;

const designStepVariantsNewFlow = ['runInfo', 'flowCellSetup', 'designWorkflow', 'runDesign'] as const;

export const EDesignStep = getEnum(designStepVariants);
export const EDesignStepNewFlow = getEnum(designStepVariantsNewFlow);

export type TDesignStep = TEnumValue<typeof EDesignStep>;
export type TDesignStepNewFlow = TEnumValue<typeof EDesignStepNewFlow>;

export const urlPostfixListNewFlow: Record<TDesignStepNewFlow, string> = {
  [EDesignStepNewFlow.runInfo]: '',
  [EDesignStepNewFlow.flowCellSetup]: 'flowCellSetup',
  [EDesignStepNewFlow.designWorkflow]: 'designWorkflow',
  [EDesignStepNewFlow.runDesign]: 'runDesign',
};

// TODO: NEWFLOW need to remove logic of steps in new flow
export const urlPostfixList: Record<TDesignStep, string> = {
  [EDesignStep.details]: '',
  [EDesignStep.timeline]: 'timeline',
  [EDesignStep.sample]: 'sample',
  [EDesignStep.reagents]: 'reagents',
  [EDesignStep.incubation]: 'incubation',
  [EDesignStep.cagingSettings]: 'cagingSettings',
  [EDesignStep.opticalSettings]: 'opticalSettings',
};

// TODO: NEWFLOW need to remove logic of steps in new flow
export const runDesignNavigation = [
  { step: EDesignStep.timeline, label: 'Run timeline' },
  { step: EDesignStep.sample, label: 'Sample information' },
  { step: EDesignStep.reagents, label: 'Assays: Reagents' },
  { step: EDesignStep.incubation, label: 'Incubation treatment' },
  { step: EDesignStep.cagingSettings, label: 'CCE settings' },
  { step: EDesignStep.opticalSettings, label: 'Optical settings' },
];

// TODO: NEWFLOW need to remove logic of steps in new flow
export const fullRunDesignNavigationSteps = [{ step: EDesignStep.details, label: 'Details' }, ...runDesignNavigation];

// TODO: NEWFLOW need to remove logic of steps in new flow
export const runDesignStepOrder: TDesignStep[] = [
  EDesignStep.details,
  EDesignStep.timeline,
  EDesignStep.sample,
  EDesignStep.reagents,
  EDesignStep.incubation,
  EDesignStep.cagingSettings,
  EDesignStep.opticalSettings,
];

export const lastRunDesignStepOrder = runDesignStepOrder.length - 1;

export const iconList = {
  iconCaging: <icons.CagingIcon />,
  iconScanning: <icons.ScanningIcon />,
  iconIncubationOffDevice: <icons.IncubationOffDeviceIcon />,
  iconReceptor: <icons.ReceptorIcon />,
  iconSecretion: <icons.SecretionIcon />,
  iconRetention: <icons.RetentionIcon />,
  iconIncubation: <icons.IncubationIcon />,
  iconKilling: <icons.TimelineCellKillingIcon />,
  iconScanningStacked: <icons.ScanningStackedIcon />,
  iconWash: <icons.WashIcon />,
  iconMRna: <icons.MRnaIcon />,
  cellKillingStain: <icons.CellKillingStainIcon />,
  iconAddAntibody: <icons.AddAntibodyIcon />,
  iconAddMedia: <icons.AddMediaIcon />,
  iconCellLysis: <icons.CellLysisIcon />,
  iconElution: <icons.ElutionIcon />,
  iconSegment: <icons.SegmentIcon />,
};

/* all non-default exports exist to clean code and remove hardcode id from data file */
export type TIconId = keyof typeof iconList;

type TIconIdList = { [P in TIconId]: P };
export const iconIdList = Object.keys(iconList).reduce<TIconIdList>(
  (acc, iconId) => ({
    ...acc,
    [iconId]: iconId,
  }),
  {} as TIconIdList
);

// Matches assay icons with components by component type
// Other components are not assays
export const assayIconList: Record<string, ReactNode> = {
  SurfaceReceptor: <icons.ReceptorIcon />,
  SurfaceReceptorCytokineSecretion: <icons.ReceptorIcon />,
  CytokineSecretion: <icons.SecretionIcon />,
  CellKilling: <icons.CellKillingIcon />,
  mRNA: <icons.MRnaIcon />,
};
