import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { getComponentListWithNewStepNewFlow } from '@/helpers/runDesigns/components/updateOnAddAndRemove';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TCreatableComponentTypeNewFlow,
} from '@/store/slices/experimentRunDesign';

import SelectComponent from '@/components/runDesign/SelectComponent';

import styles from './AddComponent.module.scss';

const cn = classnames.bind(styles);

const AddComponent: FC = () => {
  const appDispatch = useAppDispatch();
  const componentList = useSelector(experimentRunDesignSelectors.selectComponentList);
  const currentComponentId = useSelector(experimentRunDesignSelectors.selectCurrentComponentId);

  const onSelectComponentType = useCallback(
    (type: TCreatableComponentTypeNewFlow) => {
      const newComponentList = getComponentListWithNewStepNewFlow(
        type,
        structuredClone(componentList ?? []),
        currentComponentId
      );
      appDispatch(experimentRunDesignActions.updateComponents(newComponentList));
    },
    [currentComponentId, componentList]
  );

  return (
    <div className={cn('add-component')}>
      <SelectComponent
        onSelect={onSelectComponentType}
        className={cn('add-component__select')}
        stepListWrapClassName={cn('add-component__step-list-wrap')}
      />
    </div>
  );
};

export default memo(AddComponent);
