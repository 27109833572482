import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { LANE_LETTER_NAME_LIST } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import styles from './LanesTable.module.scss';
import Label from './Label';
import Name from './Name';
import CellTypes from './CellTypes';

const cn = classnames.bind(styles);

type TLanesTable = {
  className?: string;
  rowClassName?: string;
  isEditAllowed?: boolean;
  hideSampleName?: boolean;
  hideCellTypes?: boolean;
  isLocked?: boolean;
  isActivationAllowed?: boolean;
};

// TODO: NEWFLOW remove in new flow
const LanesTable: FC<TLanesTable> = ({
  className,
  hideCellTypes,
  hideSampleName,
  rowClassName,
  isLocked,
  isEditAllowed,
  isActivationAllowed,
}) => {
  const appDispatch = useAppDispatch();
  const lanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);

  const existCellTypeSize = useMemo(
    () => Math.max(...(lanes ?? []).map((lane) => lane?.cellTypes?.length ?? 0)),
    [lanes]
  );

  const onChangeLaneActive = useCallback((laneLetter: string, isActive: boolean) => {
    if (isActive) {
      appDispatch(experimentRunDesignActions.removeLane(laneLetter));
      return;
    }
    appDispatch(experimentRunDesignActions.addNewLane(laneLetter));
  }, []);

  const onChangeLaneName = useCallback((laneLetter: string, sampleName: string) => {
    appDispatch(experimentRunDesignActions.updateLane({ laneId: laneLetter, updatedData: { sample: sampleName } }));
  }, []);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('lanes-table', className)}
      header={
        <RunDesignTable.Row className={cn('lanes-table__row', 'lanes-table__header-row')}>
          <RunDesignTable.Column>
            <span className={cn('lanes-table__cell', 'lanes-table__header-item')}>Lane</span>
          </RunDesignTable.Column>
          {!hideSampleName && (
            <RunDesignTable.Column>
              <span className={cn('lanes-table__header-item')}>Sample name</span>
            </RunDesignTable.Column>
          )}
          {!hideCellTypes && (
            <RunDesignTable.Column>
              <span className={cn('lanes-table__header-item')}>Cell types</span>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {LANE_LETTER_NAME_LIST.map((laneLetter) => {
        const lane = lanes?.find((el) => laneLetter === el.id);
        const isSampleNameHidden = !lane && !isActivationAllowed;
        const existCellTypes = Array.from(
          { length: existCellTypeSize },
          (_, index) => !!lane?.cellTypes?.[index]?.cellIndex?.name
        );

        return (
          <RunDesignTable.Row key={laneLetter} className={cn('lanes-table__row', rowClassName)}>
            <RunDesignTable.Column tooltipMessage={isSampleNameHidden ? 'Activate lane on Step 2' : ''}>
              <Label
                className={cn('lanes-table__cell', {
                  'lanes-table__label_disabled': isSampleNameHidden,
                })}
                laneLetter={laneLetter}
                isEditAllowed={isEditAllowed}
                isLocked={isLocked}
                isActivationAllowed={isActivationAllowed}
                isActive={!!lane}
                onChangeActive={onChangeLaneActive}
              />
            </RunDesignTable.Column>
            {!hideSampleName && !isSampleNameHidden && (
              <RunDesignTable.Column>
                <Name
                  className={cn('lanes-table__cell')}
                  laneLetter={laneLetter}
                  isEditAllowed={isEditAllowed}
                  isLocked={isLocked}
                  isActivationAllowed={isActivationAllowed}
                  sampleName={lane?.sample ?? ''}
                  onChangeSampleName={onChangeLaneName}
                  isActive={!!lane}
                  onChangeActive={onChangeLaneActive}
                />
              </RunDesignTable.Column>
            )}

            {!hideCellTypes && (
              <RunDesignTable.Column>
                <CellTypes className={cn('lanes-table__cell')} existCellTypes={existCellTypes} />
              </RunDesignTable.Column>
            )}
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default LanesTable;
