import { FC, memo } from 'react';

import FooterControls from '@/components/runDesign/FooterControls';
import FooterTabs from '@/pages/experiment-run-design-new-flow/components/FooterTabs';

type TRunInformationFooterProps = {
  className?: string;
  onClickBack: () => void;
  onClickSaveAndExit: () => void;
  onClickContinue: () => void;
};

const RunInformationFooter: FC<TRunInformationFooterProps> = ({
  className,
  onClickBack,
  onClickContinue,
  onClickSaveAndExit,
}) => (
  <FooterControls.Wrapper className={className}>
    <FooterControls.Group>
      <FooterControls.Back onClick={onClickBack} />
      <FooterControls.SaveAndExit onClick={onClickSaveAndExit} />
    </FooterControls.Group>
    <FooterTabs />
    <FooterControls.Continue onClick={onClickContinue} />
  </FooterControls.Wrapper>
);

export default memo(RunInformationFooter);
