import { FC, memo } from 'react';

import FooterControls from '@/components/runDesign/FooterControls';
import FooterTabs from '@/pages/experiment-run-design-new-flow/components/FooterTabs';

type TFlowCellSetupFooterProps = {
  className?: string;
};

const FlowCellSetupFooter: FC<TFlowCellSetupFooterProps> = ({ className }) => (
  <FooterControls.Wrapper className={className}>
    <FooterControls.Group>
      <FooterControls.Back onClick={() => null} />
      <FooterControls.SaveAndExit onClick={() => null} />
    </FooterControls.Group>
    <FooterTabs />
    <FooterControls.Continue onClick={() => null} />
  </FooterControls.Wrapper>
);

export default memo(FlowCellSetupFooter);
