import { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import TextInput from '@/components/common/TextInput';
import icons from '@/components/common/icons';

import styles from './Name.module.scss';

const cn = classnames.bind(styles);

type TName = {
  className?: string;
  laneLetter: string;
  sampleName: string;
  isEditAllowed?: boolean;
  isActivationAllowed?: boolean;
  isLocked?: boolean;
  isActive?: boolean;
  onChangeActive?: (laneLetter: string, isActive: boolean) => void;
  onChangeSampleName?: (laneLetter: string, sampleName: string) => void;
};

const Name: FC<TName> = ({
  laneLetter,
  className,
  isEditAllowed,
  isLocked,
  isActivationAllowed,
  isActive = false,
  onChangeActive,
  onChangeSampleName,
  sampleName,
}) => {
  const isNotClickable = useMemo(
    () => isLocked || !isEditAllowed || (!isActive && !isActivationAllowed),
    [isLocked, isEditAllowed, isActive, isActivationAllowed]
  );

  const handleChangeLaneName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isEditAllowed || !isActive) {
        return;
      }
      onChangeSampleName?.(laneLetter, event.target.value);
    },
    [laneLetter, onChangeSampleName, isEditAllowed, isActive]
  );

  const handleActive = useCallback(() => {
    if (isActive || !isActivationAllowed) {
      return;
    }
    onChangeActive?.(laneLetter, isActive);
  }, [isActive, laneLetter, isActivationAllowed, isEditAllowed, onChangeActive]);

  const placeHolderString = useMemo<string>(() => {
    if (isLocked || !isEditAllowed || (!isActive && !isActivationAllowed)) {
      return '';
    }

    return 'Enter sample name...';
  }, [isLocked, isEditAllowed, isActive, isActivationAllowed]);

  return (
    <TextInput
      type="text"
      value={sampleName}
      onChange={handleChangeLaneName}
      onFocus={handleActive}
      disabled={isNotClickable}
      className={cn(className)}
      placeholder={placeHolderString}
      inputClassName={cn('input', { input_disabled: !isActive, input_locked: !isActive && !isActivationAllowed })}
    >
      {isLocked && (
        <div className={cn('locked-icon')}>
          <icons.LockIcon />
        </div>
      )}
    </TextInput>
  );
};

export default memo(Name);
