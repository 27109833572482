import { CSSProperties, FC, MutableRefObject, Fragment } from 'react';
import classNames from 'classnames/bind';

import { addTooltip } from '@/helpers';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import styles from './BaseSettingSections.module.scss';
import { THeaderSectionsData } from '../../types';

const cn = classNames.bind(styles);

type TBaseSettingSections = {
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  onTitleClickFactory?: (settingName?: string) => () => void;
  sectionsData: {
    key: string;
    sections: THeaderSectionsData[];
  }[];
  // TODO: NEWFLOW after approve new flow, remove isHideSectionMap prop
  isHideSectionMap?: Record<string, boolean>;
};

const BaseSettingSections: FC<TBaseSettingSections> = ({
  sectionsData,
  cardRef,
  onTitleClickFactory,
  isHideSectionMap = {},
}) => (
  <>
    {sectionsData.map(({ key, sections }) => (
      <Fragment key={key}>
        {sections.map(({ key: sectionKey, title, withWiki, valueType = '', waves }, _index) => {
          // TODO: NEWFLOW after approve new flow, remove isHideSectionMap prop
          if (isHideSectionMap[sectionKey]) {
            return null;
          }
          return (
            <RunDesignTable.Column
              key={sectionKey}
              style={
                {
                  '--divider-height': `${Math.ceil(cardRef?.current?.getBoundingClientRect()?.height ?? 0)}px`,
                } as CSSProperties
              }
              className={cn('column', {
                column_fixed: sectionKey === 'magnification',
              })}
            >
              {withWiki && !!onTitleClickFactory ? (
                <button
                  key={sectionKey}
                  type="button"
                  onClick={onTitleClickFactory(sectionKey)}
                  className={cn('column__label', {
                    column__label_advanced: ['fullAdvanced', 'resetToDefault'].includes(key),
                    column__label_link: withWiki,
                    column__label_wide: sectionKey === 'cageWallToOtherObjsMinDistance',
                  })}
                  {...addTooltip(`${title}${valueType ? `(${valueType})` : ''}`)}
                >
                  {title}
                  {valueType && <span className={cn('column__label_no-transform')}> {`(${valueType})`}</span>}
                </button>
              ) : (
                <div
                  className={cn('column__label', {
                    column__label_advanced: ['fullAdvanced', 'resetToDefault'].includes(key),
                  })}
                  key={sectionKey}
                >
                  {waves ? (
                    <>
                      <span>{title}</span>
                      <div className={cn('column__waves-label')}>
                        <span>EX</span>
                        <span>EM</span>
                      </div>
                    </>
                  ) : (
                    title
                  )}
                </div>
              )}
            </RunDesignTable.Column>
          );
        })}
      </Fragment>
    ))}
  </>
);

export default BaseSettingSections;
