import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';

import Metadata from '@/components/common/Metadata';

import styles from './TitleHeader.module.scss';

const cn = classnames.bind(styles);

type TitleHeaderProps = {
  title: string;
  userName: string;
  runDesignDate: string;
};

const TitleHeader: FC<TitleHeaderProps> = ({ title, runDesignDate, userName }) => {
  const currentDate = useMemo(() => {
    if (!runDesignDate) {
      return '';
    }

    return new Date(runDesignDate)
      .toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
      .replaceAll('/', '-');
  }, [runDesignDate]);

  return (
    <div className={cn('title-header')}>
      <div className={cn('title-header__title')}>{title}</div>
      <Metadata className={cn('title-header__metadata')}>
        <Metadata.Item
          titleClassName={cn('title-header__metadata-item_title')}
          className={cn('title-header__metadata-item')}
          title="Created by"
          description={userName}
        />
        {currentDate && (
          <>
            <div className={cn('title-header__metadata-divider')} />

            <Metadata.Item
              titleClassName={cn('title-header__metadata-item_title')}
              className={cn('title-header__metadata-item')}
              title="Date"
              description={currentDate}
            />
          </>
        )}
      </Metadata>
    </div>
  );
};

export default memo(TitleHeader);
