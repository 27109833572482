import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import styles from './Label.module.scss';

const cn = classnames.bind(styles);

type TLabel = {
  laneLetter: string;
  isEditAllowed?: boolean;
  isLocked?: boolean;
  isActive?: boolean;
  className?: string;
  isActivationAllowed?: boolean;
  onChangeActive?: (laneLetter: string, isActive: boolean) => void;
};

const Label: FC<TLabel> = ({
  laneLetter,
  isEditAllowed,
  isLocked,
  className,
  isActivationAllowed,
  isActive = false,
  onChangeActive,
}) => {
  const isNotClickable = useMemo(
    () => isLocked || !isEditAllowed || (!isActive && !isActivationAllowed),
    [isLocked, isEditAllowed, isActive, isActivationAllowed]
  );

  const handleActive = useCallback(() => {
    if (!isEditAllowed || !isActivationAllowed) {
      return;
    }
    onChangeActive?.(laneLetter, isActive);
  }, [isActive, laneLetter, isActivationAllowed, isEditAllowed]);

  if (isNotClickable) {
    return (
      <div
        className={cn('label', 'label_non-editable', className, {
          'label_non-existent': !isActive,
          'label_non-editable_recolored': !isActive,
        })}
      >
        {laneLetter}
      </div>
    );
  }

  return (
    <button onClick={handleActive}>
      <div className={cn('label', className, { 'label_non-existent': !isActive })}>{laneLetter}</div>
    </button>
  );
};

export default Label;
