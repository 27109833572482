import { gql } from '@apollo/client';
import { runDesignApolloClient } from '@/services/runDesignApolloClient';
import { runDesignStepsQuery, RunDesignStepsQuery, RunDesignStepsQueryVariables } from '@/graphql/runDesign';

import { TRunDesignGraphqlEndpointBuilder } from '../types';

export const runDesignStepsEndpoints = (build: TRunDesignGraphqlEndpointBuilder) => ({
  fetchRunDesignSteps: build.query<RunDesignStepsQuery['runDesign'], RunDesignStepsQueryVariables>({
    queryFn: async (args) => {
      try {
        const response = await runDesignApolloClient.query<RunDesignStepsQuery>({
          query: gql(runDesignStepsQuery),
          variables: args,
        });
        return {
          data: response.data.runDesign,
        };
      } catch (error: any) {
        console.error(error?.message);
        return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
      }
    },
  }),
});
