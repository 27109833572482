import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { isKeyOf } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import {
  experimentRunDesignSelectors,
  experimentRunDesignActions,
  TCagingSettingNumberField,
} from '@/store/slices/experimentRunDesign';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import {
  getValidationInfo,
  prepareValidationConfigForAdvancedSettings,
  validateCagingSettings,
} from '../EditSettings/helpers';
import SettingInput from '../EditSettings/components/SettingsInput/SettingsInput';

import styles from './GlobalSettings.module.scss';
import { ADVANCED_SETTINGS } from '../../constants';
import { updateLaneSettingsWhenGlobalChanged } from '../../helpers';

const cn = classnames.bind(styles);

const GlobalSettingTables = () => {
  const appDispatch = useAppDispatch();
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const { newGlobalSettings, newLaneCagingSettings, setNewGlobalSettings, setNewLaneCagingSettings } =
    useCagingSettingsContext();

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (!newGlobalSettings) return;
    const { errors } = validateCagingSettings(undefined, newGlobalSettings);
    setValidationErrors(errors);
  }, [newGlobalSettings]);

  const cageSettingsInputValidationConfig = useMemo(
    () => prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings),
    [globalCagingSettings]
  );

  const editableSettingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const settingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && !setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      setNewGlobalSettings((prev) => {
        if (!prev) return null;
        const newSettings = structuredClone(prev);

        newSettings[field] = value;
        validateCagingSettings(undefined, newSettings);
        return newSettings;
      });

      setNewLaneCagingSettings((prev) => updateLaneSettingsWhenGlobalChanged(prev, field, value));
    },
    []
  );

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  return (
    <div
      role="presentation"
      onClick={handleCardContentClick}
      className={cn('container', { container_clickable: !isEditMode })}
    >
      <RunDesignTable
        tableData={[]}
        className={cn('table')}
        headerClassName={cn('table__header')}
        header={
          <RunDesignTable.Row>
            <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
            <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
            <RunDesignTable.Column />
          </RunDesignTable.Row>
        }
      >
        {editableSettingsList.map(({ key, title, icon, customdata = '' }) => {
          const isMixed =
            newGlobalSettings?.[key] === globalCagingSettings?.[key] &&
            newLaneCagingSettings?.some((settings) => {
              if (!newGlobalSettings?.[key] || !settings) return false;
              return settings?.overrideSettings?.[key] !== newGlobalSettings?.[key];
            });
          return (
            <RunDesignTable.Row key={key} className={cn('table__row')}>
              <RunDesignTable.Column className={cn('table__column')}>
                <div className={cn('table__column-content')}>
                  {title} {customdata}
                </div>
                <div className={cn('table__column-content_light')}>
                  {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                </div>
              </RunDesignTable.Column>

              <RunDesignTable.Column className={cn('table__column')}>
                <SettingInput
                  onChangeNumberField={handleChangeNumberField}
                  isMixed={isMixed}
                  value={newGlobalSettings?.[key] ?? 0}
                  field={key}
                  errors={validationErrors}
                  settings={newGlobalSettings}
                  defaultValue={globalCagingSettings?.[key] ?? undefined}
                  isEditMode={isEditMode}
                  cageSettingsInputValidationConfig={cageSettingsInputValidationConfig}
                />
              </RunDesignTable.Column>
              <RunDesignTable.Column className={cn('table__column', 'table__column_icon')}>
                {icon}
              </RunDesignTable.Column>
            </RunDesignTable.Row>
          );
        })}
      </RunDesignTable>
      <div>
        <RunDesignTable
          tableData={[]}
          className={cn('table')}
          headerClassName={cn('table__header')}
          header={
            <RunDesignTable.Row>
              <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
              <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
              <RunDesignTable.Column />
            </RunDesignTable.Row>
          }
        >
          {settingsList.map(({ key, title, icon, customdata = '' }, index) => (
            <RunDesignTable.Row key={key} className={cn('table__row')}>
              <RunDesignTable.Column
                className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
              >
                <div className={cn('table__column-content')}>
                  {title} {customdata} *
                </div>
                <div className={cn('table__column-content_light')}>
                  {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                </div>
              </RunDesignTable.Column>
              <RunDesignTable.Column
                className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
              >
                <span>{newGlobalSettings?.[key]}</span>
              </RunDesignTable.Column>
              <RunDesignTable.Column
                className={cn(
                  'table__column',
                  { table__column_bordered: index === settingsList.length - 1 },
                  'table__column_icon'
                )}
              >
                {icon}
              </RunDesignTable.Column>
            </RunDesignTable.Row>
          ))}
        </RunDesignTable>
        <div className={cn('info')}>
          <span> *</span>
          <span className={cn('info__text')}>Default values for settings are displayed but cannot be edited.</span>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettingTables;
