import { formatDate, MDASH } from '@/helpers';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { fullRunDesignNavigationSteps, urlPostfixList } from '@/helpers/runDesigns/constants';

import { RunDesignSchema } from '@/graphql/API';
import { TemplateFieldsFragment, RunDesign } from '@/graphql/runDesign';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

export const getDraftHref = (runDesignId: string, currentStep: number) => {
  const stepIndex = currentStep >= 1 ? currentStep : 0;
  const { step } = fullRunDesignNavigationSteps[stepIndex] ?? {};

  if (step) {
    return `/run-design/${ERunDesignSourceType.draft}/${runDesignId}/${urlPostfixList[step]}`;
  }
  return `/run-design/${ERunDesignSourceType.draft}/${runDesignId}`;
};

export const getRunDesignAssayList = (schema: RunDesignSchema) => {
  const assaysMap: Record<string, TAssay> = {
    SurfaceReceptor: { icon: '', id: '1', name: 'Surface receptor', properties: { active: true } },
    SurfaceReceptorCytokineSecretion: {
      icon: '',
      id: '1',
      name: 'Surface receptor Cytokine secretion',
      properties: { active: true },
    },
    CytokineSecretion: { icon: '', id: '2', name: 'Cytokine secretion', properties: { active: true } },
    CellKilling: { icon: '', id: '3', name: 'Cell killing', properties: { active: true } },
    mRNA: { icon: '', id: '4', name: 'mRNA expression', properties: { active: true } },
  };
  const componentList = schema.components ?? [];
  return componentList.reduce((list: TAssay[], component: TRunDesignComponent) => {
    const assay = assaysMap[component.type];
    if (assay) {
      list.push(assay);
    }
    return list;
  }, [] as TAssay[]);
};

export const getRunDesignMeta = (edgeNode: RunDesign, userList: TUser[]) => [
  {
    key: 'Author',
    value: userList.find((user) => user.username === edgeNode.investigatorId)?.displayName ?? MDASH,
  },
  { key: 'Created', value: formatDate(edgeNode.createdAt) ?? MDASH },
];

export const getTemplateMeta = (template: TemplateFieldsFragment) => [
  {
    key: 'Author',
    // TODO: NEWFLOW temp commented for waiting backend add author/authorId to template
    // value: userList.find((user) => user.username === template.authorId)?.displayName ?? MDASH,
    value: MDASH,
  },
  { key: 'Created', value: formatDate(template.createdAt) ?? MDASH },
];
