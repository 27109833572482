import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames/bind';

import { EDesignStep } from '@/helpers/runDesigns/constants';

import { Title } from '@/components/runDesign/customComponentWrapper';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';

import LanesBlock from '@/components/runDesign/LanesBlock';

import styles from './RunDesignBlockWithLanes.module.scss';

const cn = classNames.bind(styles);

type TRunDesignBlockProps = {
  title: ReactNode;
  children?: ReactNode;
  hideLanesBlock?: boolean;
};

// TODO: NEWFLOW remove this after approve new flow
const RunDesignBlockWithLanes: FC<TRunDesignBlockProps> = ({ title, children, hideLanesBlock }) => {
  const { currentStep } = useRunDesignLocation();

  const isAllowChangeLines = useMemo(() => ([EDesignStep.sample] as string[]).includes(currentStep), [currentStep]);
  const isReagentAssayStep = useMemo(() => currentStep === EDesignStep.reagents, [currentStep]);

  return (
    <div className={cn('run-design-block')}>
      <Title>{title}</Title>
      {!hideLanesBlock && (
        <LanesBlock
          isEditAllowed
          isActivationAllowed={isAllowChangeLines}
          hideAnnotationSwitch={!isReagentAssayStep}
          hideCellTypes={!isAllowChangeLines}
          hideSampleNameGenerateSwitch={!isAllowChangeLines}
        />
      )}
      {children}
    </div>
  );
};

export default RunDesignBlockWithLanes;
