import { FC } from 'react';

import icons from '@/components/common/icons';

import PanelWithFolding from '@/components/runDesign/PanelWithFolding';
import { TRenderSideComponentProps } from '@/components/runDesign/PanelWithFolding/types';

const FovSideBlockHeader: FC<TRenderSideComponentProps> = ({ isOpen, toggleOpen }) => (
  <PanelWithFolding.Title Icon={icons.FovIcon} title="FOVs config" onClick={toggleOpen} isShowTitle={isOpen} />
);

export default FovSideBlockHeader;
