import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import SwitchInput from '@/components/common/SwitchInput';

import styles from './SwitchView.module.scss';

const cn = classnames.bind(styles);

type SwitchView = {
  className?: string;
};

const SwitchView: FC<SwitchView> = ({ className }) => {
  const appDispatch = useAppDispatch();
  const isGlobalSettingsView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);

  const onChange = useCallback(() => {
    appDispatch(experimentRunDesignActions.toggleGlobalSettingsView());
  }, []);

  return (
    <SwitchInput
      checked={!!isGlobalSettingsView}
      onChange={onChange}
      onLabel="All lanes"
      offLabel="By Lane"
      className={cn('switch', className)}
    />
  );
};

export default SwitchView;
