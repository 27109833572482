import {
  FC,
  FocusEventHandler,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import ReactSelect, {
  components,
  OptionProps,
  MenuListProps,
  ClassNamesConfig,
  MultiValue,
  SingleValue,
  InputProps,
  InputActionMeta,
  GroupBase,
  GroupHeadingProps,
  MenuPosition,
  MenuPlacement,
} from 'react-select';
import type { FormatOptionLabelMeta } from 'react-select/base';
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'myCustomProp' to it.

import CheckboxInput from '@/components/common/CheckboxInput';
import { themeOptions } from '@/types/theme';
import DefaultOption from '@/components/common/Select/DefaultOption';
import styles from './Select.module.scss';
import icons from '../icons';
import { getOption, isBasicOption, isCompoundOption, isOption } from './helpers';

declare module 'react-select/base' {
  // @ts-ignore
  export interface Props {
    showSelectedOption?: boolean;
    dataTestId?: string;
    menuListWrapperClassName?: string;
  }
}

const cn = classnames.bind(styles);

export type TSelectProps = {
  id?: string;
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  className?: string;
  multiple?: boolean;
  options?: TOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  value?: Nullable<number | string | number[] | string[] | TOption[] | TOption>;
  defaultValue?: number | string | number[] | string[];
  placeholder?: string;
  dropDownClassName?: string;
  separatorClassName?: string;
  menuClassName?: string;
  menuPortalClassName?: string;
  menuListClassName?: string;
  menuPosition?: MenuPosition;
  optionClassName?: string;
  selectedOptionClassName?: string;
  clearClassName?: string;
  singleValueClassName?: string;
  placeholderClassName?: string;
  controlClassName?: string;
  containerClassName?: string;
  menuListWrapperClassName?: string;
  valueContainerClassName?: string;
  showSelectedOption?: boolean;
  isMultiLine?: boolean;
  disabled?: boolean;
  menuIsOpen?: boolean;
  menuPlacement?: MenuPlacement;
  theme?: themeOptions;
  isEditable?: boolean;
  isClearable?: boolean;
  inputValue?: string;
  setInputValue?: (newValue: string) => void;
  isLoading?: boolean;
  isPortalable?: boolean;
  noOptionsMessage?: (obj: { inputValue: string }) => ReactNode;
  formatOptionLabel?: (data: TOption, formatOptionLabelMeta: FormatOptionLabelMeta<TOption>) => ReactNode;
  isRightAlign?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  customComponents?: Record<string, (props: any) => Nullable<ReactElement>>;
  errorMessage?: string;
};

const MenuList: FC<MenuListProps<TOption>> = ({ ...props }) => {
  const { children, selectProps, isMulti } = props;

  const { value, placeholder, options } = selectProps;

  let label;
  if (!value) {
    label = placeholder;
  } else if (isMulti && Array.isArray(value)) {
    if (!value.length) {
      label = placeholder;
    } else if (value.length === 1) {
      label = value[0].label;
    } else {
      label = `Multiple (${value.length})`;
    }
  }

  if (selectProps.showSelectedOption && !isMulti && Array.isArray(value)) {
    // @ts-ignore
    const labelFromOptions = options.find((el) => el?.value === value[0]?.value);
    label = labelFromOptions?.label ?? value[0]?.value ?? '';
  }

  return (
    <div data-test-id={`${selectProps.dataTestId}__menu-list`} className={cn(selectProps.menuListWrapperClassName)}>
      <components.MenuList {...props}>
        {isMulti && <div className={cn('select__title')}>{label}</div>}
        {selectProps.showSelectedOption && label && (
          <div className={cn('select__show-title')}>
            {label}
            <icons.ArrowDropdownIcon className={cn('select__dropdown-arrow', 'select__title-arrow')} />
          </div>
        )}
        {children}
      </components.MenuList>
    </div>
  );
};

const GroupHeading = (props: GroupHeadingProps<TOption>) => {
  const {
    selectProps: { inputValue, options },
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const isGroupHeadingOpen = useMemo(() => {
    if (!inputValue?.trim()) return false;
    const secondSectionWithOptions = options.find((option) => isCompoundOption(option)) as TCompoundOption;

    if (!secondSectionWithOptions) return false;

    return secondSectionWithOptions.options.some((option) => {
      const lowerCaseValue = isBasicOption(option) ? String(option.label).toLocaleLowerCase() : '';
      const lowerCaseInputValue = inputValue.toLocaleLowerCase();

      return lowerCaseValue.includes(lowerCaseInputValue);
    });
  }, [inputValue, options]);

  const handleHeaderClick = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsOpen(isGroupHeadingOpen);
  }, [isGroupHeadingOpen]);

  return (
    <div
      className={cn('select__group-heading-wrap', { '_is-open': isOpen })}
      role="presentation"
      onClick={handleHeaderClick}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const Option = (props: OptionProps<TOption>) => {
  const { isMulti, isSelected, selectProps, data } = props;
  let dataTestId = '';
  if (isBasicOption(data)) {
    dataTestId = `${selectProps.dataTestId}__option__${data.value.toString().replace(/ /g, '-')?.toLowerCase()}`;
  }

  return (
    <div data-test-id={dataTestId} className={cn('select__option-wrap')}>
      <components.Option {...props} />
      {isMulti && (
        <CheckboxInput checked={isSelected} onChange={() => null} className={cn('select__checkbox')} theme="light" />
      )}
    </div>
  );
};

const DropdownIndicator = () => <icons.ArrowDropdownIcon className={cn('select__dropdown-arrow')} />;

const Input = (props: InputProps<TOption, false, GroupBase<TOption>>) => (
  <components.Input {...props} isHidden={false} />
);

type TSelectExtensions = {
  DefaultOption: typeof DefaultOption;
};

const Select: FC<TSelectProps> & TSelectExtensions = ({
  className,
  innerRef,
  options = [],
  multiple = false,
  onChange,
  value: importedValue,
  defaultValue: importedDefaultValue,
  placeholder = 'Select...',
  disabled = false,
  containerClassName,
  controlClassName,
  dropDownClassName,
  clearClassName,
  menuClassName,
  menuListClassName,
  menuPortalClassName,
  menuPosition = 'fixed',
  optionClassName,
  selectedOptionClassName,
  placeholderClassName,
  separatorClassName,
  singleValueClassName,
  menuListWrapperClassName,
  valueContainerClassName,
  showSelectedOption = false,
  id = 'select',
  menuIsOpen,
  menuPlacement = 'auto',
  theme = themeOptions.dark,
  isEditable = false,
  inputValue = '',
  isLoading = false,
  setInputValue = () => null,
  isPortalable = true,
  isMultiLine = false,
  noOptionsMessage,
  formatOptionLabel,
  isRightAlign = true,
  onBlur,
  isClearable = false,
  customComponents,
  errorMessage,
  ...otherProps
}) => {
  const [value, setValue] = useState<TOption[]>([]);

  useEffect(() => {
    const newValue: TOption[] = [];
    let definitelyArrayImportedValue;
    if (!Array.isArray(importedValue)) {
      definitelyArrayImportedValue = [importedValue];
    } else {
      definitelyArrayImportedValue = importedValue;
    }

    definitelyArrayImportedValue.forEach((importedOptionOrValue) => {
      let optionValue;
      if (isOption(importedOptionOrValue)) {
        if (isBasicOption(importedOptionOrValue)) {
          optionValue = importedOptionOrValue.value;
        }
      } else {
        optionValue = importedOptionOrValue;
      }

      if (optionValue === undefined || optionValue === null) {
        return;
      }
      const newOptionValue = getOption(options, optionValue);
      if (newOptionValue) {
        newValue.push(newOptionValue);
      }
    });

    setValue(newValue);
  }, [importedValue, options]);

  const defaultValue = useMemo(() => {
    const newValue: TOption[] = [];
    let definitelyArrayImportedDefaultValue: Array<string | number>;

    if (importedDefaultValue === undefined || importedDefaultValue === null) {
      return;
    }

    if (['string', 'number'].includes(typeof importedDefaultValue)) {
      definitelyArrayImportedDefaultValue = [importedDefaultValue] as Array<string | number>;
    } else {
      definitelyArrayImportedDefaultValue = importedDefaultValue as Array<string | number>;
    }

    if (!definitelyArrayImportedDefaultValue) {
      return;
    }

    definitelyArrayImportedDefaultValue.forEach((elemValue) => {
      const newOptionValue = getOption(options, elemValue);
      if (newOptionValue) {
        newValue.push(newOptionValue);
      }
    });

    return newValue;
  }, [importedDefaultValue, options]);

  const text = useMemo(() => {
    let source;

    if (value.length) {
      source = value;
    } else if (defaultValue?.length) {
      source = defaultValue;
    } else {
      return placeholder;
    }
    if (typeof source[0]?.label === 'string') {
      return source.reduce((acc, option) => `${acc} + ${option?.label}`, '').slice(3);
    }
    return (
      <div className={cn('select__placeholder-elements-container')}>
        {source.map((option) => (isBasicOption(option) ? <div key={option.value}>{option.label}</div> : null))}
      </div>
    );
  }, [value, defaultValue, placeholder]);

  const handleChange = useCallback(
    (selectValue: MultiValue<TOption> | SingleValue<TOption>) => {
      let newValue;

      if (Array.isArray(selectValue)) {
        newValue = selectValue.map((option) => option);
      } else {
        newValue = [selectValue];
      }

      setValue(newValue);
      if (multiple) {
        onChange(newValue.map((option) => option.value));
        return;
      }
      onChange(newValue[0].value);
    },
    [onChange]
  );

  const handleAllSelectClick = useCallback(() => {
    if (options.length === value.length) {
      setValue([]);
      onChange([]);
      return;
    }

    setValue(options);
    onChange(
      options.reduce((acc, option) => {
        if (isBasicOption(option)) {
          acc.push(option.value);
        }

        return acc;
      }, [] as Array<string | number>)
    );
  }, [options, value]);

  const styleClasses: ClassNamesConfig<TOption> = useMemo(
    () => ({
      valueContainer: () => cn(valueContainerClassName),
      container: ({ isDisabled }) =>
        cn('select', containerClassName, {
          select_disabled: isDisabled,
          select_light: theme === themeOptions.light,
        }),
      control: () => cn('select__control', controlClassName),
      dropdownIndicator: () => cn('select__dropdown-indicator', dropDownClassName),
      indicatorSeparator: () => cn('select__indicator-separator', separatorClassName),
      menu: () =>
        cn(
          'select__menu',
          {
            select__menu_light: theme === themeOptions.light,
            select__menu_standard: !isEditable,
            'select__menu_right-align': isRightAlign,
          },
          menuClassName
        ),
      menuPortal: () => cn('select__menu-portal', menuPortalClassName),
      menuList: () =>
        cn('select__menu-list', { 'select__menu-list_light': theme === themeOptions.light }, menuListClassName),
      option: ({ isFocused, isSelected, isMulti, isDisabled }) =>
        cn('select__option', optionClassName, {
          select__option_multiple: isMulti,
          select__option_focused: isFocused,
          select__option_selected: isSelected,
          select__option_disabled: isDisabled,
          'select__option_multi-line': isMultiLine,
          'select__option_light-focus': theme === themeOptions.light && isFocused,
          [`${selectedOptionClassName}`]: isSelected,
        }),
      clearIndicator: () => cn('select__clear-indicator', clearClassName),
      placeholder: () => cn('select__placeholder', placeholderClassName),
      singleValue: () =>
        cn('select__single-value', singleValueClassName, { 'select__single-value_hidden': isEditable }),
    }),
    [
      controlClassName,
      dropDownClassName,
      separatorClassName,
      menuClassName,
      menuPortalClassName,
      menuListClassName,
      clearClassName,
      placeholderClassName,
      singleValueClassName,
      selectedOptionClassName,
    ]
  );

  // @ts-ignore
  const onFocus = () => value && innerRef?.current?.select?.inputRef?.select();

  const handleInputChange = useCallback(
    (newValue: string, meta: InputActionMeta) => {
      if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
        setInputValue(newValue);

        if (!newValue.trim()) {
          const emptyValue = multiple ? value : { label: '', value: '' };

          onChange(emptyValue);
        }
      }
    },
    [onChange, value, multiple]
  );

  const handleEditableChange = useCallback(
    (option: MultiValue<TOption> | SingleValue<TOption>): void => {
      if (!option) {
        onChange({ label: '', value: '' });
        return;
      }
      onChange(option);
    },
    [onChange]
  );

  return (
    <div
      data-test-id={id}
      className={cn(
        'select-main',
        { 'select-main_disabled': disabled, 'select-main_light': theme === themeOptions.light },
        className
      )}
    >
      {isEditable ? (
        <ReactSelect
          isLoading={isLoading}
          // @ts-ignore
          ref={innerRef}
          inputId={id}
          options={options}
          placeholder={text}
          classNames={styleClasses}
          value={value}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleEditableChange}
          onFocus={onFocus}
          controlShouldRenderValue
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          onBlur={onBlur}
          isClearable={isClearable}
          components={{
            Input,
            MenuList,
            Option,
            DropdownIndicator,
            GroupHeading,
            ...customComponents,
          }}
          noOptionsMessage={noOptionsMessage}
          menuPortalTarget={isPortalable ? document.body : undefined}
          isMulti={multiple}
          closeMenuOnSelect={!multiple}
          {...otherProps}
        />
      ) : (
        <ReactSelect
          id={`${id}-select-main`}
          // @ts-ignore
          ref={innerRef}
          inputId={id}
          options={options}
          onChange={handleChange}
          classNames={styleClasses}
          menuPortalTarget={isPortalable ? document.body : undefined}
          menuPlacement={menuPlacement}
          controlShouldRenderValue={!multiple}
          placeholder={text}
          menuPosition={menuPosition}
          isMulti={multiple}
          isSearchable={false}
          openMenuOnFocus
          hideSelectedOptions={false}
          closeMenuOnSelect={!multiple}
          isDisabled={disabled}
          components={{
            MenuList,
            Option,
            DropdownIndicator,
            GroupHeading,
            ...customComponents,
          }}
          value={value}
          defaultValue={defaultValue}
          // @ts-ignore
          onSelectAllClick={handleAllSelectClick}
          isAllSelected={options.length === value.length}
          menuIsOpen={menuIsOpen}
          isAnySelected={value.length !== 0}
          menuListWrapperClassName={menuListWrapperClassName}
          showSelectedOption={showSelectedOption}
          dataTestId={id}
          onBlur={onBlur}
          noOptionsMessage={noOptionsMessage}
          formatOptionLabel={formatOptionLabel}
          {...otherProps}
        />
      )}
      {!!errorMessage && <span className={cn('select__error')}>{errorMessage}</span>}
    </div>
  );
};

Select.DefaultOption = DefaultOption;

export default Select;
