import { SVGProps } from 'react';

const FovIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="0.75" width="9.33871" height="9.33871" stroke="white" strokeWidth="1.5" />
    <rect x="13.9111" y="0.75" width="9.33871" height="9.33871" stroke="white" strokeWidth="1.5" />
    <rect x="0.75" y="13.9111" width="9.33871" height="9.33871" stroke="white" strokeWidth="1.5" />
    <rect x="13.9111" y="13.9111" width="9.33871" height="9.33871" stroke="white" strokeWidth="1.5" />
  </svg>
);

export default FovIcon;
