import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import Panel from '@/components/common/Panel';
import { useRunDesignLocationNewFlow } from '@/hooks/runDesign/useRunDesignLocationNewFlow';

import pagePanelStyles from '../PagePanel.module.scss';
import runInformationStyles from './RunInformation.modules.scss';
import TemplateHeader from '../components/TemplateHeader';
import RunInformationFooter from './components/RunInformationFooter';

const cn = classnames.bind({ ...pagePanelStyles, ...runInformationStyles });

const RunInformationDefault: FC = () => {
  const { goToDraftList } = useRunDesignLocationNewFlow();

  const onClickBack = useCallback(() => {
    goToDraftList();
  }, [goToDraftList]);

  // TODO: NEWFLOW temp log
  const onClickContinue = useCallback(() => {
    console.log('continue');
  }, []);

  const onClickSaveAndExit = useCallback(() => {
    goToDraftList();
  }, [goToDraftList]);

  return (
    <Panel className={cn('page-panel')} theme={themeOptions.light}>
      <Panel.Header className={cn('run-information__header')} wrapClassName={cn('page-panel__header-wrap')}>
        <TemplateHeader templateName="templateName" />
      </Panel.Header>

      <Panel.Content className={cn('page-panel__content')}>Work in progress</Panel.Content>

      <div className={cn('page-panel__footer')}>
        <RunInformationFooter
          onClickBack={onClickBack}
          onClickContinue={onClickContinue}
          onClickSaveAndExit={onClickSaveAndExit}
        />
      </div>
    </Panel>
  );
};

export default memo(RunInformationDefault);
