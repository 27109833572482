import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { MDASH } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { includes } from '@/helpers/enum';

import { useFetchNextToken } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { QUERY_LIMIT } from '@/hooks/graphql/useGraphqlQueryFullList';

import { RunDesignStatus } from '@/graphql/runDesign';
import { runDesignGraphqlAPI } from '@/store/services/graphql/runDesign';

import { authAPI } from '@/store/services/auth';
import { experimentRunDesignActions } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';
import PagePanel from '@/components/common/PagePanel';
import NoDataFound from '@/components/common/NoDataFound';
import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';
import { DEFAULT_WIZARD_STEP } from '@/store/slices/experimentRunDesign/slice';
import { graphqlAPI } from '@/store/services/graphql';
import { lastRunDesignStepOrder } from '@/helpers/runDesigns/constants';

import {
  DesignCardRenderer,
  ScreenVariantList,
  TDraftDesignCardData,
  TReadyDesignCardData,
  TScreenVariantList,
  TTemplateDesignCardData,
} from './types';
import { getDraftHref, getRunDesignMeta, getTemplateMeta } from './helpers';

import DesignCard from './components/DesignCard';

import styles from './DesignSelector.module.scss';

const cn = classnames.bind(styles);

const DesignSelector = () => {
  const appDispatch = useAppDispatch();

  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });

  // TODO: NEWFLOW need understand, where we get this data in new api
  const [generateManifest, { isLoading: isManifestLoading }] = graphqlAPI.useGenerateReagentManifestMutation();

  const [
    fetchTemplates,
    { data: templatesData, isLoading: isTemplatesLoading, error: templatesError, isError: isTemplatesError },
  ] = runDesignGraphqlAPI.useLazyFetchTemplatesQuery();

  const [
    fetchRunDesigns,
    { data: runDesigns, isLoading: isRunDesignsLoading, isError: isRunDesignsError, error: runDesignsError },
  ] = runDesignGraphqlAPI.useLazyFetchRunDesignsQuery();

  useEffect(() => {
    // TODO: NEWFLOW temp create manual limit by QUERY_LIMIT items
    fetchTemplates({ limit: QUERY_LIMIT });
    fetchRunDesigns({ limit: QUERY_LIMIT });
  }, []);

  const templateCardDataList = useMemo(
    () =>
      (templatesData?.items ?? []).reduce<TTemplateDesignCardData[]>((list, template) => {
        list.push({
          id: template.id,
          title: template.name,
          // TODO: NEWFLOW temp commented for waiting backend add author/authorId to template
          meta: getTemplateMeta(template),
          href: `/run-design/${ERunDesignSourceType.template}/${template.id}`,
          // TODO: NEWFLOW temp commented for waiting backend for get info about and we dont know, how to get info about assays with new flow of steps
          // ...(template.activeVersion?.schema && {
          //   assayList: getRunDesignAssayList(template.activeVersion.schema),
          // }),
        });

        return list;
      }, []),
    [templatesData, userList]
  );

  const draftRunDesignList = useMemo(
    () => (runDesigns?.items ?? []).filter((runDesignItem) => runDesignItem.status === RunDesignStatus.Draft),
    [runDesigns]
  );
  const readyRunDesignList = useMemo(
    () => (runDesigns?.items ?? []).filter((runDesignItem) => runDesignItem.status === RunDesignStatus.Ready),
    [runDesigns]
  );

  const draftCardDataList = useMemo(
    () =>
      draftRunDesignList.map<TDraftDesignCardData>((runDesign) => {
        const steps = {
          total: lastRunDesignStepOrder,
          // TODO: NEWFLOW now we dont know how to calculate wizard step and does it required
          // current: edge.node?.wizardStep ?? DEFAULT_WIZARD_STEP,
          current: DEFAULT_WIZARD_STEP,
        };
        return {
          id: runDesign.id,
          title: runDesign.name,
          // TODO: NEWFLOW now we dont know how to calculate wizard step and does it required
          href: getDraftHref(runDesign.id, 0),
          meta: getRunDesignMeta(runDesign, userList),
          // assayList: getRunDesignAssayList(edge.node.schema),
          steps,
        };
      }),
    [draftRunDesignList, userList]
  );

  const handleManifestClick = useCallback((id: string) => {
    generateManifest(id)
      .unwrap()
      .then((res) => {
        window.open(res.downloadUrl, '_blank');
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  }, []);

  const readyCardDataList = useMemo(
    () =>
      readyRunDesignList.map<TReadyDesignCardData>((runDesign) => ({
        id: runDesign.id,
        title: runDesign.name,
        href: `/run-design/${ERunDesignSourceType.draft}/${runDesign.id}`,
        meta: getRunDesignMeta(runDesign, userList),
        // TODO: NEWFLOW we cant work with assay list
        // assayList: getRunDesignAssayList(edge.node.schema),
        // TODO: NEWFLOW we dont know how to correct get data about lanes
        laneCount: runDesign.flowcell?.lanes.length ?? MDASH,
        controls: [
          {
            label: 'Edit',
            icon: <icons.SettingsIcon />,
            clickHandler: () => {
              navigate(`/run-design/${ERunDesignSourceType.draft}/${runDesign.id}`);
            },
          },
          {
            label: 'Manifest',
            icon: <icons.ManifestIcon />,
            clickHandler: () => {
              // TODO: NEWFLOW WIP
              toast.info('Work in progress');
              handleManifestClick(runDesign.id);
            },
            disabled: isManifestLoading,
          },
        ],
      })),
    [readyRunDesignList, userList, isManifestLoading]
  );

  const isLoading = useMemo(() => isTemplatesLoading || isRunDesignsLoading, [isTemplatesLoading, isRunDesignsLoading]);
  const isError = useMemo(() => isTemplatesError || isRunDesignsError, [isTemplatesError, isRunDesignsError]);
  const errorMessage = useMemo(
    () => getErrorMessage(templatesError || runDesignsError),
    [templatesError, runDesignsError]
  );
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = useMemo(() => {
    const tab = searchParams.get('tab');

    if (!tab) {
      return ScreenVariantList.templates;
    }

    return tab;
  }, [searchParams]);
  const handleToggleViewClickFactory = (newValue: TScreenVariantList) => () => {
    if (includes([ScreenVariantList.templates], newValue)) {
      // hide default tab value
      setSearchParams((params) => {
        params.delete('tab');
        return params;
      });
      return;
    }

    setSearchParams({ tab: newValue });
  };

  const pageControlsConfig: TDefaultPageControlsConfig = {
    back: {
      clickHandler: () => {
        navigate('/home');
      },
    },
  };

  useEffect(() => {
    appDispatch(experimentRunDesignActions.clear());
  }, []);

  return (
    <PagePanel
      title="Experiment design"
      isLoading={isLoading}
      className={cn('design-experiment')}
      config={pageControlsConfig}
    >
      {!isLoading && isError && (
        <div className={cn('design-experiment__error')}>
          <NoDataFound size="big" textData={errorMessage} />
        </div>
      )}

      {!isLoading && !isError && (
        <>
          <PagePanel.CollapsableHeader
            className={cn('design-experiment__header-wrap')}
            collapsedClassName={cn('_collapsed')}
          >
            <div className={cn('design-experiment__view-toggle')}>
              <button
                className={cn('design-experiment__templates', { _active: currentTab === ScreenVariantList.templates })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.templates)}
              >
                Templates
                <div className={cn('design-experiment__counter')}>{templateCardDataList.length}</div>
              </button>
              <button
                className={cn('design-experiment__drafts', { _active: currentTab === ScreenVariantList.drafts })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.drafts)}
              >
                Drafts
                <div className={cn('design-experiment__counter')}>{draftCardDataList.length}</div>
              </button>
              <div className={cn('design-experiment__view-toggle-spacer')} />
              <button
                className={cn('design-experiment__ready', { _active: currentTab === ScreenVariantList.ready })}
                onClick={handleToggleViewClickFactory(ScreenVariantList.ready)}
              >
                Ready
                <div className={cn('design-experiment__counter')}>{readyCardDataList.length}</div>
              </button>
            </div>

            <div className={cn('design-experiment__header')}>
              Select a template
              <icons.SlashBigIcon className={cn('design-experiment__header-slash')} />
              finish the draft
            </div>
          </PagePanel.CollapsableHeader>

          <div className={cn('dashboard__items_cards', 'design-experiment__content')}>
            {currentTab === ScreenVariantList.templates && (
              <>
                {templateCardDataList.map((data) => (
                  <DesignCard data={data} key={data.id} />
                ))}
                {templateCardDataList.length === 0 && (
                  <NoDataFound textData="No templates found" className={cn('design-experiment__no-data')} />
                )}
              </>
            )}
            {currentTab === ScreenVariantList.drafts && (
              <>
                {draftCardDataList.map((data) => (
                  <DesignCard render={DesignCardRenderer.draft} data={data} key={data.id} />
                ))}
                {draftCardDataList.length === 0 && (
                  <NoDataFound textData="No drafts found" className={cn('design-experiment__no-data')} />
                )}
              </>
            )}
            {currentTab === ScreenVariantList.ready && (
              <>
                {readyCardDataList.map((data) => (
                  <DesignCard render={DesignCardRenderer.ready} data={data} key={data.id} />
                ))}
                {readyCardDataList.length === 0 && (
                  <NoDataFound
                    textData="No ready run designs found"
                    size="big"
                    className={cn('design-experiment__no-data')}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </PagePanel>
  );
};

export default memo(DesignSelector);
