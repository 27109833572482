import { FC, memo } from 'react';

import FooterControls from '@/components/runDesign/FooterControls';
import FooterTabs from '@/pages/experiment-run-design-new-flow/components/FooterTabs';

type TDesignWorkflowFooterProps = {
  className?: string;
};

const DesignWorkflowFooter: FC<TDesignWorkflowFooterProps> = ({ className }) => (
  <FooterControls.Wrapper className={className}>
    <FooterControls.SaveAndExit onClick={() => null} />
    <FooterTabs />
    <FooterControls.Group>
      <FooterControls.Revert onClick={() => null} />
      <FooterControls.Save onClick={() => null} />
    </FooterControls.Group>
  </FooterControls.Wrapper>
);

export default memo(DesignWorkflowFooter);
