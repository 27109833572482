import { SVGProps } from 'react';

const FovFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.75" y="0.75" width="12.5" height="12.5" fill="currentColor" stroke="currentColor" strokeWidth="1.5" />
    <rect x="17.75" y="0.75" width="12.5" height="12.5" fill="currentColor" stroke="currentColor" strokeWidth="1.5" />
    <rect x="0.75" y="17.75" width="12.5" height="12.5" fill="currentColor" stroke="currentColor" strokeWidth="1.5" />
    <rect x="17.75" y="17.75" width="12.5" height="12.5" fill="currentColor" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default FovFillIcon;
