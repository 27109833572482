import {
  ECreatableComponentTypeNewFlow,
  TCreatableComponentTypeNewFlow,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign/types';
import {
  getInitialCytokineSecretionComponent,
  getInitialIncubationComponent,
  getInitialMRNAComponent,
  getInitialScanComponentNewFlow,
  getInitialSurfaceReceptorComponent,
} from './getInitialComponent';

export const renameByTypeOrder = <T extends { name: string; type: string }>(items: T[]): T[] => {
  const countByType: Record<string, number> = {};
  const currentCountByType: Record<string, number> = {};

  items.forEach((item) => {
    if (!countByType[item.type]) {
      countByType[item.type] = 0;
    }
    countByType[item.type]++;
    currentCountByType[item.type] = 0;
  });

  const updatedComponents = structuredClone(items);
  updatedComponents.forEach((component) => {
    component.name = component.name.trim().replace(/\s\d+$/, '');

    if (countByType[component.type] <= 1) {
      return;
    }

    const count = ++currentCountByType[component.type];
    component.name = `${component.name} ${count}`;
  });

  return updatedComponents;
};

export const getComponentListWithNewStepNewFlow = (
  type: TCreatableComponentTypeNewFlow,
  components: TRunDesignComponent[],
  id?: Nullable<string>
) => {
  const correctId = id ?? components?.at(-1)?.id;
  if (!correctId) {
    return components ?? [];
  }

  const relativeTo = components.find((component) => component.id === correctId) ? correctId : components.at(-1)?.id;
  const newComponentIndex = components.findIndex((component) => component.id === relativeTo);

  // TODO: NEWFLOW need add new components here
  const initialComponentList: Record<TCreatableComponentTypeNewFlow, any> = {
    [ECreatableComponentTypeNewFlow.Incubation]: getInitialIncubationComponent,
    [ECreatableComponentTypeNewFlow.SurfaceReceptor]: getInitialSurfaceReceptorComponent,
    [ECreatableComponentTypeNewFlow.CytokineSecretion]: getInitialCytokineSecretionComponent,
    [ECreatableComponentTypeNewFlow.mRNA]: getInitialMRNAComponent,
    [ECreatableComponentTypeNewFlow.ScanComp]: getInitialScanComponentNewFlow,
  };

  components.splice(newComponentIndex + 1, 0, initialComponentList[type](1, relativeTo));

  return components;
};
