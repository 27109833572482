import { FC, memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classnames from 'classnames/bind';

import { DRAGGABLE_TYPES } from '@/helpers';

import styles from './DndItem.module.scss';

import type { TDndItem, TDragItem } from './types';

const cn = classnames.bind(styles);

const DndItem: FC<TDndItem> = ({ id, index, onDrop, render, className, dragType }) => {
  const [{ isDragging }, drag] = useDrag<TDragItem, unknown, { isDragging: boolean }>(
    () => ({
      type: DRAGGABLE_TYPES[dragType],
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(), // Track if the item is currently being dragged
      }),
      item: {
        id,
        index,
        type: dragType,
      },
    }),
    [index, id, dragType, onDrop]
  );

  const [{ isBefore }, dropBeforeRef] = useDrop<TDragItem, unknown, { isBefore: boolean }>(
    () => ({
      accept: DRAGGABLE_TYPES[dragType],
      drop: (item) => {
        // Trigger the onDrop callback when an item is dropped before the current item
        onDrop(item.index, index, { isAfter: false, isBefore: true });
      },
      collect: (monitor) => ({
        isBefore: monitor.isOver(), // Track if an item is being dragged over the "before" area
      }),
    }),
    [index, id, dragType, onDrop]
  );

  const [{ isAfter }, dropAfterRef] = useDrop<TDragItem, unknown, { isAfter: boolean }>(
    () => ({
      accept: DRAGGABLE_TYPES[dragType],
      drop: (item) => {
        // Trigger the onDrop callback when an item is dropped after the current item
        onDrop(item.index, index, { isAfter: true, isBefore: false });
      },
      collect: (monitor) => ({
        isAfter: monitor.isOver(), // Track if an item is being dragged over the "after" area
      }),
    }),
    [index, id, dragType, onDrop]
  );

  return (
    <div className={cn('dnd-item', className)} ref={drag} data-handler-id={id}>
      {/* Visual mask for drop zones */}
      <div className={cn('dnd-mask')}>
        <div className={cn('dnd-mask__before')} ref={dropBeforeRef} />
        <div className={cn('dnd-mask__after')} ref={dropAfterRef} />
      </div>
      {render({ isDragging, isHovered: isAfter || isBefore, isAfter, isBefore })}
    </div>
  );
};

export default memo(DndItem);
