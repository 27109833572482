import { CSSProperties, FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import { TContentSideItemProps } from '../../types';

import { usePanelWithFoldingContext } from '../../context';

import styles from './Content.module.scss';
import { getSideGridColumn } from '../../helpers';

const cn = classnames.bind(styles);

const ContentSideItem: FC<TContentSideItemProps> = ({ id, renderContent, blockClassNameFn, blockIndex }) => {
  const { sideBlocksOpenStatusMap, toggleSide, showingSideBlocks, collapseAllSideBlocks } =
    usePanelWithFoldingContext();

  const isOpen = useMemo(() => !!sideBlocksOpenStatusMap[id], [sideBlocksOpenStatusMap, id]);

  const toggleOpen = useCallback(() => {
    toggleSide(id, !isOpen, blockIndex);
  }, [id, blockIndex, isOpen]);

  // Computes the class name for the content block based on the provided function.
  const blockClassName: string | undefined = useMemo(
    () => blockClassNameFn?.({ isOpen, toggleOpen, collapseAllSideBlocks }),
    [isOpen, toggleOpen, blockClassNameFn, collapseAllSideBlocks]
  );

  // Computes the grid column style for the content block based on its state and side block visibility.
  const gridColumn: string = useMemo(
    () => getSideGridColumn(showingSideBlocks, blockIndex),
    [showingSideBlocks, blockIndex, isOpen]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={cn('content-side', blockClassName)}
      style={{ '--grid-content-side-column': gridColumn } as CSSProperties}
    >
      {renderContent({ toggleOpen, isOpen, collapseAllSideBlocks })}
    </div>
  );
};

export default ContentSideItem;
