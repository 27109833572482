import { FC, memo, useCallback, useMemo, CSSProperties } from 'react';
import classnames from 'classnames/bind';

import { TContentBaseItemProps } from '../../types';
import { usePanelWithFoldingContext } from '../../context';

import styles from './Content.module.scss';
import { getContentBaseColumn } from '../../helpers';

const cn = classnames.bind(styles);

const ContentBaseItem: FC<TContentBaseItemProps> = ({
  id,
  renderContent,
  contentBlockClassNameFn,
  isExpandable,
  isLast,
}) => {
  const { changeExpandedId, expandedId, showingSideBlocks, expandableCount, collapseAllSideBlocks } =
    usePanelWithFoldingContext();

  const toggleExpanded = useCallback(() => {
    if (!isExpandable) {
      return;
    }
    changeExpandedId(id);
  }, [id, isExpandable]);

  const isExpanded = useMemo(() => id === expandedId, [id, expandedId]);

  // Computes the class name for the content block based on the provided function.
  const blockClassName: string | undefined = useMemo(
    () => contentBlockClassNameFn?.({ expandedId, isExpanded, toggleExpanded, collapseAllSideBlocks }),
    [expandedId, isExpanded, toggleExpanded, contentBlockClassNameFn]
  );

  // Computes the grid column style for the content block based on its state and side block visibility.
  const gridColumn = useMemo(
    () => getContentBaseColumn({ expandableCount, isExpanded, isLast, sideList: showingSideBlocks }),
    [showingSideBlocks, expandableCount, isExpanded, isLast]
  );

  if (expandedId && !isExpanded && isExpandable) {
    return null;
  }

  return (
    <div className={cn('content-base', blockClassName)} style={{ '--grid-column': gridColumn } as CSSProperties}>
      {renderContent({
        expandedId,
        toggleExpanded,
        collapseAllSideBlocks,
        isExpanded,
      })}
    </div>
  );
};

export default memo(ContentBaseItem);
