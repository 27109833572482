export const dndReorder = <T>(list: T[], dragIndex: number, dropIndex: number, isBefore = true): T[] => {
  const copyList = [...list];

  // Validate indices to ensure they are within the bounds of the list
  if (dropIndex < 0 || dragIndex < 0 || dragIndex >= list.length || dropIndex >= list.length) {
    return copyList;
  }

  // Calculate the offset for the drop position based on the `isBefore` flag
  const offset = isBefore ? 0 : 1;
  // Extract the element being dragged from the list.
  const dragElement = copyList[dragIndex];
  // Mark the dragged element's original position as `null` (to be removed later).
  // @ts-ignore
  copyList[dragIndex] = null;
  // Use `splice` to insert the dragged element at the correct position.
  copyList.splice(dropIndex + offset, 0, dragElement);
  // Filter out the `null` value(s) to clean up the list
  const filtered = copyList.filter(Boolean);
  return filtered;
};
