import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';
import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import { isIncubationComponent } from '@/store/slices/experimentRunDesign';
import { formatDurationText } from '@/pages/experiment-run-design/DesignTimeline/components/Timeline/helpers';
import { iconList } from '@/helpers/runDesigns/constants';
import { TCardData } from '@/pages/experiment-run-design/DesignTimeline/components/Timeline/types';
import styles from './DefaultRenderer.module.scss';
import timelineCardStyles from '../TimelineCard.module.scss';

const cn = classNames.bind({ ...timelineCardStyles, ...styles });

type TDefaultRendererProps = {
  data: TCardData;
  position: {
    row: number;
    column: number;
  };
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const DefaultRenderer: FC<TDefaultRendererProps> = ({ data, position, onClick }) => {
  const abbreviationName = useMemo(() => getAbbreviationName(data.component), [data.component]);

  return (
    <button
      className={cn('timeline-card', 'default-card', {
        'timeline-card_simultaneous': position.row > 1,
        'timeline-card_no-scan': !data.scan,
      })}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
        } as CSSProperties
      }
    >
      <div className={cn('timeline-card__wrap')}>
        <div className={cn('timeline-card__block', 'default-card__main')}>
          <div className={cn('timeline-card__header')}>
            <h3 className={cn('timeline-card__title')}>
              <span className={cn('timeline-card__text')}>{data.component.name}</span>
              <span className={cn('timeline-card__text', 'timeline-card__text_abbreviation')}>{abbreviationName}</span>
              {isIncubationComponent(data.component) && data.component?.hasTreatment && <span> +TRT</span>}
            </h3>
            <p className={cn('timeline-card__duration')}>{formatDurationText(data.durationText)}</p>
          </div>
          {data.iconCollection && (
            <div className={cn('timeline-card__icon-list')}>
              {data.iconCollection.map((iconId) => (
                <div className={cn('timeline-card__icon-wrap')} key={iconId}>
                  {iconList[iconId]}
                </div>
              ))}
            </div>
          )}
        </div>

        {data.scan && (
          <div className={cn('timeline-card__block', 'default-card__scan')}>
            <div className={cn('timeline-card__header')}>
              <h3 className={cn('timeline-card__title')}>{data.scan.title}</h3>
              <p className={cn('timeline-card__duration')}>{formatDurationText(data.scan.durationText)}</p>
            </div>
            <div className={cn('timeline-card__icon-list')}>
              {data.scan.iconCollection.map((iconId) => (
                <div className={cn('timeline-card__icon')} key={iconId}>
                  {iconList[iconId]}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </button>
  );
};

export default DefaultRenderer;
