import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EComponentTypes, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import SampleInformationComponent from './scanComponents/SampleInformationComponent';
import CellCageComponent from './scanComponents/CellCageComponent';
import SurfaceReceptorComponent from './scanComponents/SurfaceReceptorComponent';
import CytokineSecretionComponent from './scanComponents/CytokineSecretionComponent';
import IncubationComponent from './scanComponents/IncubationComponent';
import MrnaComponent from './scanComponents/MrnaComponent';

const componentByType = (type?: Nullable<EComponentTypes>): FC => {
  switch (type) {
    case null:
    case undefined:
      return SampleInformationComponent;
    case EComponentTypes.CellCagingComponent:
      return CellCageComponent;
    case EComponentTypes.SurfaceReceptorComponent:
      return SurfaceReceptorComponent;
    case EComponentTypes.CytokineSecretionComponent:
      return CytokineSecretionComponent;
    case EComponentTypes.IncubationComponent:
      return IncubationComponent;
    case EComponentTypes.mRNAComponent:
      return MrnaComponent;
    default: {
      const Component: FC = () => <h1>WORK IN PROGRESS</h1>;
      return Component;
    }
  }
};

const ComponentByType: FC = () => {
  const componentInfo = useSelector(experimentRunDesignSelectors.selectCurrentComponentInfo);

  const Component = useMemo(() => componentByType(componentInfo?.type as EComponentTypes), [componentInfo?.type]);

  return <Component />;
};

export default memo(ComponentByType);
