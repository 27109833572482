import { FC } from 'react';

import MrnaHeader from './MrnaHeader';

const MrnaComponent: FC = () => (
  <>
    <MrnaHeader />
    <b>WORK IN PROGRESS</b>
  </>
);

export default MrnaComponent;
