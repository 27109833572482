import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import {
  experimentRunDesignSelectors,
  isIncubationComponent,
  isSubCompIncubation,
} from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';
import PanelWithFolding from '@/components/runDesign/PanelWithFolding';
import RunDesignBlockWithLanesNewFlow from '@/components/runDesign/RunDesignBlockWithLanes/RunDesignBlockWithLanesNewFlow';

import styles from './IncubationComponent.module.scss';
import IncubationBaseBlock from './components/IncubationBaseBlock';

const cn = classnames.bind(styles);

const IncubationContent: FC = () => {
  const currentComponentInfo = useSelector(experimentRunDesignSelectors.selectCurrentComponentInfo);

  if (!currentComponentInfo) {
    return null;
  }

  if (!isIncubationComponent(currentComponentInfo)) {
    return null;
  }

  if (!isSubCompIncubation(currentComponentInfo.incubation)) {
    return null;
  }

  const { incubation } = currentComponentInfo;

  return (
    <RunDesignBlockWithLanesNewFlow>
      <PanelWithFolding
        className={cn('incubation__panel')}
        renderBaseList={[
          {
            id: 'incubation',
            width: '1fr',
            renderHeader: () => <PanelWithFolding.Title title="Incubation" Icon={icons.IncubationSmallIcon} />,
            renderContent: () => (
              <IncubationBaseBlock
                componentId={currentComponentInfo.id}
                duration={incubation.duration}
                onDevice={incubation.onDevice}
                temperature={incubation.temperature}
                temperatureUnit={incubation.temperatureUnit}
              />
            ),
          },
        ]}
      />
    </RunDesignBlockWithLanesNewFlow>
  );
};

export default memo(IncubationContent);
