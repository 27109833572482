import { FC } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import PanelWithFolding from '@/components/runDesign/PanelWithFolding';
import { TRenderBaseComponentProps } from '@/components/runDesign/PanelWithFolding/types';

import styles from './CellCageBaseBlock.module.scss';
import CellCageAdvancedButton from './CellCageAdvencedButton';

const cn = classnames.bind(styles);

const CellCageBaseBlockHeader: FC<TRenderBaseComponentProps> = () => (
  <div className={cn('cell-cage-header-block')}>
    <PanelWithFolding.Title Icon={icons.CellanomeSmallIcon} title="CCE formation" className={cn('title')} />
    <CellCageAdvancedButton />
  </div>
);

export default CellCageBaseBlockHeader;
