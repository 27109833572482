import {
  CellCaging,
  CellKilling,
  Compound,
  CytokineSecretion,
  Incubation,
  mRNA,
  OpticsSettingsItem,
  Reagent,
  ScanComp,
  SelectiveRetention,
  Stain,
  SurfaceReceptor,
  SurfaceReceptorCytokineSecretion,
  Treatment,
} from '@/graphql/API';

import { EDesignStep, TDesignStep } from '@/helpers/runDesigns/constants';

import { TRunDesignComponent, TRunDesignConsumable } from '@/store/slices/experimentRunDesign/types';

export const isIncubationComponent = (component?: Nullable<TRunDesignComponent>): component is Incubation =>
  (component && component.__typename === 'Incubation') ?? false;

export const isScanComponent = (component?: Nullable<TRunDesignComponent>): component is ScanComp =>
  (component && component.__typename === 'ScanComp') ?? false;

export const isComponentWithWash = (
  component?: Nullable<TRunDesignComponent>
): component is SurfaceReceptor | SurfaceReceptorCytokineSecretion | CellKilling | CytokineSecretion | Treatment =>
  (component &&
    [
      'SurfaceReceptor',
      'SurfaceReceptorCytokineSecretion',
      'CellKilling',
      'CytokineSecretion',
      'Treatment',
      'Incubation',
    ].includes(component.__typename)) ??
  false;

export const isComponentWithIncubation = (
  component?: Nullable<TRunDesignComponent>
): component is
  | SurfaceReceptor
  | SurfaceReceptorCytokineSecretion
  | CellKilling
  | CytokineSecretion
  | Incubation
  | Treatment =>
  (component &&
    [
      'SurfaceReceptor',
      'SurfaceReceptorCytokineSecretion',
      'CellKilling',
      'CytokineSecretion',
      'Incubation',
      'Treatment',
    ].includes(component.__typename)) ??
  false;

export const isComponentWithScan = (
  component?: Nullable<TRunDesignComponent>
): component is
  | CellCaging
  | CellKilling
  | CytokineSecretion
  | Incubation
  | SelectiveRetention
  | SurfaceReceptor
  | SurfaceReceptorCytokineSecretion
  | Treatment =>
  (component &&
    [
      'CellCaging',
      'CellKilling',
      'CytokineSecretion',
      'Incubation',
      'SelectiveRetention',
      'SurfaceReceptor',
      'SurfaceReceptorCytokineSecretion',
      'Treatment',
    ].includes(component.__typename)) ??
  false;

export const isReagentConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Reagent =>
  (consumable && ['Reagent'].includes(consumable.__typename)) ?? false;

export const isCompoundConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Compound =>
  (consumable && ['Compound'].includes(consumable.__typename)) ?? false;

export const isStainConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Stain =>
  (consumable && ['Stain'].includes(consumable.__typename)) ?? false;

export const isAssayComponent = (
  component?: Nullable<TRunDesignComponent>
): component is SurfaceReceptor | SurfaceReceptorCytokineSecretion | CellKilling | CytokineSecretion | mRNA =>
  (component &&
    ['SurfaceReceptor', 'SurfaceReceptorCytokineSecretion', 'CellKilling', 'CytokineSecretion', 'mRNA'].includes(
      component.__typename
    )) ??
  false;

export const isCellKillingComponent = (component?: Nullable<TRunDesignComponent>): component is CellKilling =>
  component?.__typename === 'CellKilling';

export const isSurfaceReceptorComponent = (component?: Nullable<TRunDesignComponent>): component is SurfaceReceptor =>
  component?.__typename === 'SurfaceReceptor';

export const isCytokineSecretionComponent = (
  component?: Nullable<TRunDesignComponent>
): component is CytokineSecretion => component?.__typename === 'CytokineSecretion';

export const isMRNAComponent = (component?: Nullable<TRunDesignComponent>): component is mRNA =>
  component?.__typename === 'mRNA';

export const isCellCagingComponent = (component?: Nullable<TRunDesignComponent>): component is CellCaging =>
  component?.__typename === 'CellCaging';

export const isOpticalSettings = (
  settings?: OpticsSettingsItem[] | null | undefined
): settings is OpticsSettingsItem[] => Array.isArray(settings);

export const isRunDesignStep = (str?: string): str is TDesignStep => !!str && Object.keys(EDesignStep).includes(str);
