import { FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import { TemperatureUnit } from '@/graphql/API';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';

import { CELSIUS, FAHRENHEIT } from '@/helpers';

import styles from '../IncubationBaseBlock.module.scss';

const cn = classnames.bind(styles);

type TTemperatureUnitInputProps = {
  temperatureUnit: TemperatureUnit;
  onChange: (value: TemperatureUnit) => void;
};

const options = [
  { value: TemperatureUnit.C, label: CELSIUS },
  { value: TemperatureUnit.F, label: FAHRENHEIT },
];

const TemperatureUnitInput: FC<TTemperatureUnitInputProps> = ({ temperatureUnit, onChange }) => {
  const onChangeTemperatureUnit = useCallback(
    (value: TemperatureUnit) => {
      onChange(value);
    },
    [onChange]
  );
  return (
    <Select
      options={options}
      value={temperatureUnit}
      onChange={onChangeTemperatureUnit}
      theme={themeOptions.light}
      className={cn('select', 'select_narrow')}
      controlClassName={cn('select-control')}
      menuClassName={cn('select-menu')}
      menuPosition="absolute"
      isPortalable={false}
      menuListClassName={cn('select-menu-list')}
      customComponents={{ Option: Select.DefaultOption }}
    />
  );
};

export default memo(TemperatureUnitInput);
