import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import styles from './FooterButtons.module.scss';
import { TDefaultButtonProps } from './types';

const cn = classnames.bind(styles);

type TRevertProps = TDefaultButtonProps;

const Revert: FC<TRevertProps> = ({ onClick, customTitle, className, disabled }) => (
  <Button
    color="light"
    isOutlined
    className={cn('page-controls__button', 'page-controls__revert', className)}
    onClick={onClick}
    disabled={disabled}
  >
    <icons.RevertIcon /> {customTitle ?? 'Revert'}
  </Button>
);

export default memo(Revert);
