import { ChangeEventHandler, FC, memo, useCallback } from 'react';
import classnames from 'classnames/bind';

import CheckboxInput from '@/components/common/CheckboxInput';

import styles from './IncubationBaseBlock.module.scss';

const cn = classnames.bind(styles);

type TOnDeviceInputProps = {
  isOnDevice: boolean;
  toggleSwitch: (checked: boolean) => void;
};

const OnDeviceInput: FC<TOnDeviceInputProps> = ({ isOnDevice, toggleSwitch }) => {
  const onSwitchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ currentTarget: { checked } }) => {
      toggleSwitch(checked);
    },
    [toggleSwitch]
  );

  return (
    <div className={cn('group', 'group_on-device')}>
      <div className={cn('group__title')}>Location</div>
      <div className={cn('group__checkbox-wrap')}>
        <span className={cn('group__checkbox-label')}>On instrument</span>
        <CheckboxInput
          checked={isOnDevice}
          isSwitch
          theme="run-design"
          onChange={onSwitchChange}
          labelClassName={cn('main-block__checkbox-label')}
        />
        <span className={cn('group__checkbox-label')}>Off instrument</span>
      </div>
    </div>
  );
};

export default memo(OnDeviceInput);
