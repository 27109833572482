import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import Button from '@/components/common/Button';

import panelStyles from '@/components/common/PagePanel/PageControls/PageControls.module.scss';

import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';

import styles from './FooterSaveCancelActions.module.scss';

const cn = classNames.bind({ ...panelStyles, ...styles });

type TFooterSaveCancelActions = {
  className?: string;
  config: TDefaultPageControlsConfig;
};

const FooterSaveCancelActions: FC<TFooterSaveCancelActions> = ({ className, config }) => {
  const [blockLeftPost, setBlockLeftPost] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [layoutZoom, setLayoutZoom] = useState(1);
  const actionsBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (config.refCheck?.current) {
        const rect = config.refCheck.current.getBoundingClientRect();
        const emptyLayoutEl = document.querySelector('#empty-layout');
        const stylesMap = emptyLayoutEl?.computedStyleMap ? emptyLayoutEl.computedStyleMap() : undefined;
        const zoomProp = stylesMap?.get('--zoom');
        const zoomValue = Number(zoomProp?.toString()) || 1;
        setLayoutZoom(zoomValue);
        setBlockLeftPost(rect.left / zoomValue);
      }
      requestAnimationFrame(updatePosition);
    };

    const handleNumber = requestAnimationFrame(updatePosition);

    return () => {
      cancelAnimationFrame(handleNumber);
    };
  }, []);

  useEffect(() => {
    if (!actionsBlockRef.current) {
      return;
    }

    const newPaddingLeft = actionsBlockRef.current.getBoundingClientRect().left / layoutZoom;
    setPaddingLeft(blockLeftPost - newPaddingLeft);
  }, [blockLeftPost, actionsBlockRef.current, layoutZoom]);

  const handleSaveAndContinue = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      config?.saveAndContinue?.clickHandler?.(e);
    },
    [config?.saveAndContinue?.clickHandler]
  );

  const handleCancel = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      config?.cancel?.clickHandler?.(e);
    },
    [config?.cancel?.clickHandler]
  );

  return (
    <div className={cn('footer-save-cancel-actions', className)} ref={actionsBlockRef} style={{ paddingLeft }}>
      <Button
        color="yellow"
        className={cn('page-controls__button', 'page-controls__next')}
        onClick={handleSaveAndContinue}
      >
        Save
      </Button>
      <Button
        color="light"
        isOutlined
        className={cn('page-controls__button', 'page-controls__cancel')}
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </div>
  );
};

export default FooterSaveCancelActions;
