import { FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useDrag, useDrop } from 'react-dnd';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import icons from '@/components/common/icons';
import { DRAGGABLE_TYPES } from '@/helpers';
import { isCellKillingComponent } from '@/helpers/runDesigns/typeGuards';
import { getScanLabelsMap } from '@/helpers/runDesigns/timeline';
import DefaultRenderer from './DefaultRenderer';
import CellKillingRenderer from './CellKillingRenderer';
import styles from './SchemaComponent.module.scss';

const cn = classNames.bind(styles);

type TSchemaComponentProps = {
  isCurrent: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  component: TRunDesignComponent;
  componentListData: {
    component: TRunDesignComponent;
    position: {
      row: number;
      column: number;
      columnEnd?: number;
    };
  }[];
  replaceList: {
    onPlaceBefore: (id: string, relativeTo: string) => void;
    onPlaceAfter: (id: string, relativeTo: string) => void;
  };
};

const SchemaComponent: FC<TSchemaComponentProps> = ({
  isCurrent = false,
  onClick,
  position,
  component,
  componentListData,
  replaceList: { onPlaceBefore, onPlaceAfter },
}) => {
  const scanLabelMap = useMemo(
    () => getScanLabelsMap(componentListData.map((data) => data.component)),
    [componentListData]
  );

  const [{ opacity, isDragging }, dragRef] = useDrag(
    () => ({
      type: DRAGGABLE_TYPES.runDesignComponent,
      item: { id: component.id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [component.id]
  );

  const [{ isBeforeOver, isAnyDragging }, dropBeforeRef] = useDrop<
    { id: string },
    unknown,
    { isBeforeOver: boolean; isAnyDragging: boolean }
  >(
    () => ({
      accept: DRAGGABLE_TYPES.runDesignComponent,
      drop: ({ id }) => {
        onPlaceBefore(id, component.id);
      },
      collect: (monitor) => ({
        isBeforeOver: monitor.isOver(),
        isAnyDragging: !!monitor.getItem(),
      }),
    }),
    []
  );

  const [{ isAfterOver }, dropAfterRef] = useDrop<{ id: string }, unknown, { isAfterOver: boolean }>(
    () => ({
      accept: DRAGGABLE_TYPES.runDesignComponent,
      drop: ({ id }) => {
        onPlaceAfter(id, component.id);
      },
      collect: (monitor) => ({
        isAfterOver: monitor.isOver(),
      }),
    }),
    []
  );

  if (isCellKillingComponent(component)) {
    return (
      <CellKillingRenderer
        isCurrent={isCurrent}
        component={component}
        onClick={onClick}
        position={position}
        scanLabelMap={scanLabelMap}
        componentListData={componentListData}
      />
    );
  }

  return (
    <DefaultRenderer
      isCurrent={isCurrent}
      component={component}
      onClick={onClick}
      position={position}
      scanLabelMap={scanLabelMap}
      dnd={{
        opacity,
        dragRef,
        dropArea: isAnyDragging && !isDragging && !isCellKillingComponent(component) && (
          <div className={cn('schema-component__drop-wrap')}>
            <div className={cn('schema-component__drop-zone', { '_is-over': isBeforeOver })} ref={dropBeforeRef}>
              <div className={cn('schema-component__drop', 'schema-component__drop_before')}>
                <icons.ArrowRightIcon className={cn('schema-component__drop-icon')} />
              </div>
            </div>
            <div className={cn('schema-component__drop-zone', { '_is-over': isAfterOver })} ref={dropAfterRef}>
              <div className={cn('schema-component__drop', 'schema-component__drop_after')}>
                <icons.ArrowRightIcon className={cn('schema-component__drop-icon')} />
              </div>
            </div>
          </div>
        ),
      }}
    />
  );
};

export default SchemaComponent;
