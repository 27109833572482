import { FC, ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import styles from './RunDesignBlockWithLanes.module.scss';
import LanesBlockNew from '../LanesBlock/LanesBlockNew';

const cn = classNames.bind(styles);

type TRunDesignBlockWithLanesNewFlowProps = {
  children?: ReactNode;
  isEditable?: boolean;
  tableClassName?: string;
};

// TODO: NEWFLOW need to be replaced filename to index and remove old index
const RunDesignBlockWithLanesNewFlow: FC<TRunDesignBlockWithLanesNewFlowProps> = ({
  children,
  isEditable,
  tableClassName,
}) => {
  const appDispatch = useAppDispatch();
  const lanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);

  const onChangeLaneActive = useCallback((laneLetter: string, isActive: boolean) => {
    if (isActive) {
      appDispatch(experimentRunDesignActions.removeLane(laneLetter));
      return;
    }
    appDispatch(experimentRunDesignActions.addNewLane(laneLetter));
  }, []);

  return (
    <div className={cn('run-design-block', '_new-flow')}>
      <LanesBlockNew
        tableClassName={cn('table', tableClassName)}
        isEditable={isEditable}
        lanes={lanes}
        onChangeLaneActive={onChangeLaneActive}
      />
      {children}
    </div>
  );
};

export default RunDesignBlockWithLanesNewFlow;
