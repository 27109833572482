import { FC, useEffect, useMemo, useState, useCallback, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import { showErrorToast } from '@/helpers/errors';
import { EDesignStep, EDesignStepNewFlow } from '@/helpers/runDesigns/constants';

import { useOpenModal } from '@/hooks/useOpenModal';
import { usePageControlsConfig } from '@/hooks/runDesign/usePageControlsConfig';
import { useRunDesignLocationNewFlow } from '@/hooks/runDesign/useRunDesignLocationNewFlow';

import Portal from '@/components/common/Portal';
import Button from '@/components/common/Button';
import PagePanel from '@/components/common/PagePanel';
import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';
import DesignCompleteModal from '@/components/runDesign/DesignCompleteModal';

import { useRunDesignLocation } from '../hooks/useRunDesignLocation';
import { useEditDesignData } from '../hooks/useEditDesignData';

import { RunDesignContext, TRunDesignContextProps } from '../context';

import styles from './RunDetails.module.scss';
import SampleInformationHeader from './components/SampleInformationHeader';

const cn = classNames.bind(styles);

const showValidationErrors = (validationErrors: Record<string, string>) => {
  showErrorToast(`The following fields have incorrect values: ${Object.values(validationErrors).join(', ')}`);
};

const RunDetails: FC = () => {
  const { isLoading, isError } = useEditDesignData();
  // TODO: NEWFLOW need to remove logic of steps in new flow
  const { goToNextStep, goToPrevStep, currentStep } = useRunDesignLocation();
  const { goToStep } = useRunDesignLocationNewFlow();
  const { isOpen: isCompleteModalOpen, open: openCompleteModal, onClose: closeCompleteModal } = useOpenModal();

  const [footerConfig, setFooterConfig] = useState({});
  const [title, setTitle] = useState<ReactNode>('');
  const [customFooterContent, setCustomFooterContent] = useState<ReactNode>(null);
  const [saveAndContinueMethod, setSaveAndContinueMethod] = useState<() => void>(() => null);
  const [customFooterConfig, setCustomFooterConfig] = useState<Partial<TDefaultPageControlsConfig>>({});
  const [customHeaderContent, setCustomHeaderContent] = useState<ReactNode>(null);
  const [customPanelHeaderContent, setCustomPanelHeaderContent] = useState<ReactNode>(null);
  const [incubationChangeTrigger, setIncubationChangeTrigger] = useState(false);

  const { pageControlsConfig, handleUpdateRunDesign } = usePageControlsConfig(showValidationErrors, openCompleteModal);

  useEffect(() => {
    setFooterConfig({ ...pageControlsConfig, ...customFooterConfig });
  }, [pageControlsConfig, customFooterConfig]);

  const overrideCustomFooterConfig = useCallback(
    (overrideValue: Partial<TDefaultPageControlsConfig>) => {
      setCustomFooterConfig((prev) => ({ ...prev, ...overrideValue }));
    },
    [goToNextStep, goToPrevStep]
  );

  const clearCustomFooterConfig = useCallback(() => {
    setCustomFooterConfig({});
  }, []);

  const addCustomControls = useCallback(setCustomFooterContent, []);
  const removeCustomControls = useCallback(() => setCustomFooterContent(null), []);

  const addCustomHeaderContent = useCallback((node: ReactNode) => {
    setCustomHeaderContent(node);
  }, []);

  const clearCustomHeaderContent = useCallback(() => {
    setCustomHeaderContent(null);
  }, []);

  const runDesignContextValue: TRunDesignContextProps = useMemo(
    () => ({
      footerConfig,
      overrideCustomFooterConfig,
      addCustomControls,
      removeCustomControls,
      setTitle,
      saveAndContinueMethod,
      setSaveAndContinueMethod,
      clearCustomFooterConfig,
      addCustomHeaderContent,
      clearCustomHeaderContent,
      handleUpdateRunDesign,
      incubationChangeTrigger,
      setIncubationChangeTrigger,
      setCustomPanelHeaderContent,
    }),
    [
      footerConfig,
      overrideCustomFooterConfig,
      setTitle,
      saveAndContinueMethod,
      setSaveAndContinueMethod,
      clearCustomFooterConfig,
      clearCustomHeaderContent,
      addCustomHeaderContent,
      handleUpdateRunDesign,
      incubationChangeTrigger,
      setIncubationChangeTrigger,
      setCustomPanelHeaderContent,
    ]
  );

  const conditionalProps = useMemo(() => {
    if (customFooterContent) {
      return {
        customControls: customFooterContent,
      };
    }

    return {
      config: footerConfig,
    };
  }, [footerConfig, customFooterContent]);

  const onNewFlowClick = useCallback(() => {
    goToStep(EDesignStepNewFlow.designWorkflow);
  }, []);

  // TODO: NEWFLOW need update this render after design will be approved
  return (
    <RunDesignContext.Provider value={runDesignContextValue}>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <PagePanel
          title="Run details"
          className={cn('run-details')}
          isLoading={isLoading}
          {...conditionalProps}
          customPanelHeaderContent={customPanelHeaderContent}
        >
          <div className={cn('run-details__header')}>
            {customHeaderContent ?? (
              <SampleInformationHeader
                title={title}
                isLoading={isLoading}
                isError={isError}
                isChangeCellTypesAllowed={currentStep === EDesignStep.sample}
                isChangeLanesAllowed={currentStep === EDesignStep.sample}
              />
            )}
            <Portal portalId="root">
              <Button onClick={onNewFlowClick} className={cn('to-new-flow-btn')}>
                New flow
              </Button>
            </Portal>
          </div>
          {!isLoading && !isError && <Outlet />}
          {isCompleteModalOpen && <DesignCompleteModal isOpen={isCompleteModalOpen} closeModal={closeCompleteModal} />}
        </PagePanel>
      </DndProvider>
    </RunDesignContext.Provider>
  );
};

export default RunDetails;
