import { CSSProperties, FC, memo, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import { isDefined } from '@/helpers/typeGuards';

import { THeaderBaseItemProps } from '../../types';

import styles from './Header.module.scss';
import ExpandButton from '../ExpandButton';
import { usePanelWithFoldingContext } from '../../context';
import { getHeaderBaseColumn } from '../../helpers';

const cn = classnames.bind(styles);

const HeaderBase: FC<THeaderBaseItemProps> = ({
  renderHeader,
  id,
  headerBlockClassNameFn,
  isExpandable,
  onExpandClickFactory,
}) => {
  const { changeExpandedId, expandedId, expandableCount, collapseAllSideBlocks } = usePanelWithFoldingContext();

  const isExpanded = useMemo(() => expandedId === id, [id, expandedId]);

  // Custom logic on click expand if provided onExpandClickFactory
  const onExpandClickCustom = useCallback(() => {
    onExpandClickFactory?.({ collapseAllSideBlocks, expandedId, isExpanded })();
  }, [collapseAllSideBlocks, expandedId, isExpanded, onExpandClickFactory]);

  const toggleExpanded = useCallback(() => {
    changeExpandedId(id);
    onExpandClickCustom();
  }, [id, onExpandClickCustom]);

  // Computes the class name for the header block based on the provided function.
  const blockClassName: string | undefined = useMemo(
    () => headerBlockClassNameFn?.({ expandedId, isExpanded, toggleExpanded, collapseAllSideBlocks }),
    [expandedId, isExpanded, toggleExpanded, collapseAllSideBlocks, headerBlockClassNameFn]
  );

  // Computes the grid column style for the header based on expandable state.
  const gridColumn = useMemo(() => getHeaderBaseColumn({ expandableCount, isExpanded }), [expandableCount, isExpanded]);

  // If another header is expanded and current header is expandable but not currently expanded, hide it
  if (isDefined(expandedId) && !isExpanded && isExpandable) {
    return null;
  }

  return (
    <div className={cn('header-base', blockClassName)} style={{ '--grid-column': gridColumn } as CSSProperties}>
      <div className={cn('header-base__content')}>
        {renderHeader({
          toggleExpanded,
          collapseAllSideBlocks,
          expandedId,
          isExpanded,
        })}
      </div>
      {/* Render the expand button if the header is expandable */}
      {isExpandable && <ExpandButton onClick={toggleExpanded} isExpanded={isExpanded} className={cn('expand')} />}
    </div>
  );
};

export default memo(HeaderBase);
