import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { DRAGGABLE_TYPES } from '@/helpers';
import { getComponentDurationNewFlow } from '@/helpers/runDesigns/timeline';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import Swiper from '../../Swiper';
import { TSwiperItem } from '../../Swiper/types';
import DndItem from '../../common/DndItem';

import TimelineItem from '../Timeline/TimelineItem';
import TimelineArrow from '../Timeline/TimelineArrow';

import styles from './ComponentsSwiper.module.scss';
import { TTiming } from '../Timeline/TimelineScanTime/types';
import type { TOnDropFn } from '../../common/DndItem/types';

const cn = classnames.bind(styles);

type TComponentsSwiper = {
  className?: string;
};

const ComponentsSwiper: FC<TComponentsSwiper> = ({ className }) => {
  const appDispatch = useAppDispatch();

  const componentList = useSelector(experimentRunDesignSelectors.selectComponentList);

  // don't use useCallback for this function, because react store execute result if we dnd to same place
  const onDrop: TOnDropFn = (dragIndex, dropIndex, { isBefore }) => {
    appDispatch(experimentRunDesignActions.dndComponent({ dragIndex, dropIndex, isBefore }));
  };

  const onChangeSelected = useCallback((newId: string) => {
    appDispatch(experimentRunDesignActions.setCurrentComponentId(newId));
  }, []);

  const componentTimingMap = useMemo(() => {
    const result: Record<string, TTiming> = {};
    let start = 0;
    (componentList ?? []).forEach((el) => {
      const duration = getComponentDurationNewFlow(el);
      result[el.id] = { duration, start };
      start += duration;
    });
    return result;
  }, [componentList]);

  const items: TSwiperItem[] = useMemo(() => {
    const sampleLoadingItem: TSwiperItem = {
      id: 'sampleLoading',
      render: ({ isSelected }) => (
        <TimelineItem
          duration={0}
          name="Sample loading"
          start={0}
          isSelected={isSelected}
          isDraggable={false}
          className={cn('component__timeline-item')}
        />
      ),
    };

    const components: TSwiperItem[] = (componentList ?? []).map((component, index) => ({
      id: component.id,
      className: cn('component__swiper-item'),
      render: ({ isSelected }) => (
        <DndItem
          id={component.id}
          index={index}
          onDrop={onDrop}
          className={cn('component__dnd-item')}
          dragType={DRAGGABLE_TYPES.runDesignComponent}
          render={({ isDragging, isAfter, isBefore }) => (
            <TimelineItem
              duration={componentTimingMap[component.id].duration}
              name={component.name}
              start={componentTimingMap[component.id].start}
              isSelected={isSelected}
              isDraggable
              isDragging={isDragging}
              className={cn('component__timeline-item', { '_drop-after': isAfter, '_drop-before': isBefore })}
            />
          )}
        />
      ),
    }));
    return [sampleLoadingItem, ...components];
  }, [componentList, componentTimingMap, onDrop]);

  return (
    <Swiper
      onChangeSelected={onChangeSelected}
      includeArrows
      items={items}
      renderArrow={({ isNextArrow }) => <TimelineArrow isNext={isNextArrow} />}
      className={cn('components-swiper', className)}
      contentClassName={cn('components-swiper__content')}
      arrowClassName={cn('components-swiper__arrow')}
    />
  );
};

export default memo(ComponentsSwiper);
