import { FC, memo, ReactNode, useCallback } from 'react';
import classnames from 'classnames/bind';

import styles from './Tabs.module.scss';

const cn = classnames.bind(styles);

export type TTabItemProps = {
  id: string;
  className?: string;
  render: (props: { isSelected: boolean; disabled: boolean; handleSelect: () => void }) => ReactNode;
  onSelect: (id: string) => void;
  isSelected: boolean;
  disabled?: boolean;
};

const TabItem: FC<TTabItemProps> = ({ id, render, className, isSelected, onSelect, disabled }) => {
  const handleSelect = useCallback(() => {
    if (disabled) {
      return;
    }
    onSelect(id);
  }, [id, onSelect, disabled]);

  return (
    <button
      className={cn('tab-item', { _selected: isSelected, _disabled: disabled }, className)}
      onClick={handleSelect}
      disabled={disabled}
    >
      {render({ isSelected, handleSelect, disabled: !!disabled })}
    </button>
  );
};

export default memo(TabItem);
