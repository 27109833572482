import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import LanesBlockNew from '@/components/runDesign/LanesBlock/LanesBlockNew';
import LaneSamplesBlock from '@/components/runDesign/LanesBlock/LaneSamplesBlock';

import FlowcellTypeSelect from '../../../components/FlowcellTypeSelect';
import FlowCellSetupLanes from '../FlowCellSetupLanes';

import styles from './FlowCellSettings.module.scss';

const cn = classnames.bind(styles);

const FlowCellSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const lanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);

  const onChangeLaneActive = useCallback((laneLetter: string, isActive: boolean) => {
    if (isActive) {
      appDispatch(experimentRunDesignActions.removeLane(laneLetter));
      return;
    }
    appDispatch(experimentRunDesignActions.addNewLane(laneLetter));
  }, []);

  const onChangeLaneName = useCallback((laneLetter: string, sampleName: string) => {
    appDispatch(experimentRunDesignActions.updateLane({ laneId: laneLetter, updatedData: { sample: sampleName } }));
  }, []);

  return (
    <div className={cn('flow-cell-settings')}>
      <div className={cn('flow-cell-settings__selects')}>
        <FlowcellTypeSelect wrapperClassName={cn('flow-cell-settings__select-type')} />
      </div>
      <div className={cn('flow-cell-settings__lanes-container')}>
        <FlowCellSetupLanes />
        <div className={cn('flow-cell-settings__samples-container')}>
          <LanesBlockNew isEditable lanes={lanes} onChangeLaneActive={onChangeLaneActive} />
          <LaneSamplesBlock
            isEditable
            lanes={lanes}
            onChangeActive={onChangeLaneActive}
            onChangeSampleName={onChangeLaneName}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FlowCellSettings);
