export const getSideGridColumn = (list: boolean[], blockIndex: number): string => {
  // Find the index of the next showing side panel after the blockIndex
  const nextShowingSidePanel = list.findIndex((value, index) => index > blockIndex && value);

  // Calculate the span count
  let spanCount = nextShowingSidePanel - blockIndex;

  // If no found some showing side panel, span to the end of the list
  if (spanCount < 1) {
    spanCount = list.length - blockIndex;
  }

  return `span ${spanCount}`;
};

type TGetHeaderBaseColumnProps = { expandableCount: number; isExpanded: boolean };

export const getHeaderBaseColumn = ({ expandableCount, isExpanded }: TGetHeaderBaseColumnProps): string => {
  // init value of span content, default = 1
  let columnCount = 1;

  // also, if this block expandable,
  // need add all count of expandable block minus one
  // minus one - because current block we already take it from default value
  if (isExpanded) {
    columnCount += expandableCount - 1;
  }
  return `span ${columnCount}`;
};

type TGetContentBaseColumnProps = {
  sideList: boolean[];
  expandableCount: number;
  isExpanded: boolean;
  isLast: boolean;
};

export const getContentBaseColumn = ({
  expandableCount,
  isExpanded,
  sideList,
  isLast,
}: TGetContentBaseColumnProps): string => {
  // init value of span content, default = 1
  let columnCount = 1;

  // if it is last or expanded block, need add count of all sides,
  // witch hidden at start to first opened or all
  if (isLast || isExpanded) {
    let sideIndex = sideList.findIndex(Boolean);

    // if not contain some opening side block, need add all blocks to base block
    if (sideIndex === -1) {
      sideIndex = sideList.length;
    }
    columnCount += sideIndex;
  }

  // also, if this block expandable,
  // need add all count of expandable block minus one
  // minus one - because current block we already take it from default value
  if (isExpanded) {
    columnCount += expandableCount - 1;
  }
  return `span ${columnCount}`;
};
