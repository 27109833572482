import { FC } from 'react';

import { TemperatureUnit } from '@/graphql/API';

import { TRenderSideComponentProps } from '@/components/runDesign/PanelWithFolding/types';
import WashBlock from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/WashBlock';

const washMockBlockProps = {
  durationHour: {
    value: 2,
    onChange: (_value: number) => null,
  },
  durationMinute: {
    value: 2,
    onChange: (_value: number) => null,
  },
  temperature: {
    value: 2,
    onChange: (_value: number) => null,
  },
  temperatureUnit: {
    value: TemperatureUnit.C,
    onChange: (_value: TemperatureUnit) => null,
  },
  washesTotal: {
    value: 2,
    onChange: (_value: number) => null,
  },
};

const WashSideBlockContent: FC<TRenderSideComponentProps> = () => <WashBlock {...washMockBlockProps} />;

export default WashSideBlockContent;
