import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';
import { isSomeObjValueDiff, removeDuplicates } from '@/helpers';
import { CCEType } from '@/graphql/API';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TCagingSettingNumberField,
} from '@/store/slices/experimentRunDesign';

import Select from '@/components/common/Select';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import Button from '@/components/common/Button';
import { ADVANCED_SETTINGS } from '@/pages/experiment-run-design/CagingSettings/constants';
import { cceTypeOptions } from '@/pages/experiment-run-design/CagingSettings/helpers';

import AdvancedSettings from './components/AdvancedSettings';
import ReagentSelector from './components/ReagentSelector/ReagentSelector';

import styles from './EditFields.module.scss';

const cn = classnames.bind(styles);

type TEditFields = {
  laneId: string;
  rowIndex: number;
};

const EditFields: FC<TEditFields> = ({ laneId, rowIndex }) => {
  const appDispatch = useAppDispatch();

  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const currentLaneData = useSelector(experimentRunDesignSelectors.selectCurrentLaneById(laneId));
  const laneCagingSettings = useSelector(experimentRunDesignSelectors.selectLaneCagingSettings(laneId));
  const laneCagingSettingsErrors = useSelector(experimentRunDesignSelectors.selectLaneCagingSettingsErrors(laneId));
  const isAdvancedMode = useSelector(experimentRunDesignSelectors.selectIsAdvancedMode);
  const isSomeSubtractiveCCE = useSelector(experimentRunDesignSelectors.selectISomeSubtractiveCCE);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      appDispatch(experimentRunDesignActions.updateLaneCagingSettingsNumberField({ field, laneId, value }));
    },
    [laneId]
  );

  const handleResetSettings = useCallback(() => {
    appDispatch(experimentRunDesignActions.resetLaneCagingSettings({ laneId }));
  }, [laneId]);

  const isChanged = useMemo(
    () => isSomeObjValueDiff(globalCagingSettings ?? {}, laneCagingSettings?.overrideSettings ?? {}),
    [globalCagingSettings, laneCagingSettings]
  );

  const cellNameOptions = useMemo<TBasicOption[]>(() => {
    const namesCollection: TBasicOption[] = [];

    currentLaneData?.cellTypes?.forEach((cellType) => {
      if (cellType?.cellIndex?.name) {
        namesCollection.push({
          label: cellType.cellIndex.name,
          value: cellType.cellIndex.id,
          customData: cellType.cellIndex,
        });
      }
    });

    return removeDuplicates(namesCollection, 'value');
  }, [currentLaneData]);

  const handleChangeCellName = useCallback(
    (cellIndexId: string, type: 'cellToCage' | 'cellToSubtract') => {
      appDispatch(
        experimentRunDesignActions.updateLaneCagingSettingsCellName({
          cellIndexId,
          laneId,
          type,
        })
      );
    },
    [laneId]
  );

  const handleChangeCCEType = useCallback(
    (cceType: CCEType) => {
      appDispatch(experimentRunDesignActions.updateLaneCagingSettingsCCEType({ cceType, laneId }));
    },
    [laneId]
  );

  const handleChangeCellToCageCellName = (value: string) => handleChangeCellName(value, 'cellToCage');
  const handleChangeCellToSubtractCellName = (value: string) => handleChangeCellName(value, 'cellToSubtract');
  const isLaneWithSubtractiveCCE = laneCagingSettings?.cceType === CCEType.SUBTRACTIVE_CCE;

  if (!laneCagingSettings) {
    return <RunDesignTable.Row key={laneId} />;
  }

  return (
    <RunDesignTable.Row key={laneId}>
      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ columnIndex: 1, rowIndex, dataType: 'cellToCage' })}
      >
        <Select
          value={laneCagingSettings.cceType}
          options={cceTypeOptions}
          theme={themeOptions.light}
          onChange={handleChangeCCEType}
          className={cn('select')}
          controlClassName={cn('select__control')}
          menuClassName={cn('select__menu')}
          menuListClassName={cn('select__menu-list')}
          optionClassName={cn('select__option')}
          customComponents={{ Option: Select.DefaultOption }}
          isPortalable
        />
      </RunDesignTable.Column>

      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ columnIndex: 1, rowIndex, dataType: 'cellToCage' })}
      >
        <Select
          value={laneCagingSettings?.cellToCage?.cellIndex?.id}
          options={cellNameOptions}
          theme={themeOptions.light}
          onChange={handleChangeCellToCageCellName}
          className={cn('select', 'cell-name-select')}
          controlClassName={cn('select__control')}
          menuClassName={cn('select__menu')}
          menuListClassName={cn('select__menu-list')}
          optionClassName={cn('select__option')}
          customComponents={{ Option: Select.DefaultOption }}
          isPortalable
        />
      </RunDesignTable.Column>
      <RunDesignTable.Column
        className={cn(SELECTABLE_CLASS_NAME, {
          'selected-field': !isSomeSubtractiveCCE,
        })}
        {...addSelectableAttribute({ columnIndex: 3, rowIndex, dataType: 'cellToCage' })}
      >
        <ReagentSelector laneCagingSettings={laneCagingSettings} type="cellToCage" />
      </RunDesignTable.Column>

      {isSomeSubtractiveCCE && (
        <>
          <RunDesignTable.Column
            className={cn(SELECTABLE_CLASS_NAME)}
            {...addSelectableAttribute({ columnIndex: 4, rowIndex, dataType: 'cellToCage' })}
          >
            {isLaneWithSubtractiveCCE && (
              <Select
                value={laneCagingSettings?.cellToSubtract?.cellIndex?.id}
                options={cellNameOptions}
                theme={themeOptions.light}
                onChange={handleChangeCellToSubtractCellName}
                className={cn('select', 'cell-name-select')}
                controlClassName={cn('select__control')}
                menuClassName={cn('select__menu')}
                menuListClassName={cn('select__menu-list')}
                optionClassName={cn('select__option')}
                customComponents={{ Option: Select.DefaultOption }}
                isPortalable
              />
            )}
          </RunDesignTable.Column>
          <RunDesignTable.Column
            className={cn(
              {
                'selected-field': isLaneWithSubtractiveCCE,
              },
              SELECTABLE_CLASS_NAME
            )}
            {...addSelectableAttribute({ columnIndex: 6, rowIndex, dataType: 'cellToSubtract' })}
          >
            {isLaneWithSubtractiveCCE && (
              <ReagentSelector laneCagingSettings={laneCagingSettings} type="cellToSubtract" />
            )}
          </RunDesignTable.Column>
        </>
      )}

      {isAdvancedMode && (
        <AdvancedSettings
          laneCagingSettings={laneCagingSettings}
          laneCagingSettingsErrors={laneCagingSettingsErrors}
          onChangeNumberField={handleChangeNumberField}
          globalCagingSettings={globalCagingSettings}
          advancedSettingsList={ADVANCED_SETTINGS}
          {...addSelectableAttribute({ columnIndex: 7, rowIndex, dataType: 'advancedSettings' })}
        />
      )}
      <RunDesignTable.Column>
        <div
          className={cn('selected-field', 'selected-field_no-padding', 'selected-field_from-start')}
          {...addSelectableAttribute({ columnIndex: 0, rowIndex, dataType: 'resetToDefault' })}
        >
          <Button
            onClick={handleResetSettings}
            type="button"
            color="light"
            disabled={!isChanged}
            className={cn('selected-field__reset-btn', { 'selected-field__reset-btn_disabled': !isChanged })}
          >
            Reset
          </Button>
        </div>
      </RunDesignTable.Column>
    </RunDesignTable.Row>
  );
};

export default memo(EditFields);
