import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './PanelWithFolding.module.scss';
import { TPanelWithFoldingProps } from './types';

import Title from './components/Title';
import Header from './components/Header';
import Content from './components/Content';
import { PanelWithFoldingContext, TPanelWithFoldingContext } from './context';

const cn = classnames.bind(styles);

type TExtension = {
  Title: typeof Title;
};

const PanelWithFolding: FC<TPanelWithFoldingProps> & TExtension = ({ className, ...props }) => {
  const [expandedId, setExpandedId] = useState<Nullable<string>>(null);

  // State to manage the grid template columns for the layout
  const [gridTemplateColumns, setGridTemplateColumns] = useState('');

  // Filtering renderSideList and renderBaseList by hidden props
  const renderSideList = useMemo(
    () => (props.renderSideList ?? []).filter((el) => !el.isHidden),
    [props.renderSideList]
  );
  const renderBaseList = useMemo(
    () => (props.renderBaseList ?? []).filter((el) => !el.isHidden),
    [props.renderBaseList]
  );

  // State to manage the open/closed status of side panels.
  // * IMPORTANT use a string as key, because a number, because object in js will be sort number to top
  // and it will be break a logic for showingSideBlocks array
  const [sideBlocksOpenStatusMap, setSideBlocksOpenStatusMap] = useState<Record<string, boolean>>(
    // Convert defaultIsOpen to a boolean and assign it to the ID in the accumulator
    renderSideList.reduce((acc: Record<string, boolean>, el) => {
      acc[el.id] = !!el.defaultIsOpen;
      return acc;
    }, {}) ?? {}
  );

  /**
   * Toggles the visibility of a side block.
   * usage value instead !prevValue because it is incorrect work with React.StrictMode in dev environment
   */
  const toggleSide: TPanelWithFoldingContext['toggleSide'] = useCallback((key: string, value: boolean) => {
    setSideBlocksOpenStatusMap((prev) => {
      prev[key] = value;
      return { ...prev };
    });
  }, []);

  const collapseAllSideBlocks: TPanelWithFoldingContext['collapseAllSideBlocks'] = useCallback(() => {
    setSideBlocksOpenStatusMap((prev) =>
      Object.keys(prev).reduce((acc: Record<string, boolean>, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  }, []);

  /**
   * Changes the currently expanded section ID.
   * also hide all sideBlocks
   */
  const changeExpandedId: TPanelWithFoldingContext['changeExpandedId'] = useCallback((newValue: Nullable<string>) => {
    setExpandedId((prev) => {
      if (prev === newValue) {
        return null;
      }
      collapseAllSideBlocks();
      return newValue;
    });
  }, []);

  // Effect to update the grid template columns when side blocks are toggled or something render list will be changed
  useEffect(() => {
    const headerSizeList: string[] = [];
    renderBaseList.forEach((el) => {
      headerSizeList.push(el.width);
    });
    renderSideList.forEach((el) => {
      headerSizeList.push(sideBlocksOpenStatusMap[el.id] ? el.width : el.collapsedWidth);
    });

    setGridTemplateColumns(headerSizeList.filter(Boolean).join(' '));
  }, [sideBlocksOpenStatusMap, renderBaseList, renderSideList]);

  // Effect to initialize the grid template columns and side block states
  useEffect(() => {
    const contentSizeList: string[] = [];
    const headerSizeList: string[] = [];

    // Initialize base list widths and side block states
    renderBaseList.forEach((el) => {
      contentSizeList.push(el.width);
      headerSizeList.push(el.width);
    });
    renderSideList.forEach((el) => {
      contentSizeList.push(el.width);
      headerSizeList.push(el.defaultIsOpen ? el.width : el.collapsedWidth);
    });

    // Set the initial grid template columns and side block states
    setGridTemplateColumns(headerSizeList.join(' '));
  }, []);

  // Memoized values for counts of base blocks, side blocks, and expandable blocks
  const baseBlocksCount = useMemo(() => renderBaseList.length ?? 0, [renderBaseList.length]);
  const sideBlocksCount = useMemo(() => renderSideList.length ?? 0, [renderSideList.length]);
  const expandableCount = useMemo(
    () => renderBaseList.filter((el) => el.isExpandable).length ?? 0,
    [renderBaseList.length]
  );

  // Memoized value to track which side blocks are currently visible
  const showingSideBlocks = useMemo(() => Object.values(sideBlocksOpenStatusMap), [sideBlocksOpenStatusMap]);

  const contextValue: TPanelWithFoldingContext = useMemo(
    () => ({
      changeExpandedId,
      toggleSide,
      collapseAllSideBlocks,
      expandedId,
      sideBlocksOpenStatusMap,
      showingSideBlocks,
      baseBlocksCount,
      sideBlocksCount,
      expandableCount,
    }),
    [
      changeExpandedId,
      toggleSide,
      collapseAllSideBlocks,
      expandedId,
      sideBlocksOpenStatusMap,
      showingSideBlocks,
      baseBlocksCount,
      sideBlocksCount,
      expandableCount,
    ]
  );

  return (
    <PanelWithFoldingContext.Provider value={contextValue}>
      <div className={cn('panel', className)}>
        <Header renderBaseList={renderBaseList} renderSideList={renderSideList} gridColumns={gridTemplateColumns} />
        <Content renderBaseList={renderBaseList} renderSideList={renderSideList} gridColumns={gridTemplateColumns} />
      </div>
    </PanelWithFoldingContext.Provider>
  );
};

PanelWithFolding.Title = Title;

export default PanelWithFolding;
