import { FC, memo, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import LanesPlate from '@/components/runDesign/LanesPlate';

import LanesTotal from '../../../components/LanesTotal';

import styles from './FlowCellSetupLanes.module.scss';

const cn = classnames.bind(styles);

const FlowCellSetupLanes: FC = () => {
  const appDispatch = useAppDispatch();
  const lanesByLetter = useSelector(experimentRunDesignSelectors.selectCurrentLaneLetterMap);

  const lanesMap = useMemo(
    () =>
      Object.keys(lanesByLetter).reduce((acc: Record<string, string>, letter) => {
        acc[letter] = lanesByLetter[letter].id;
        return acc;
      }, {}),
    [lanesByLetter]
  );

  const onLaneClick = useCallback((laneId: string, isSelected: boolean) => {
    if (isSelected) {
      appDispatch(experimentRunDesignActions.removeLane(laneId));
    } else {
      appDispatch(experimentRunDesignActions.addNewLane(laneId));
    }
  }, []);

  return (
    <div className={cn('flow-cell-setup-lanes')}>
      <LanesTotal isChangeAllowed />
      <LanesPlate lanesMap={lanesMap} onLaneClick={onLaneClick} />
    </div>
  );
};

export default memo(FlowCellSetupLanes);
