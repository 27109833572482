import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import styles from './CellTypes.module.scss';
import CellTypeCircle from './CellTypeCircle';

const cn = classnames.bind(styles);

type TCellTypes = {
  className?: string;
  existCellTypes: boolean[];
};

// TODO: NEWFLOW remove in new flow
const CellTypes: FC<TCellTypes> = ({ existCellTypes, className }) => (
  <div className={cn('cell-types', className)}>
    {existCellTypes?.map((isCellTypeExist, index) => {
      const key = index;
      return <CellTypeCircle key={key} isActive={isCellTypeExist} />;
    })}
  </div>
);

export default memo(CellTypes);
