import { FC, memo, useCallback, useState, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';

import { getTimeUnits } from '@/helpers/time';
import updateTimeValue from '@/helpers/runDesigns/components/updateTimeValue';

import TimeInput, { ETimeInputUnit } from '@/components/common/TimeInput';
import { themeOptions } from '@/types/theme';

import styles from './IncubationBaseBlock.module.scss';

const cn = classnames.bind(styles);

type TDurationProps = {
  initDuration: number; // in seconds
  onChange: (value: number) => void;
};

const Duration: FC<TDurationProps> = ({ initDuration, onChange }) => {
  const [duration, setDuration] = useState<number>(initDuration);
  const { hours, minutes } = useMemo(() => getTimeUnits(duration), [duration]);

  useEffect(() => {
    onChange(duration);
  }, [duration]);

  useEffect(() => {
    setDuration(initDuration);
  }, [initDuration]);

  const onChangeDurationInHour = useCallback((newValue: number) => {
    setDuration((prevDuration) => updateTimeValue(prevDuration, { unit: ETimeInputUnit.hour, value: newValue }));
  }, []);

  const onChangeDurationInMinute = useCallback((newValue: number) => {
    setDuration((prevDuration) => updateTimeValue(prevDuration, { unit: ETimeInputUnit.minute, value: newValue }));
  }, []);

  return (
    <div className={cn('group', 'group_time')}>
      <div className={cn('group__title')}>Duration</div>
      <div className={cn('group__inputs')}>
        <TimeInput
          theme={themeOptions.light}
          onChange={onChangeDurationInHour}
          value={hours}
          unit={ETimeInputUnit.hour}
          className={cn('group__input-wrap')}
          inputClassName={cn('group__input')}
        />
        <TimeInput
          theme={themeOptions.light}
          onChange={onChangeDurationInMinute}
          value={minutes}
          unit={ETimeInputUnit.minute}
          className={cn('group__input-wrap')}
          inputClassName={cn('group__input')}
        />
      </div>
    </div>
  );
};

export default memo(Duration);
