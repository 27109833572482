import { FC, CSSProperties } from 'react';
import classnames from 'classnames/bind';

import { THeaderProps } from '../../types';

import styles from './Header.module.scss';
import HeaderBase from './HeaderBase';
import HeaderSideItem from './HeaderSide';

const cn = classnames.bind(styles);

const Header: FC<THeaderProps> = ({ className, renderBaseList, renderSideList, gridColumns }) => (
  <div className={cn('header', className)} style={{ '--grid-template-columns': gridColumns } as CSSProperties}>
    {gridColumns && renderBaseList?.map((props, index) => <HeaderBase key={props.id} blockIndex={index} {...props} />)}
    {gridColumns &&
      renderSideList?.map((props, index) => <HeaderSideItem key={props.id} blockIndex={index} {...props} />)}
  </div>
);

export default Header;
