import { FC, memo, useCallback, useMemo } from 'react';

import { EDesignStepNewFlow } from '@/helpers/runDesigns/constants';
import { useRunDesignLocationNewFlow } from '@/hooks/runDesign/useRunDesignLocationNewFlow';
import { TRenderItem } from '@/components/runDesign/FooterControls/Tabs';
import FooterControls from '@/components/runDesign/FooterControls';

const FooterTabs: FC = () => {
  const { goToStep, currentStep } = useRunDesignLocationNewFlow();
  const stepFn = useMemo<Record<string, () => void>>(
    () => ({
      [EDesignStepNewFlow.runInfo]: () => goToStep(EDesignStepNewFlow.runInfo),
      [EDesignStepNewFlow.flowCellSetup]: () => goToStep(EDesignStepNewFlow.flowCellSetup),
      [EDesignStepNewFlow.designWorkflow]: () => goToStep(EDesignStepNewFlow.designWorkflow),
      [EDesignStepNewFlow.runDesign]: () => null,
    }),
    []
  );

  const stepRenderList = useMemo<TRenderItem[]>(
    () => [
      { id: EDesignStepNewFlow.runInfo, render: () => 'Run info' },
      { id: EDesignStepNewFlow.flowCellSetup, render: () => 'Flow cell setup' },
      { id: EDesignStepNewFlow.designWorkflow, render: () => 'Design workflow' },
      { id: EDesignStepNewFlow.runDesign, render: () => 'Run design', disabled: true },
    ],
    []
  );

  const handleChangeTabFactory = useCallback(
    (newTab: string) => () => {
      stepFn[newTab]?.();
    },
    [stepFn, currentStep]
  );

  return (
    <FooterControls.Tabs
      initSelectedIdTab={EDesignStepNewFlow.runInfo}
      renderList={stepRenderList}
      onChange={handleChangeTabFactory}
    />
  );
};

export default memo(FooterTabs);
