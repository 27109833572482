import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RemoveStep from '@/components/runDesign/RemoveStep';
import ComponentHeaderWrapper from '../../../components/ComponentHeaderWrapper';

const SurfaceReceptorHeader: FC = () => {
  const appDispatch = useAppDispatch();
  const componentInfo = useSelector(experimentRunDesignSelectors.selectCurrentComponentInfo);

  const handleRemoveStep = useCallback(() => {
    if (!componentInfo?.id) {
      return;
    }
    appDispatch(experimentRunDesignActions.removeComponent({ id: componentInfo.id }));
  }, [componentInfo?.id]);
  return (
    <ComponentHeaderWrapper title="Surface receptor settings">
      <RemoveStep onRemove={handleRemoveStep} name={componentInfo?.name ?? ''} isRemovable />
    </ComponentHeaderWrapper>
  );
};

export default SurfaceReceptorHeader;
