import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import styles from './TemplateHeader.module.scss';

const cn = classnames.bind(styles);

type TTemplateHeaderProps = {
  className?: string;
  templateName: string;
};

const TemplateHeader: FC<TTemplateHeaderProps> = ({ templateName, className }) => (
  <div className={cn('template-header', className)}>
    Template name {'>'} {templateName}
  </div>
);

export default memo(TemplateHeader);
