import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './FooterButtons.module.scss';

const cn = classnames.bind(styles);

type TGroupProps = {
  className?: string;
  children: ReactNode;
};

const Group: FC<TGroupProps> = ({ children, className }) => (
  <div className={cn('page-controls__group', className)}>{children}</div>
);

export default Group;
