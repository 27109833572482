import { FC, memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import AsyncSelect from 'react-select/async';

import { getNoOptionsMessage } from '@/components/common/Select/helpers';
import selectStyles from '@/components/common/Select/Select.module.scss';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { antnAPI } from '@/store/services/annotation';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import Select from '@/components/common/Select';
import { MultiValue } from 'react-select';
import styles from './FlowcellTypeSelect.module.scss';

const cn = classnames.bind({ ...selectStyles, ...styles });

type TFlowcellTypeSelectProps = {
  controlClassName?: string;
  menuClassName?: string;
  wrapperClassName?: string;
};

const FlowcellTypeSelect: FC<TFlowcellTypeSelectProps> = ({ controlClassName, menuClassName, wrapperClassName }) => {
  const appDispatch = useAppDispatch();

  const currentFlowcellType = useSelector(experimentRunDesignSelectors.selectFlowcellType);
  const [fetchAnnotations] = antnAPI.useLazyFetchAnnotationsQuery();
  const selectedOptionLabel = useRef('');

  const [selectedOption, setSelectedOption] = useState<Nullable<TBasicOption | undefined>>(null);
  const [options, setOptions] = useState<TBasicOption[]>([]);

  const loadOptions = (_inputValue: string, callback: (options: TBasicOption[]) => void) => {
    fetchAnnotations({ type: EAnnotationType.flowcellType }, true)
      .unwrap()
      .then((flowcellTypeList) => {
        const optionList = flowcellTypeList.map(
          (flowcellType): TBasicOption => ({ label: flowcellType.name, value: flowcellType.id })
        );
        setOptions(optionList);
        callback(optionList);
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
        callback([]);
      });
  };

  useEffect(() => {
    const currentOption = options.find((option) => option.value === currentFlowcellType);

    if (currentOption) {
      setSelectedOption(currentOption);
    }
  }, [options]);

  const handleChange = (option: MultiValue<TBasicOption> | Nullable<TBasicOption>) => {
    if (Array.isArray(option)) {
      return;
    }
    const newOption = option as Nullable<TBasicOption>;

    setSelectedOption(newOption);
    appDispatch(
      // here undefined instead of null, because it is optional field in graphql
      experimentRunDesignActions.setEditFields({ flowcellType: newOption?.value ? String(newOption.value) : undefined })
    );
  };

  const noOptionsMessage = (obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>;

  return (
    <div className={cn('flowcell-type-select', wrapperClassName)}>
      <label htmlFor="design_flowcell_type">Flow cell type</label>
      <div className={cn('details__select')}>
        <AsyncSelect
          placeholder="Select flow cell type"
          id="design_flowcell_type"
          classNames={{
            container: ({ isDisabled }) =>
              cn('select', 'select_light', {
                select_disabled: isDisabled,
              }),
            control: () => `${cn('select__control', 'details__select-control', controlClassName)}}`,
            dropdownIndicator: () => cn('select__dropdown-indicator'),
            indicatorSeparator: () => cn('select__indicator-separator'),
            menu: () => `${cn('select__menu', 'select__menu_light', menuClassName)}`,
            menuPortal: () => cn('select__menu-portal'),
            menuList: () => cn('select__menu-list', 'select__menu-list_light', 'details__select-menu-list'),
            option: ({ isFocused, isSelected, isMulti, isDisabled }) =>
              cn('select__option', {
                select__option_multiple: isMulti,
                select__option_focused: isFocused,
                select__option_selected: isSelected,
                select__option_disabled: isDisabled,
                'select__option_light-focus': isFocused,
              }),
            clearIndicator: () => cn('select__clear-indicator'),
            placeholder: () => cn('select__placeholder'),
            singleValue: () => cn('select__single-value'),
          }}
          value={selectedOption}
          onChange={handleChange}
          loadOptions={loadOptions}
          defaultOptions
          noOptionsMessage={noOptionsMessage}
          options={options}
          key={selectedOptionLabel.current}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          isClearable
          cacheOptions
          components={{ Option: Select.DefaultOption }}
          menuPosition="absolute"
        />
      </div>
    </div>
  );
};

export default memo(FlowcellTypeSelect);
