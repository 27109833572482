import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { urlPostfixListNewFlow, EDesignStepNewFlow, TDesignStepNewFlow } from '@/helpers/runDesigns/constants';

export const useRunDesignLocationNewFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentStep = useMemo(() => {
    const currentStepParam = location.pathname.split('/').at(-1);
    return (
      Object.entries(urlPostfixListNewFlow).find(([_, postfix]) => currentStepParam === postfix)?.[0] ??
      EDesignStepNewFlow.designWorkflow
    );
  }, [location]);

  const goToDraftList = useCallback(() => {
    navigate('/run-design?tab=drafts');
  }, []);

  const goToReadyList = useCallback(() => {
    navigate('/run-design?tab=ready');
  }, []);

  const goToTemplateList = useCallback(() => {
    navigate('/run-design');
  }, []);

  const goToStep = useCallback((step: TDesignStepNewFlow) => {
    const currentStepParam = location.pathname.split('/').at(-1);
    const rootPath = location.pathname.split('/').slice(0, 4).join('/');

    if (currentStepParam === urlPostfixListNewFlow[step]) {
      return;
    }

    navigate(`${rootPath}/${urlPostfixListNewFlow[step]}`);
  }, []);

  return {
    goToStep,
    goToReadyList,
    goToDraftList,
    goToTemplateList,
    currentStep,
  };
};
