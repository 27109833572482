import { FC, memo, useCallback, useState } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Popover from '@/components/common/Popover';

import styles from './MenuBurger.module.scss';

const cn = classnames.bind(styles);

type TMenuBurger = {
  className?: string;
};

const MenuBurger: FC<TMenuBurger> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <Popover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClick={handleOpen}
      options={[
        { id: 'remap', title: 'Remap lanes' },
        { id: 'clear', title: 'Clear run data, by lane' },
        { id: 'validate', title: 'Validate run design' },
      ]}
      positions={['bottom']}
      wrapperClassName={cn('popover-wrapper')}
    >
      <button className={cn('menu', { _open: isOpen }, className)} aria-label="menu-burger">
        <icons.HamburgerIcon width={25} height={20} />
      </button>
    </Popover>
  );
};

export default memo(MenuBurger);
