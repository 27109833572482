import { v4 as uuidv4 } from 'uuid';
import { CellKilling, CytokineSecretion, Incubation, mRNA, Placement, ScanComp, SurfaceReceptor } from '@/graphql/API';

import { EComponentTypes, TCreatableComponentTypeNewFlow } from '@/store/slices/experimentRunDesign/types';

import getInitialWash from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getInitialWash';
import getInitialScanConfig from './getInitialScanConfig';
import getInitialIncubation from './getInitialIncubation';

export const getInitialIncubationComponent = (counterInName: number, relativeTo?: string): Incubation => ({
  __typename: EComponentTypes.IncubationComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `Incubation ${counterInName}` : 'Incubation',
  type: EComponentTypes.IncubationComponent,
  performedOnLanes: [],
  performedOnLanesWithMedia: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
});

export const getInitialSurfaceReceptorComponent = (counterInName: number, relativeTo?: string): SurfaceReceptor => ({
  __typename: EComponentTypes.SurfaceReceptorComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `Surface Receptor ${counterInName}` : 'Surface Receptor',
  type: EComponentTypes.SurfaceReceptorComponent,
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialCytokineSecretionComponent = (
  counterInName: number,
  relativeTo?: string
): CytokineSecretion => ({
  __typename: EComponentTypes.CytokineSecretionComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `Cytokine Secretion ${counterInName}` : 'Cytokine Secretion',
  type: EComponentTypes.CytokineSecretionComponent,
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialMRNAComponent = (counterInName: number, relativeTo?: string): mRNA => ({
  __typename: EComponentTypes.mRNAComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `mRNA ${counterInName}` : 'mRNA',
  type: EComponentTypes.mRNAComponent,
  performedOnLanes: [],
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

export const getInitialCellKillingComponent = (counterInName: number, relativeTo?: string): CellKilling => ({
  __typename: EComponentTypes.CellKillingComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `Cell Killing ${counterInName}` : 'Cell Killing',
  type: EComponentTypes.CellKillingComponent,
  performedOnLanes: [],
  scanConfig: getInitialScanConfig(),
  timing: {
    __typename: 'Timing',
    placement: Placement.SIMULTANEOUS,
    relativeTo,
  },
  incubation: getInitialIncubation(),
  wash: getInitialWash(),
});

export const getInitialScanComponent = (counterInName: number, relativeTo?: string): ScanComp => ({
  __typename: EComponentTypes.ScanComponent,
  id: uuidv4(),
  name: counterInName > 1 ? `Scan ${counterInName}` : 'Scan',
  type: EComponentTypes.ScanComponent,
  duration: 90 * 60,
  pauseAfterCompletion: null,
  performedOnLanes: [],
  tags: null,
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

// TODO: NEWFLOW use this instead getInitialScanComponent after approve
export const getInitialScanComponentNewFlow = (counterInName: number, relativeTo?: string): ScanComp => ({
  __typename: 'ScanComp',
  id: uuidv4(),
  name: counterInName > 1 ? `Scan ${counterInName}` : 'Scan',
  type: 'ScanComp',
  duration: 90 * 60,
  pauseAfterCompletion: null,
  performedOnLanes: [],
  tags: null,
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

export const getInitialComponentByType = (
  type: TCreatableComponentTypeNewFlow,
  counterInName: number,
  relativeTo?: string
) => {
  switch (type) {
    case EComponentTypes.ScanComponent: {
      return getInitialScanComponent(counterInName, relativeTo);
    }
    case EComponentTypes.mRNAComponent: {
      return getInitialMRNAComponent(counterInName, relativeTo);
    }
    case EComponentTypes.CytokineSecretionComponent: {
      return getInitialCytokineSecretionComponent(counterInName, relativeTo);
    }
    case EComponentTypes.SurfaceReceptorComponent: {
      return getInitialSurfaceReceptorComponent(counterInName, relativeTo);
    }
    case EComponentTypes.IncubationComponent: {
      return getInitialIncubationComponent(counterInName, relativeTo);
    }
    default: {
      return getInitialScanComponent(counterInName, relativeTo);
    }
  }
};
