import { CSSProperties, FC, memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { getHeaderSectionsForEditMode } from '@/helpers/runDesigns/CagingSettings';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import styles from './EditSettings.module.scss';

import BaseSettingSections from '../BaseSettingSections';
import TableContent from './TableContent';

const cn = classNames.bind(styles);

type TEditSettings = {
  className?: string;
};

const EditSettings: FC<TEditSettings> = ({ className }) => {
  const appDispatch = useAppDispatch();

  const isAdvancedMode = useSelector(experimentRunDesignSelectors.selectIsAdvancedMode);
  const isSomeSubtractiveCCE = useSelector(experimentRunDesignSelectors.selectISomeSubtractiveCCE);
  const isSomeUnsaved = useSelector(experimentRunDesignSelectors.selectIsSomeUnsaved);

  const settingSections = useMemo(
    () => getHeaderSectionsForEditMode(isSomeSubtractiveCCE, isAdvancedMode).slice(1), // need filter magnification
    [isSomeSubtractiveCCE, isAdvancedMode]
  );

  useEffect(() => {
    appDispatch(experimentRunDesignActions.recheckAllCagingSettings());
    return () => {
      appDispatch(experimentRunDesignActions.setIsAdvancedMode(false));
    };
  }, []);

  useEffect(() => {
    appDispatch(experimentRunDesignActions.recheckAllCagingSettings());
  }, [isSomeUnsaved]);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('edit-settings__table', className)}
      headerClassName={cn('edit-settings__header')}
      style={
        {
          '--settings-count': settingSections.length,
        } as CSSProperties
      }
      header={
        <RunDesignTable.Row className={cn('edit-settings__header-row')}>
          <BaseSettingSections sectionsData={settingSections} />
        </RunDesignTable.Row>
      }
    >
      <TableContent />
    </RunDesignTable>
  );
};

export default memo(EditSettings);
