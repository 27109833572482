import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';

import { arrToMapByKeys, LANE_LETTER_NAME_LIST } from '@/helpers';
import { TRunDesignLane } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '../RunDesignTable';

import styles from './LanesBlock.module.scss';
import Name from '../LanesTable/Name';

const cn = classnames.bind(styles);

type TLaneSamplesBlockProps = {
  isEditable?: boolean;
  rowClassName?: string;
  tableClassName?: string;
  lanes: Nullable<TRunDesignLane[]>;
  onChangeActive: (laneLetter: string, isActive: boolean) => void;
  onChangeSampleName: (laneLetter: string, sampleName: string) => void;
};

// TODO: NEWFLOW need to be replaced filename to index and remove old index
const LaneSamplesBlock: FC<TLaneSamplesBlockProps> = ({
  isEditable,
  rowClassName,
  tableClassName,
  lanes,
  onChangeActive,
  onChangeSampleName,
}) => {
  const lanesMap = useMemo(() => arrToMapByKeys(lanes ?? [], 'id'), [lanes]);

  return (
    <RunDesignTable tableData={[]} className={tableClassName}>
      {LANE_LETTER_NAME_LIST.map((laneLetter) => {
        const lane = lanesMap[laneLetter];
        const isSampleNameHidden = !lane && !isEditable;

        return (
          <RunDesignTable.Row key={laneLetter} className={cn('lanes-table__row', rowClassName)}>
            <RunDesignTable.Column tooltipMessage={isSampleNameHidden ? 'Activate lane on Sample loading' : ''}>
              <Name
                className={cn('lanes-table__cell')}
                laneLetter={laneLetter}
                isEditAllowed={isEditable}
                sampleName={lane?.sample ?? ''}
                onChangeActive={onChangeActive}
                onChangeSampleName={onChangeSampleName}
                isActive={!!lane}
                isActivationAllowed
              />
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default memo(LaneSamplesBlock);
