import {
  isComponentWithIncubation,
  isComponentWithScan,
  isComponentWithWash,
  isScanComponent,
} from '@/helpers/runDesigns/typeGuards';
import { getTimeUnits } from '@/helpers/time';

import { Placement, ScanComp } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign/types';

export const getScanOrdersMap = (componentList: TRunDesignComponent[]) => {
  let currentOrder = 1;
  const map: Record<string, number> = {};
  componentList.forEach((component) => {
    if (isComponentWithScan(component) && component.scanConfig) {
      const numberOfScans = component.scanConfig.numberOfScans ?? 0;

      if (numberOfScans) {
        map[component.id] = currentOrder;
        currentOrder += numberOfScans;
      }
    }
  });
  return map;
};

export const getScanLabelsMap = (componentList: TRunDesignComponent[]) => {
  let currentOrder = 0;
  const map: Record<string, string> = {};
  componentList.forEach((component) => {
    if (isComponentWithScan(component)) {
      const numberOfScans = component.scanConfig?.numberOfScans ?? 0;

      if (!numberOfScans) {
        map[component.id] = '';
      } else {
        map[component.id] =
          numberOfScans === 1
            ? `Scan ${currentOrder + 1}`
            : `Scans ${currentOrder + 1}-${currentOrder + numberOfScans}`;
        currentOrder += numberOfScans;
      }
    }
  });
  return map;
};

export const getComponentPosition = (
  componentList: TRunDesignComponent[],
  component: TRunDesignComponent,
  row: number
) => {
  let column = 0; // invalid css value of "grid-column-start" property to place items in "auto" columns
  let columnEnd: number | undefined;
  const relativeToIndex = componentList.findIndex(
    (firstRowComponent) => firstRowComponent.id === component.timing.relativeTo
  );

  const placementActions = {
    [Placement.START]: () => {
      column = 1;
    },
    [Placement.END]: () => {
      column = componentList.length;
    },
    [Placement.SIMULTANEOUS]: () => {
      if (relativeToIndex < 0) {
        return;
      }
      column = relativeToIndex + 1;
      columnEnd = componentList.length + 1;
    },
  };

  if (component.timing.placement in placementActions) {
    placementActions[component.timing.placement as keyof typeof placementActions]();
  }

  return {
    row,
    column,
    columnEnd,
  };
};

export const getComponentDuration = (component: TRunDesignComponent, scanComponentList: ScanComp[] = []) => {
  let duration = component.duration ?? 0;
  if (isComponentWithIncubation(component)) {
    duration += component.incubation?.duration ?? 0;
  }
  if (isComponentWithWash(component)) {
    duration += component.wash?.duration ?? 0;
  }

  if (isComponentWithScan(component)) {
    const scanListDuration = component.scanConfig?.scanIds.reduce((scanDurationAcc, scanId) => {
      const scanDuration = scanComponentList.find(({ id }) => id === scanId)?.duration ?? 0;

      return scanDurationAcc + scanDuration;
    }, 0);

    duration += scanListDuration ?? 0;
  }

  return duration;
};

// TODO: NEWFLOW need use this method and remove getComponentDuration, here remove scan logic, because scan will be individual component
export const getComponentDurationNewFlow = (component: TRunDesignComponent) => {
  let duration = component.duration ?? 0;
  if (isComponentWithIncubation(component)) {
    duration += component.incubation?.duration ?? 0;
  }
  if (isComponentWithWash(component)) {
    duration += component.wash?.duration ?? 0;
  }

  return duration;
};

export const getScanDuration = (component: TRunDesignComponent, componentList: Nullable<TRunDesignComponent[]>) => {
  if (!isComponentWithScan(component) || !componentList) {
    return 0;
  }

  let duration = 0;
  component.scanConfig?.scanIds?.forEach((scanId) => {
    const scan = componentList.find((scanComponent) => isScanComponent(scanComponent) && scanComponent.id === scanId);
    if (scan?.duration) {
      duration += scan.duration;
    }
  });
  return duration;
};

export const formatDurationText = ({ duration, comment = '' }: { duration: number; comment?: string }) => {
  const { hours, minutes, seconds } = getTimeUnits(duration);

  const durationHourPart = `${hours}h`;
  const durationMinutePart = minutes ? ` ${minutes}m` : '';
  const durationSecondPart = seconds ? ` ${seconds}s` : '';
  const commentPart = comment ? ` (${comment})` : '';

  return `${durationHourPart}${durationMinutePart}${durationSecondPart}${commentPart}`;
};

export const getTimeText = (timeInSeconds: number) => {
  const { hours, minutes } = getTimeUnits(timeInSeconds);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
