import { FC, LegacyRef, memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';

import styles from './Swiper.module.scss';
import SwiperItem from './SwiperItem';
import { getItemId } from './helpers';
import { TSwiperItem } from './types';

const cn = classnames.bind(styles);

type TSwiperProps = {
  items: TSwiperItem[];
  defaultItemId?: Nullable<string>;
  includeArrows?: boolean;
  className?: string;
  contentClassName?: string;
  arrowClassName?: string;
  renderArrow?: (props: { isNextArrow: boolean; onClick: () => void }) => ReactNode;
  onChangeSelected?: (newId: string) => void;
  innerRef?: LegacyRef<HTMLDivElement>;
};

const Swiper: FC<TSwiperProps> = ({
  items,
  defaultItemId,
  includeArrows,
  renderArrow,
  className,
  contentClassName,
  arrowClassName,
  onChangeSelected,
  innerRef,
}) => {
  const swiperId = useMemo(() => `swiper-${uuidv4()}`, []);
  const contentRef = useRef<HTMLDivElement>(null);

  const [selectedItemId, setSelectedItemId] = useState<string | undefined | null>(defaultItemId);

  useEffect(() => {
    if (!selectedItemId) {
      return;
    }
    onChangeSelected?.(selectedItemId);
  }, [selectedItemId]);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    const listener = (evt: WheelEvent) => {
      evt.preventDefault();
      if (contentRef.current) {
        contentRef.current.scrollLeft += evt.deltaY;
      }
    };
    contentRef.current.addEventListener('wheel', listener);

    return () => {
      if (!contentRef.current) {
        return;
      }

      contentRef.current.removeEventListener('wheel', listener);
    };
  }, [contentRef.current]);

  const itemsTreeMap = useMemo(() => {
    const treeMap: Record<string, { prev: Nullable<string>; next: Nullable<string> }> = {};
    items.forEach((el, index) => {
      treeMap[el.id] = {
        next: items[index + 1]?.id ?? null,
        prev: items[index - 1]?.id ?? null,
      };
    });

    return treeMap;
  }, [items]);

  const handleSelectItem = useCallback((newId: string) => {
    setSelectedItemId(newId);
  }, []);

  const handlePrevArrowClick = useCallback(() => {
    setSelectedItemId((prevValue) => {
      if (!prevValue) {
        return prevValue;
      }
      const newId = itemsTreeMap[prevValue]?.prev ?? prevValue;
      contentRef.current?.querySelector(`#${getItemId(swiperId, newId)}`)?.scrollIntoView({ behavior: 'smooth' });
      return newId;
    });
  }, [itemsTreeMap, swiperId]);

  const handleNextArrowClick = useCallback(() => {
    setSelectedItemId((prevValue) => {
      if (!prevValue) {
        return prevValue;
      }

      const newId = itemsTreeMap[prevValue]?.next ?? prevValue;
      contentRef.current?.querySelector(`#${getItemId(swiperId, newId)}`)?.scrollIntoView({ behavior: 'smooth' });
      return newId;
    });
  }, [itemsTreeMap, swiperId]);

  const isShowArrow = !!(renderArrow && includeArrows);

  return (
    <div className={cn('swiper', className)} ref={innerRef}>
      {isShowArrow && (
        <button className={cn('swiper__btn', arrowClassName)} onClick={handlePrevArrowClick}>
          {renderArrow?.({ isNextArrow: false, onClick: handlePrevArrowClick })}
        </button>
      )}
      <div className={cn('swiper__content', contentClassName)} ref={contentRef}>
        {items.map((item) => (
          <SwiperItem
            key={item.id}
            item={item}
            isSelected={item.id === selectedItemId}
            handleSelectItem={handleSelectItem}
            swiperId={swiperId}
          />
        ))}
      </div>
      {isShowArrow && (
        <button className={cn('swiper__btn', arrowClassName)} onClick={handleNextArrowClick}>
          {renderArrow?.({ isNextArrow: true, onClick: handleNextArrowClick })}
        </button>
      )}
    </div>
  );
};

export default memo(Swiper);
