import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { themeOptions } from '@/types/theme';

import { formatDate } from '@/helpers';
import { showErrorToast } from '@/helpers/errors';

import { useFetchNextToken } from '@/hooks';
import useItemList from '@/hooks/useItemList';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { authAPI } from '@/store/services/auth';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import TextInput from '@/components/common/TextInput';
import Textarea from '@/components/common/Textarea';
import ProjectSelect from '@/components/admin/ProjectSelect';
import PagePanel from '@/components/common/PagePanel';
import Metadata from '@/components/common/Metadata';
import icons from '@/components/common/icons';

import { usePageControlsConfig } from '@/hooks/runDesign/usePageControlsConfig';
import { useEditDesignData } from '../hooks/useEditDesignData';

import ExternalLinkItem from './components/ExternalLinkItem';
import OrganismItem from './components/OrganismItem';

import designExperimentStyles from '../DesignSelector/DesignSelector.module.scss';
import styles from './DesignDetails.module.scss';
import FlowcellTypeSelect from './components/FlowcellTypeSelect';
import DetailsSelectWithLabel from './components/DetailsSelectWithLabel';

const cn = classnames.bind({ ...designExperimentStyles, ...styles });

const DesignDetails = () => {
  const appDispatch = useAppDispatch();
  const { user: currentUser } = useAuthenticator((context) => [context.user]);

  const { isLoading, isError } = useEditDesignData();

  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });
  const userOptionList = useMemo(
    () => userList.map((user) => ({ label: user.displayName, value: user.username })),
    [userList]
  );

  const [investigatorName, setInvestigatorName] = useState('');
  useEffect(() => {
    const selectedInvestigator = userOptionList.find((user) => user.value === currentEditFields.investigatorId);
    setInvestigatorName(selectedInvestigator?.label ?? '');
  }, [userOptionList, currentEditFields.investigatorId]);

  const {
    list: organismList,
    addEmptyItem: addOrganism,
    changeItem: changeOrganism,
    deleteItem: deleteOrganism,
  } = useItemList<string>(
    currentEditFields.organisms ?? [],
    (valueList: string[]) => {
      appDispatch(experimentRunDesignActions.setEditFields({ organisms: valueList }));
    },
    '',
    (value) => !!value.trim()
  );
  const {
    list: externalLinkList,
    addEmptyItem: addExternalLink,
    changeItem: changeExternalLink,
    deleteItem: deleteExternalLink,
  } = useItemList<string>(
    currentEditFields.externalLinks ?? [],
    (valueList: string[]) => {
      appDispatch(experimentRunDesignActions.setEditFields({ externalLinks: valueList }));
    },
    '',
    (value) => !!value.trim()
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const { pageControlsConfig, saveRunDesignAndExit } = usePageControlsConfig(setErrors);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveRunDesignAndExit();
  };

  const deleteFieldError = (fieldName: string) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fieldName];
      return newErrors;
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    appDispatch(experimentRunDesignActions.setEditFields({ name: event.target.value }));
    deleteFieldError('name');
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    appDispatch(experimentRunDesignActions.setEditFields({ description: event.target.value }));
  };

  const handleInvestigatorChange = (option: TBasicOption) => {
    const { value } = option;
    const investigatorId = value.toString();
    appDispatch(experimentRunDesignActions.setEditFields({ investigatorId }));
    deleteFieldError('investigatorId');
  };

  const handleProjectIdChange = (newProjectId: string) => {
    appDispatch(experimentRunDesignActions.setEditFields({ projectId: newProjectId ?? '' }));
    deleteFieldError('projectId');
  };

  useEffect(() => {
    const errorFieldList = Object.keys(errors).filter(
      (field) => !['name', 'investigatorId', 'projectId'].includes(field)
    );
    if (errorFieldList.length > 0) {
      showErrorToast(`The following fields have incorrect values: ${errorFieldList.join(', ')}`);
    }
  }, [errors]);

  const templateHeaderTitle = useMemo(
    () => (designData.templateName ? `Template > ${designData.templateName}` : 'Template'),
    [designData.templateName]
  );

  const currentDate = useMemo(
    () =>
      new Date()
        .toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })
        .replaceAll('/', '-'),
    []
  );

  return (
    <PagePanel
      title={templateHeaderTitle}
      isLoading={isLoading}
      className={cn('design-experiment', 'details')}
      config={pageControlsConfig}
    >
      <>
        <div className={cn('design-experiment__header')}>Run information</div>
        <Metadata className={cn('details__metadata')}>
          <Metadata.Item
            titleClassName={cn('details__metadata-item_title')}
            className={cn('details__metadata-item')}
            title="Created by"
            description={currentUser?.attributes?.name ?? ''}
          />
          <div className={cn('details__metadata-divider')} />
          <Metadata.Item
            titleClassName={cn('details__metadata-item_title')}
            className={cn('details__metadata-item')}
            title="Date"
            description={currentDate}
          />
        </Metadata>
      </>

      {!isLoading && !isError && (
        <form onSubmit={handleFormSubmit} className={cn('design-experiment__content')}>
          <div className={cn('details__panel_design-name')}>
            <div className={cn('details__group')}>
              <label htmlFor="design_name">
                Run name <span className={cn('required')}>*</span>
              </label>
              <TextInput
                id="design_name"
                value={currentEditFields.name}
                onChange={handleNameChange}
                errorMessage={errors.name}
                placeholder="Enter run name..."
                className={cn('details__value', 'details__input', 'details__input_design-name', {
                  details__input_error: errors.name,
                })}
              />
            </div>
          </div>
          <div className={cn('details__panel')}>
            <div className={cn('details__group')}>
              <label htmlFor="design_description">Description</label>
              <Textarea
                placeholder="Enter objective of the run or the hypothesis being tested..."
                id="design_description"
                value={currentEditFields.description ?? ''}
                onChange={handleDescriptionChange}
                wrapperClassName={cn('details__textarea-wrapper')}
                className={cn('details__value', 'details__textarea')}
                rows={6}
              />
              {designData.runDesignDate && (
                <>
                  <label htmlFor="design_date">Design date</label>
                  <TextInput
                    id="design_date"
                    value={formatDate(designData.runDesignDate, 'E, MMM d  h:mm a')}
                    disabled
                    className={cn('details__value', 'details__input')}
                  />
                </>
              )}
              <label htmlFor="design_link">External links</label>
              <div className={cn('details__multi-value')}>
                {externalLinkList.map((link, index) => (
                  <ExternalLinkItem
                    key={link.uuid}
                    value={link.value}
                    uuid={link.uuid}
                    onChange={changeExternalLink}
                    inputId={index === 0 ? 'design_link' : undefined}
                    isDeleteAllowed={externalLinkList.length > 1 || !!link.value}
                    onDelete={deleteExternalLink}
                  />
                ))}
              </div>

              <div className={cn('details__add-button-wrap')}>
                <button className={cn('details__add-button')} type="button" onClick={addExternalLink}>
                  <icons.PlusIcon className={cn('details__plus-icon')} />
                  <span>ADD MORE LINKS</span>
                </button>
              </div>
            </div>
            <div className={cn('details__group')}>
              <DetailsSelectWithLabel
                htmlFor="design_investigator"
                labelTitle="Investigator name"
                isRequired
                value={currentEditFields.investigatorId}
                inputValue={investigatorName}
                options={userOptionList}
                onChange={handleInvestigatorChange}
                theme={themeOptions.light}
                placeholder="Select investigator..."
                isEditable
                isClearable
                setInputValue={setInvestigatorName}
                errorMessage={errors.investigatorId}
                className={cn({ details__select_error: errors.investigatorId })}
              />

              <label htmlFor="design_project">
                Project name <span className={cn('required')}>*</span>
              </label>
              <ProjectSelect
                selectedProjectId={currentEditFields.projectId ?? ''}
                setSelectedProjectId={handleProjectIdChange}
                placeholder="Select project..."
                id="design_project"
                errorMessage={errors.projectId}
                className={cn('details__value', 'details__select', { details__select_error: errors.projectId })}
                controlClassName={cn('details__select-control')}
                menuListClassName={cn('details__select-menu-list')}
                menuPosition="absolute"
                isPortalable={false}
              />

              <label htmlFor="design_organism">Organism</label>
              <div className={cn('details__multi-value')}>
                {organismList.map((organism, index) => (
                  <OrganismItem
                    key={organism.uuid}
                    value={organism.value}
                    uuid={organism.uuid}
                    onChange={changeOrganism}
                    inputId={index === 0 ? 'design_organism' : undefined}
                    isDeleteAllowed={organismList.length > 1 || !!organism.value}
                    onDelete={deleteOrganism}
                  />
                ))}
              </div>
              <div className={cn('details__add-button-wrap')}>
                <button className={cn('details__add-button')} type="button" onClick={addOrganism}>
                  <icons.PlusIcon className={cn('details__plus-icon')} />
                  <span>ADD ORGANISM</span>
                </button>
              </div>
              <FlowcellTypeSelect
                controlClassName={cn('details__select-control')}
                menuClassName={cn('details__select-menu')}
              />
            </div>
          </div>
        </form>
      )}
    </PagePanel>
  );
};

export default DesignDetails;
