import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import Panel from '@/components/common/Panel';

import pagePanelStyles from '../PagePanel.module.scss';
import flowCellSetupStyles from './FlowCellSetup.module.scss';
import FlowCellSetupFooter from './components/FlowCellSetupFooter';
import TemplateHeader from '../components/TemplateHeader';
import FlowCellSetupContent from './components/FlowCellSetupContent';

const cn = classnames.bind({ ...pagePanelStyles, ...flowCellSetupStyles });

const FlowCellSetup: FC = () => (
  <Panel className={cn('page-panel')} theme={themeOptions.light}>
    <Panel.Header className={cn('flow-cell-setup__header')} wrapClassName={cn('page-panel__header-wrap')}>
      <TemplateHeader templateName="templateName" />
    </Panel.Header>

    <Panel.Content className={cn('page-panel__content')}>
      <FlowCellSetupContent />
    </Panel.Content>

    <div className={cn('page-panel__footer')}>
      <FlowCellSetupFooter />
    </div>
  </Panel>
);

export default memo(FlowCellSetup);
