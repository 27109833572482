import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import PanelWithFolding from '@/components/runDesign/PanelWithFolding';
import MagnificationTable from '@/pages/experiment-run-design-new-flow/components/CagingSettings/components/EditSettings/components/MagnificationTable';

import FovSideBlockHeader from '../FovSideBlock/FovSideBlockHeader';
import FovSideBlockContent from '../FovSideBlock/FovSideBlockContent';
import WashSideBlockHeader from '../WashSideBlock/WashSideBlockHeader';
import WashSideBlockContent from '../WashSideBlock/WashSideBlockContent';
import CellCageBaseBlockContent from '../CellCageBaseBlock/CellCageBaseBlockContent';
import CellCageBaseBlockHeader from '../CellCageBaseBlock/CellCageBaseBlockHeader';
import styles from './CellCageLanesSettings.module.scss';

const cn = classnames.bind(styles);

const CellCageLanesSettings: FC = () => (
  <PanelWithFolding
    renderBaseList={[
      {
        id: 'scan + segment',
        renderHeader: () => <PanelWithFolding.Title Icon={icons.ScanningSmallIcon} title="Scan + Segment" />,
        renderContent: () => <MagnificationTable />,
        width: '250px',
      },
      {
        id: 'cce formation',
        renderHeader: (props) => <CellCageBaseBlockHeader {...props} />,
        renderContent: (props) => <CellCageBaseBlockContent {...props} />,
        isExpandable: true,
        width: '1fr',
        contentBlockClassNameFn: () => cn('cce-formation'),
      },
    ]}
    renderSideList={[
      {
        id: 'wash side block',
        renderHeader: (props) => <WashSideBlockHeader {...props} />,
        renderContent: (props) => <WashSideBlockContent {...props} />,
        width: '300px',
        collapsedWidth: '60px',
      },
      {
        id: 'fovs config',
        renderHeader: (props) => <FovSideBlockHeader {...props} />,
        renderContent: (props) => <FovSideBlockContent {...props} />,
        width: '300px',
        collapsedWidth: '60px',
      },
    ]}
  />
);

export default memo(CellCageLanesSettings);
