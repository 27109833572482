import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';

import { themeOptions } from '@/types/theme';

import LoaderProgress from '@/components/common/LoaderProgress';

const RunDesignDataLayoutWrapper: FC = () => {
  // TODO: NEWFLOW need to change flow to get run design data
  // const { isLoading } = useEditDesignData();
  const isLoading = false;
  if (isLoading) {
    return <LoaderProgress theme={themeOptions.light} />;
  }

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <Outlet />
    </DndProvider>
  );
};

export default RunDesignDataLayoutWrapper;
