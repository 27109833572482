import { FC, memo, useState } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import { isDefined } from '@/helpers/typeGuards';

import ConfirmRemoveModal from './ConfirmModal';

import styles from './RemoveStep.module.scss';

const cn = classnames.bind(styles);

type TRemoveStepProps = {
  name: string;
  isRemovable: boolean;
  onRemove?: () => void;
};

const RemoveStep: FC<TRemoveStepProps> = ({ name, isRemovable, onRemove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemoveClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmClick = () => {
    setIsModalOpen(false);

    setTimeout(() => {
      if (isDefined(onRemove)) {
        onRemove();
      }
    }, 300);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cn('remove')}>
      <button
        className={cn('remove__btn', { remove__btn_hidden: !isRemovable })}
        aria-label="delete"
        onClick={handleRemoveClick}
      >
        <icons.BinIcon className={cn('remove__icon')} />
        Delete Step
      </button>

      <ConfirmRemoveModal
        isOpen={isModalOpen}
        name={name}
        title="Delete step?"
        onCancel={handleCancelClick}
        onConfirm={handleConfirmClick}
      />
    </div>
  );
};

export default memo(RemoveStep);
