import { OptionProps } from 'react-select';
import classnames from 'classnames/bind';
import Select from '@/components/common/Select';

import ConsumableComponent from '~/src/components/runDesign/ConsumableComponent';

import { useMemo } from 'react';
import styles from './CustomComponents.mosule.scss';

const cn = classnames.bind(styles);

type TReagentOption<T> = OptionProps<T> & { consumableClassName?: string };

const ReagentOption = <T extends TBasicOption>({ data, ...props }: TReagentOption<T>) => {
  const label = useMemo(
    () => (
      <>
        <div className={cn('reagent-option__type')}>{data?.customData?.type ?? ''}</div>
        <ConsumableComponent
          className={cn('reagent-option__item')}
          wavesClassName={cn('reagent-option__waves')}
          consumable={data.customData?.reagent}
        />
      </>
    ),
    [data]
  );

  const dataWithLabel = useMemo(
    () => ({
      ...data,
      label,
    }),
    [data, label]
  );

  return <Select.DefaultOption data={dataWithLabel} {...props} />;
};

export default ReagentOption;
