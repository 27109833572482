import { ChangeEventHandler, FC } from 'react';
import classNames from 'classnames/bind';
import CheckboxInput from '@/components/common/CheckboxInput';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { includes } from '@/helpers/enum';
import { Placement } from '@/graphql/API';
import { isBasicOption } from '@/components/common/Select/helpers';
import Remover from '@/components/runDesign/RemoveStep';

import styles from './TitleBlock.module.scss';

const cn = classNames.bind(styles);

const PlacementList = {
  [Placement.START]: 'First step of the run',
  [Placement.END]: 'Last step of the run',
  [Placement.BEFORE]: 'Before',
  [Placement.AFTER]: 'After',
  [Placement.SIMULTANEOUS]: 'Simult. w.',
};

const placementOptions = Object.entries(PlacementList).map(([value, label]) => ({
  value,
  label,
}));

type TTitleBlockProps = {
  name: string;
  typename?: string;
  timelineWithCellKilling?: boolean;
  afterCompletion: {
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
  };
  removing: {
    isRemovable: boolean;
    onRemove?: () => void;
  };
  timing: {
    placement: {
      value: Nullable<Placement>;
      onChange: (value: Placement) => void;
    };
    relativeTo: {
      list: TOption[];
      value: Nullable<string>;
      onChange: (value: string) => void;
    };
  };
};

const TitleBlock: FC<TTitleBlockProps> = ({ name, afterCompletion, timing, removing, typename }) => (
  <div className={cn('title-block')}>
    <div className={cn('title-block__column')}>
      <h2 className={cn('title-block__title')}>{name}</h2>
      <div className={cn('title-block__pause-wrap')}>
        <CheckboxInput
          checked={afterCompletion.checked}
          isSwitch
          theme="run-design"
          onChange={afterCompletion.onChange}
          label="Pause after completion"
          labelClassName={cn('title-block__checkbox-label')}
        />
      </div>
    </div>
    <div className={cn('title-block__column')}>
      <div className={cn('title-block__step-title')}>
        {typename === 'CellKilling' ? 'Cell Killing timing' : 'Step timing'}
      </div>
      <div className={cn('title-block__timing-placement')}>
        {typename === 'CellKilling' ? (
          <div className={cn('title-block__cell-killing-placement')}>
            Starts at step:{' '}
            <b>
              {timing.relativeTo.list.find(
                (option: TOption) => isBasicOption(option) && option.value === timing.relativeTo.value
              )?.label ?? ''}
            </b>
          </div>
        ) : (
          <>
            <Select
              options={placementOptions}
              value={timing.placement.value}
              onChange={timing.placement.onChange}
              theme={themeOptions.light}
              placeholder="Select timing..."
              className={cn('title-block__select')}
              controlClassName={cn('title-block__select-control')}
              menuClassName={cn('title-block__select-menu')}
              menuListClassName={cn('title-block__select-menu-list')}
              menuPosition="absolute"
              isPortalable={false}
              customComponents={{ Option: Select.DefaultOption }}
            />
            {includes([Placement.AFTER, Placement.BEFORE, Placement.SIMULTANEOUS], timing.placement.value) && (
              <Select
                options={timing.relativeTo.list}
                value={timing.relativeTo.value}
                onChange={timing.relativeTo.onChange}
                theme={themeOptions.light}
                placeholder="Select step..."
                className={cn('title-block__select')}
                controlClassName={cn('title-block__select-control')}
                menuClassName={cn('title-block__select-menu')}
                menuListClassName={cn('title-block__select-menu-list')}
                menuPosition="absolute"
                isPortalable={false}
                customComponents={{ Option: Select.DefaultOption }}
              />
            )}
          </>
        )}
      </div>
    </div>
    {removing.isRemovable && (
      <div className={cn('title-block__column')}>
        <Remover {...removing} name={name} />
      </div>
    )}
  </div>
);

export default TitleBlock;
