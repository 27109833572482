import { FC, MouseEventHandler } from 'react';

import Button from '@/components/common/Button';

type TAdvancedButton = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isAdvanced: boolean;
  className?: string;
};

const AdvancedButton: FC<TAdvancedButton> = ({ className, onClick, isAdvanced }) => (
  <Button onClick={onClick} isFitContent isBgLight type="button" color="dark" className={className}>
    {isAdvanced ? 'Hide advanced' : 'View advanced'}
  </Button>
);

export default AdvancedButton;
