import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';
import { isMRNAComponent } from '@/helpers/runDesigns/typeGuards';
import ReagentModalContextProvider from '@/pages/experiment-run-design/ReagentsForAssays/context';

import ReagentsForAssaysBlock from './components/ReagentsForAssaysBlock';

const ReagentsForAssays: FC = () => {
  const componentList = useSelector(experimentRunDesignSelectors.selectAssayComponentList);
  const componentListWithoutMRNA = useMemo(
    () => componentList.filter((component) => !isMRNAComponent(component)),
    [componentList]
  );

  return (
    <ReagentModalContextProvider>
      <RunDesignBlockWithLanes title="Select reagents for assays">
        <ReagentsForAssaysBlock componentList={componentListWithoutMRNA} noDataMessage="There are no components" />
      </RunDesignBlockWithLanes>
    </ReagentModalContextProvider>
  );
};

export default ReagentsForAssays;
