import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import LanesTotal from './LanesTotal';
import CellTypeMax from './CellTypeMax';
import RunDetailsMetadata from './RunDetailsMetadata';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

type SampleInformationHeaderProps = {
  title: ReactNode;
  isLoading: boolean;
  isError: boolean;
  isChangeCellTypesAllowed: boolean;
  isChangeLanesAllowed: boolean;
  children?: ReactNode;
};

const SampleInformationHeader: FC<SampleInformationHeaderProps> = ({
  title,
  isLoading,
  isError,
  isChangeCellTypesAllowed,
  isChangeLanesAllowed,
  children,
}) => (
  <>
    <h3 className={cn('run-details__title')}>{title}</h3>
    {!isLoading && !isError && (
      <div className={cn('run-details__header-data')}>
        <LanesTotal isChangeAllowed={isChangeLanesAllowed} />
        <CellTypeMax isChangeAllowed={isChangeCellTypesAllowed} />
        <RunDetailsMetadata />
        {children}
      </div>
    )}
  </>
);

export default SampleInformationHeader;
