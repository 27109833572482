import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import styles from './FooterButtons.module.scss';
import { TDefaultButtonProps } from './types';

const cn = classnames.bind(styles);

type TBackProps = Omit<TDefaultButtonProps, 'customTitle'>;

const Back: FC<TBackProps> = ({ onClick, className, disabled }) => (
  <Button
    color="light"
    isOutlined
    className={cn('page-controls__button', 'page-controls__back', className)}
    onClick={onClick}
    disabled={disabled}
  >
    <icons.BackArrowIcon />
  </Button>
);

export default memo(Back);
