import { FC, MouseEventHandler, SVGProps } from 'react';
import classnames from 'classnames/bind';

import styles from './Title.module.scss';

const cn = classnames.bind(styles);

type TTitleProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  isShowTitle?: boolean;
  className?: string;
  svgWrapperClassName?: string;
};

const Title: FC<TTitleProps> = ({ Icon, title, isShowTitle = true, onClick, svgWrapperClassName, className }) => {
  const titleComponent = (
    <div className={cn('title', { clickable: !!onClick }, className)}>
      <div className={cn('icon', { icon_center: !isShowTitle }, svgWrapperClassName)}>
        <Icon className={cn('icon__svg')} />
      </div>
      {isShowTitle && <span>{title}</span>}
    </div>
  );

  if (onClick) {
    return (
      <button aria-label={title} className={cn('button')} onClick={onClick}>
        {titleComponent}
      </button>
    );
  }

  return titleComponent;
};

export default Title;
