import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';
import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import icons from '@/components/common/icons';
import { CellKilling, Placement } from '@/graphql/API';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import styles from './CellKillingRenderer.module.scss';
import schemaComponentStyles from '../SchemaComponent.module.scss';

const cn = classNames.bind({ ...schemaComponentStyles, ...styles });

type TCellKillingRendererProps = {
  isCurrent: boolean;
  component: CellKilling;
  componentListData: {
    component: TRunDesignComponent;
    position: {
      row: number;
      column: number;
      columnEnd?: number;
    };
  }[];
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  scanLabelMap: Record<string, string>;
};

const CellKillingRenderer: FC<TCellKillingRendererProps> = ({
  isCurrent,
  component,
  componentListData,
  onClick,
  position,
  scanLabelMap,
}) => {
  const firstRowComponentDataList = useMemo(
    () =>
      componentListData.filter(
        (firstRowComponentData) => firstRowComponentData.component.timing.placement !== Placement.SIMULTANEOUS
      ),
    [componentListData]
  );

  const associatedCellKillingData = useMemo(() => {
    const lastIndex = position.columnEnd ?? firstRowComponentDataList.length - 1;
    const firstIndex = position.column;
    const cellKillingPositionList = Array.from({ length: lastIndex - firstIndex }, (_, index) => ({
      column: index + 1,
    }));

    return cellKillingPositionList.map((partPosition, partIndex) => {
      const collectedData: {
        withStain?: boolean;
        name?: string;
        abbreviationName?: string;
        withScan?: boolean;
        scanLabel?: string;
        position: {
          column: number;
        };
      } = {
        position: partPosition,
      };

      const associatedComponentData = firstRowComponentDataList[position.column - 1 + partIndex]; // position.column is not index, so need -1

      if (associatedComponentData) {
        collectedData.withStain = component.deliveryStainsAt?.includes(associatedComponentData.component.id);
        collectedData.withScan = !!scanLabelMap[associatedComponentData.component.id];
      }

      if (partIndex === 0) {
        collectedData.name = component.name;
        collectedData.abbreviationName = getAbbreviationName(component);
      }

      if (partIndex === cellKillingPositionList.length - 1) {
        collectedData.withScan = true;
        collectedData.scanLabel = scanLabelMap[component.id];
      }

      return collectedData;
    });
  }, [firstRowComponentDataList, position, scanLabelMap, component]);

  return (
    <button
      className={cn('schema-cell-killing', {
        'schema-cell-killing_current': isCurrent,
      })}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
          '--column-end': position.columnEnd,
        } as CSSProperties
      }
    >
      {associatedCellKillingData.map((partData, index) => (
        <div
          key={index}
          className={cn('schema-component', {
            'schema-component_no-scan': !partData.withScan,
          })}
          style={
            {
              '--column': partData.position.column,
            } as CSSProperties
          }
        >
          <div className={cn('schema-component__wrap')}>
            <div className={cn('schema-component__step', 'schema-component__incubation')}>
              {partData.name && <span className={cn('schema-component__name')}>{partData.name}</span>}
              {partData.abbreviationName && (
                <span className={cn('schema-component__name', 'schema-component__name_abbreviation')}>
                  {partData.abbreviationName}
                </span>
              )}
              {partData.withStain && (
                <div className={cn('schema-cell-killing_stain')}>
                  <icons.StainIcon width={10} height={15} />
                </div>
              )}
            </div>
            {partData.withScan && (
              <div className={cn('schema-component__step', 'schema-component__scan')}>{partData.scanLabel}</div>
            )}
          </div>
        </div>
      ))}
    </button>
  );
};

export default CellKillingRenderer;
