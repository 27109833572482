import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';
import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import { formatDurationText } from '@/pages/experiment-run-design/DesignTimeline/components/Timeline/helpers';
import { iconList, TIconId } from '@/helpers/runDesigns/constants';
import icons from '@/components/common/icons';
import {
  TCardData,
  TCellKillingCardData,
} from '@/pages/experiment-run-design/DesignTimeline/components/Timeline/types';
import { Placement } from '@/graphql/API';
import { isDefined } from '@/helpers/typeGuards';
import styles from './CellKillingRenderer.module.scss';
import timelineCardStyles from '../TimelineCard.module.scss';

const cn = classNames.bind({ ...timelineCardStyles, ...styles });

type TCellKillingRendererProps = {
  data: TCellKillingCardData;
  cardDataList: TCardData[];
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const CellKillingRenderer: FC<TCellKillingRendererProps> = ({ data, cardDataList, position, onClick }) => {
  const firstRowCardDataList = useMemo(
    () =>
      cardDataList.filter((firstRowCardData) => firstRowCardData.component.timing.placement !== Placement.SIMULTANEOUS),
    [cardDataList]
  );

  const associatedCellKillingData = useMemo(() => {
    const lastIndex = position.columnEnd ?? firstRowCardDataList.length - 1;
    const firstIndex = position.column;
    const cellKillingPositionList = Array.from({ length: lastIndex - firstIndex }, (_, index) => ({
      column: index + 1,
    }));

    return cellKillingPositionList.map((partPosition, partIndex) => {
      const collectedData: {
        withStain?: boolean;
        name?: string;
        abbreviationName?: string;
        withScan?: boolean;
        scanLabel?: string;
        scanDurationText?: string;
        position: {
          column: number;
        };
        durationText?: string;
        iconCollection: TIconId[];
        scanIconCollection: TIconId[];
      } = {
        position: partPosition,
        iconCollection: [],
        scanIconCollection: [],
      };

      const associatedComponentData = firstRowCardDataList[position.column - 1 + partIndex]; // position.column is not index, so need -1

      if (associatedComponentData) {
        collectedData.withStain = data.component.deliveryStainsAt?.includes(associatedComponentData.component.id);
        collectedData.withScan = !!associatedComponentData.scan;
      }

      if (partIndex === 0) {
        collectedData.name = data.component.name;
        collectedData.abbreviationName = getAbbreviationName(data.component);
        collectedData.durationText = formatDurationText(data.durationText);
        collectedData.iconCollection = data.iconCollection;
      }

      if (isDefined(data.scan) && partIndex === cellKillingPositionList.length - 1) {
        collectedData.withScan = true;
        collectedData.scanLabel = data.scan?.title;
        collectedData.scanDurationText = formatDurationText(data.scan.durationText);
        collectedData.scanIconCollection = data.scan.iconCollection;
      }

      return collectedData;
    });
  }, [cardDataList, position, firstRowCardDataList, data]);

  return (
    <button
      className={cn('cell-killing-card')}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
          '--column-end': position.columnEnd,
        } as CSSProperties
      }
    >
      {associatedCellKillingData.map((partData, index) => (
        <div
          key={index}
          className={cn('timeline-card', 'cell-killing-card__part', {
            'timeline-card_simultaneous': position.row > 1,
            'timeline-card_no-scan': !data.scan,
          })}
          style={
            {
              '--row': 2,
              '--column': partData.position.column,
            } as CSSProperties
          }
        >
          <div className={cn('timeline-card__wrap')}>
            <div className={cn('timeline-card__block', 'cell-killing-card__main')}>
              <div className={cn('timeline-card__header')}>
                <h3 className={cn('timeline-card__title')}>
                  <span className={cn('timeline-card__text')}>{partData.name}</span>
                  <span className={cn('timeline-card__text', 'timeline-card__text_abbreviation')}>
                    {partData.abbreviationName}
                  </span>
                </h3>
                <p className={cn('timeline-card__duration')}>{partData.durationText}</p>
              </div>

              <div className={cn('timeline-card__icon-list')}>
                {partData.iconCollection.map((iconId) => (
                  <div className={cn('timeline-card__icon-wrap')} key={iconId}>
                    {iconList[iconId]}
                  </div>
                ))}
                {partData.withStain && (
                  <div className={cn('timeline-card__icon-wrap')}>
                    <icons.StainIcon height={35} width={25} />
                  </div>
                )}
              </div>
            </div>

            {partData.withScan && (
              <div className={cn('timeline-card__block', 'cell-killing-card__scan')}>
                <div className={cn('timeline-card__header')}>
                  <h3 className={cn('timeline-card__title')}>{partData.scanLabel}</h3>
                  <p className={cn('timeline-card__duration')}>{partData.scanDurationText}</p>
                </div>
                <div className={cn('timeline-card__icon-list')}>
                  {partData.scanIconCollection.map((iconId) => (
                    <div className={cn('timeline-card__icon')} key={iconId}>
                      {iconList[iconId]}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </button>
  );
};

export default CellKillingRenderer;
