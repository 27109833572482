import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import useOutsideClick from '@/hooks/useOutsideClick';
import { ECreatableComponentTypeNewFlow, TCreatableComponentTypeNewFlow } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';

import styles from './SelectComponent.module.scss';
import StepItem from './StepItem';

const cn = classnames.bind(styles);

type TSelectComponentProps = {
  onSelect: (type: TCreatableComponentTypeNewFlow) => void;
  className?: string;
  stepListWrapClassName?: string;
};

const SelectComponent: FC<TSelectComponentProps> = ({ onSelect, className, stepListWrapClassName }) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const addStepRef = useRef<HTMLDivElement>(null);

  useOutsideClick(addStepRef, () => {
    setIsAddOpen(false);
  });

  const handleAddStepClick = useCallback(() => {
    setIsAddOpen((prev) => !prev);
  }, []);

  const stepNameMap = useMemo(
    () => ({
      [ECreatableComponentTypeNewFlow.Incubation]: 'Incubation',
      [ECreatableComponentTypeNewFlow.SurfaceReceptor]: 'Surface Receptor',
      [ECreatableComponentTypeNewFlow.CytokineSecretion]: 'Cytokine Secretion',
      [ECreatableComponentTypeNewFlow.mRNA]: 'mRNA',
      [ECreatableComponentTypeNewFlow.ScanComp]: 'Scan component',
    }),
    []
  );

  return (
    <div className={cn('select-component__add-step-wrap', className, { _collapsed: isAddOpen })} ref={addStepRef}>
      <div className={cn('select-component__add-step-list-wrap', stepListWrapClassName)}>
        <button className={cn('select-component__add-step-trigger')} onClick={handleAddStepClick}>
          <div className={cn('select-component__add-step-title', 'title', '')}>
            <icons.PlusInCircleIcon className={cn('select-component__add-step-trigger-icon')} />
            <span>Add Step</span>
          </div>
        </button>

        <div className={cn('select-component__add-step-list-wrapper', { 'visually-hidden': !isAddOpen })}>
          <ul className={cn('select-component__add-step-list', { 'visually-hidden': !isAddOpen })}>
            {Object.values(ECreatableComponentTypeNewFlow).map((type) => (
              <StepItem
                key={type}
                name={stepNameMap[type]}
                type={type}
                onSelect={onSelect}
                tabIndex={isAddOpen ? 0 : -1}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectComponent);
