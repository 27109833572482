import { ChangeEvent, FC } from 'react';
import classNames from 'classnames/bind';

import TextInput from '@/components/common/TextInput';
import icons from '@/components/common/icons';

import styles from '../DesignDetails.module.scss';

const cn = classNames.bind(styles);

type TExternalLinkItemProps = {
  value: string;
  uuid: string;
  onChange: (uuid: string, value: string) => void;
  isDeleteAllowed: boolean;
  onDelete: (uuid: string) => void;
  inputId?: string;
};

const ExternalLinkItem: FC<TExternalLinkItemProps> = ({
  value,
  uuid,
  onChange,
  isDeleteAllowed,
  onDelete,
  inputId,
}) => {
  const handleDeleteClick = () => {
    onDelete(uuid);
  };

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(uuid, event.target.value);
  };

  return (
    <div
      className={cn({
        'details__multi-value_item': isDeleteAllowed,
      })}
    >
      <TextInput
        value={value}
        onChange={handleValueChange}
        placeholder="Paste link here..."
        id={inputId}
        className={cn('details__value', 'details__input')}
      />
      {isDeleteAllowed && (
        <button
          className={cn('details__delete')}
          type="button"
          onClick={handleDeleteClick}
          aria-label="Delete external link"
        >
          <icons.DeleteIcon className={cn('details__delete-icon')} />
        </button>
      )}
    </div>
  );
};

export default ExternalLinkItem;
