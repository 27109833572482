import { gql } from '@apollo/client';
import { runDesignApolloClient } from '@/services/runDesignApolloClient';
import {
  templateQuery,
  TemplateQuery,
  TemplateQueryVariables,
  templatesQuery,
  TemplatesQuery,
  TemplatesQueryVariables,
} from '@/graphql/runDesign';

import { TRunDesignGraphqlEndpointBuilder } from '../types';
import { TagTypesMap } from '../constants';
import { baseMerge } from '../helpers';

export const templatesEndpoints = (build: TRunDesignGraphqlEndpointBuilder) => ({
  fetchTemplates: build.query<TemplatesQuery['templates'], TemplatesQueryVariables>({
    queryFn: async (args) => {
      try {
        const response = await runDesignApolloClient.query<TemplatesQuery>({
          query: gql(templatesQuery),
          variables: args,
        });
        return {
          data: response.data.templates,
        };
      } catch (error: any) {
        console.error(error?.message);
        return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
      }
    },
    merge: baseMerge,
    providesTags: [TagTypesMap.TemplateList],
  }),
  fetchTemplateById: build.query<TemplateQuery['template'], TemplateQueryVariables>({
    queryFn: async (args) => {
      try {
        const response = await runDesignApolloClient.query<TemplateQuery>({
          query: gql(templateQuery),
          variables: args,
        });
        return {
          data: response.data.template,
        };
      } catch (error: any) {
        console.error(error?.message);
        return { error: { error: 'Graphql api error', status: 'FETCH_ERROR' } };
      }
    },
    providesTags: [TagTypesMap.Template],
  }),
});
