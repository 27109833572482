import React, { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './ComponentHeaderWrapper.module.scss';

const cn = classnames.bind(styles);

type TComponentHeaderWrapperProps = {
  title: string;
  children?: ReactNode;
  titleClassName?: string;
  contentClassName?: string;
  showDivide?: boolean;
};

// TODO: NEWFLOW this component need to use for headers under timeline
const ComponentHeaderWrapper: FC<TComponentHeaderWrapperProps> = ({
  title,
  children,
  titleClassName,
  contentClassName,
  showDivide = true,
}) => (
  <div className={cn('component-header')}>
    <div className={cn('component-header__wrapper')}>
      <h2 className={cn('component-header__title', titleClassName)}>{title}</h2>
      {showDivide && !!children && <div className={cn('vertical-line')} />}
      <div className={cn('component-header__content', contentClassName)}>
        {React.Children.map(children, (child, index) => (
          <>
            {child}
            {showDivide && index < React.Children.count(children) - 1 && <div className={cn('vertical-line')} />}
          </>
        ))}
      </div>
    </div>
  </div>
);

export default ComponentHeaderWrapper;
