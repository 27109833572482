import { FC } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';

import ReagentModalContextProvider from '@/pages/experiment-run-design/ReagentsForAssays/context';
import ReagentsForAssaysBlock from '../ReagentsForAssays/components/ReagentsForAssaysBlock';

const IncubationReagents: FC = () => {
  const incubationComponentList = useSelector(experimentRunDesignSelectors.selectIncubationComponentList);
  return (
    <ReagentModalContextProvider>
      <RunDesignBlockWithLanes title="Select treatment for incubation">
        <ReagentsForAssaysBlock
          componentList={incubationComponentList}
          withWaves={false}
          noDataMessage="There are no components with the type Incubation"
        />
      </RunDesignBlockWithLanes>
    </ReagentModalContextProvider>
  );
};

export default IncubationReagents;
