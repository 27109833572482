import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { generateJwtToken } from '@/helpers';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/rdsvc/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await generateJwtToken();
  return { headers: { ...headers, Authorization: `Bearer ${token}` } };
});

export const runDesignApolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
});
