import { FC } from 'react';
import classnames from 'classnames/bind';
import Select, { TSelectProps } from '@/components/common/Select';
import { themeOptions } from '@/types/theme';

import styles from '../DesignDetails.module.scss';

const cn = classnames.bind(styles);

type TDetailsSelectProps = {
  labelTitle: string;
  htmlFor: string;
  labelClassName?: string;
  isRequired?: boolean;
} & TSelectProps;

// TODO: NEWFLOW this select like a selects on first page with general experiment info
// TODO: NEWFLOW may be need to remove, if it will be extra abstraction
const DetailsSelectWithLabel: FC<TDetailsSelectProps> = ({
  htmlFor,
  labelTitle,
  isRequired,
  className,
  controlClassName,
  menuClassName,
  menuListClassName,
  customComponents,
  labelClassName,
  ...selectProps
}) => (
  <>
    <label htmlFor={htmlFor} className={cn(labelClassName)}>
      {labelTitle} {isRequired && <span className={cn('required')}>*</span>}
    </label>
    <Select
      id={htmlFor}
      className={cn('details__value', 'details__select', className)}
      controlClassName={cn('details__select-control', controlClassName)}
      menuClassName={cn('details__select-menu', menuClassName)}
      menuListClassName={cn('details__select-menu-list', menuListClassName)}
      customComponents={{ Option: Select.DefaultOption, ...customComponents }}
      menuPosition="absolute"
      isPortalable={false}
      theme={themeOptions.light}
      {...selectProps}
    />
  </>
);

export default DetailsSelectWithLabel;
