import { FC, CSSProperties } from 'react';
import classnames from 'classnames/bind';

import { TContentProps } from '../../types';

import styles from './Content.module.scss';
import ContentBase from './ContentBase';
import ContentSideItem from './ContentSide';

const cn = classnames.bind(styles);

const Content: FC<TContentProps> = ({ className, renderBaseList, renderSideList, gridColumns }) => (
  <div className={cn('content', className)} style={{ '--grid-template-columns': gridColumns } as CSSProperties}>
    {gridColumns &&
      renderBaseList?.map((props, index) => (
        <ContentBase key={props.id} blockIndex={index} isLast={index === renderBaseList.length - 1} {...props} />
      ))}
    {gridColumns &&
      renderSideList?.map((props, index) => <ContentSideItem key={props.id} blockIndex={index} {...props} />)}
  </div>
);

export default Content;
