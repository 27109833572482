import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import styles from './FooterButtons.module.scss';
import { TDefaultButtonProps } from './types';

const cn = classnames.bind(styles);

type TSaveAndExit = TDefaultButtonProps;

const SaveAndExit: FC<TSaveAndExit> = ({ onClick, className, customTitle, disabled }) => (
  <Button
    color="light"
    isOutlined
    className={cn('page-controls__button', 'page-controls__save', className)}
    onClick={onClick}
    disabled={disabled}
  >
    {customTitle ?? 'Save & Exit'}
  </Button>
);

export default memo(SaveAndExit);
