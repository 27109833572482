import { CellKilling, Placement } from '@/graphql/API';
import { getComponentListWithNewStep } from '@/store/slices/experimentRunDesign/helpers';
import { TCreatableComponentType, TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { isCellKillingComponent, isComponentWithScan } from '@/helpers/runDesigns/typeGuards';

import { isDefined } from '@/helpers/typeGuards';
import fixPlacement from './helpers/fixPlacement';
import copyState from './helpers/copyState';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import { EOrderActionList, TOrderReducerAction } from './types';

const updateCellKillingStainPlacement = (
  state: TRunDesignComponent[],
  placementIndex: number,
  relativeTo: string,
  isChecked?: boolean
) => {
  const newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));

  const component = newState[placementIndex] as CellKilling;

  let deliveryStainsAt: string[] = component?.deliveryStainsAt ? [...component.deliveryStainsAt] : [];

  if (isDefined(isChecked)) {
    if (isChecked) {
      if (!deliveryStainsAt.includes(relativeTo)) {
        deliveryStainsAt.push(relativeTo);
      }
    } else {
      deliveryStainsAt = deliveryStainsAt.filter((id) => id !== relativeTo);
    }
  }

  const placementList = deliveryStainsAt.map((id) => newState.findIndex((componentData) => componentData.id === id));

  const relativeToIndex = !placementList.length ? 0 : Math.min(...placementList);
  component.timing.relativeTo = newState[relativeToIndex].id;
  component.deliveryStainsAt = deliveryStainsAt;

  return fixPlacement(newState);
};

const updateCellKillingPosition = (state: TRunDesignComponent[], component: TRunDesignComponent) => {
  if (isCellKillingComponent(component)) return state;

  const cellKillingComponentList = state.filter(isCellKillingComponent);

  if (!cellKillingComponentList.length) {
    return state;
  }

  let newState = copyState(state);
  cellKillingComponentList.forEach((cellKillingComponent) => {
    if (
      !cellKillingComponent?.deliveryStainsAt?.includes(component.id) &&
      cellKillingComponent?.timing.relativeTo !== component.id
    ) {
      return;
    }

    const cellKillingComponentIndex = newState.findIndex(({ id }) => id === cellKillingComponent.id);

    newState = updateCellKillingStainPlacement(newState, cellKillingComponentIndex, component.id);
  });

  return newState;
};

export const updateTimelinePlacement = (
  state: TRunDesignComponent[],
  placementIndex: number,
  placement: Placement,
  onReorderFinishCb?: (data: TRunDesignComponent[]) => void
): TRunDesignComponent[] => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];

  const updatedPlacementIndex = newState.findIndex(({ id }) => id === component.id);
  component.timing.placement = placement;

  newState = newState.toSpliced(updatedPlacementIndex, 1);
  let relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  if (relativeToIndex < 0) {
    relativeToIndex = 0;
    component.timing.relativeTo = newState[0]?.id;
  }

  const reorderActions = {
    [Placement.START]: () => {
      newState = [component, ...newState];
    },
    [Placement.END]: () => {
      newState = [...newState, component];
    },
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  // Update deliveryStainsAt and timing of  cell killing component in the case moved component had  cell killing  stain
  newState = updateCellKillingPosition(newState, component);

  requestAnimationFrame(() => onReorderFinishCb?.(newState));

  return newState;
};

export const updateTimelineRelativeTo = (
  state: TRunDesignComponent[],
  placementIndex: number,
  relativeTo: string,
  onReorderFinishCb?: (data: TRunDesignComponent[]) => void
) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];
  component.timing.relativeTo = relativeTo;

  newState = newState.toSpliced(placementIndex, 1);
  const relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  const reorderActions = {
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  const { placement } = component.timing;

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  // Update deliveryStainsAt and timing of  cell killing component in the case moved component had  cell killing  stain
  newState = updateCellKillingPosition(newState, component);

  requestAnimationFrame(() => onReorderFinishCb?.(newState));

  return newState;
};

const addComponent = (
  type: TCreatableComponentType,
  state: TRunDesignComponent[],
  placementIndex: number,
  id: string
) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  newState = getComponentListWithNewStep(type, newState, id, placementIndex);

  fixPlacement(newState);

  return newState;
};

const removeComponent = (state: TRunDesignComponent[], placementIndex: number) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const removingComponent = newState[placementIndex];

  newState = newState.toSpliced(placementIndex, 1);

  if (isComponentWithScan(removingComponent)) {
    const relativeScanIdList = removingComponent.scanConfig?.scanIds;
    relativeScanIdList?.forEach((scanId) => {
      const scanPlacementIndex = newState.findIndex(({ id }) => id === scanId);

      newState = newState.toSpliced(scanPlacementIndex, 1);
    });
  }

  const sameTypeComponents = newState.filter((component) => component.__typename === removingComponent.__typename);
  // rename if only 1 component exist
  if (sameTypeComponents.length === 1) {
    const componentToRename = newState.find((component) => component.__typename === removingComponent.__typename);
    if (componentToRename) {
      componentToRename.name = componentToRename.name.replace(/\s\d+$/, '');
    }
  }

  fixPlacement(newState);

  return newState;
};

const renumber = (state: TRunDesignComponent[], type: string) => {
  const newState = copyState(state);
  const componentListToRenumber = newState.filter((component) => component.__typename === type);

  if (componentListToRenumber.length <= 1) {
    return newState;
  }
  const name = componentListToRenumber[0].name.replace(/\s\d+$/, '');

  componentListToRenumber.forEach((component, index) => {
    component.name = `${name} ${index + 1}`;
  });

  return newState;
};

const orderReducer = (state: TRunDesignComponent[], action: TOrderReducerAction) => {
  if (action.type === EOrderActionList.renumber) {
    return renumber(state, action.payload.type);
  }

  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EOrderActionList.updateTimelinePlacement) {
    return updateTimelinePlacement(state, placementIndex, action.payload.value, action.payload.onReorderFinishCb);
  }

  if (action.type === EOrderActionList.updateTimelineRelativeTo) {
    return updateTimelineRelativeTo(state, placementIndex, action.payload.value, action.payload.onReorderFinishCb);
  }

  if (action.type === EOrderActionList.updateCellKillingStainPlacement) {
    return updateCellKillingStainPlacement(state, placementIndex, action.payload.value, action.payload.isChecked);
  }

  if (action.type === EOrderActionList.addStep) {
    return addComponent(action.payload.type, state, placementIndex, action.payload.id);
  }

  if (action.type === EOrderActionList.removeStep) {
    return removeComponent(state, placementIndex);
  }

  throw new Error('Unknown edit order action.');
};

export default orderReducer;
