import { SVGProps } from 'react';

const ScanningSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.4004 1H21.5004C23.4334 1 25.0004 2.56682 25.0004 4.49982C25.0004 5.49594 25.0004 6.58588 25.0004 7.6"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M7.59961 1H4.49961C2.56661 1 0.99961 2.56682 0.99961 4.49982C0.99961 5.49594 0.99961 6.58588 0.99961 7.6"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M18.4004 25H21.5004C23.4334 25 25.0004 23.4332 25.0004 21.5002C25.0004 20.5041 25.0004 19.4141 25.0004 18.4"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M7.59961 25H4.49961C2.56661 25 0.99961 23.4332 0.99961 21.5002C0.99961 20.5041 0.99961 19.4141 0.99961 18.4"
      stroke="white"
      strokeWidth="2"
    />
    <circle cx="13" cy="13" r="6" transform="rotate(-90 13 13)" stroke="white" strokeWidth="2" strokeLinecap="square" />
  </svg>
);

export default ScanningSmallIcon;
