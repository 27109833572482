import { SVGProps } from 'react';

const RevertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.0055 9.99984C19.0055 14.9207 15.0164 18.9098 10.0955 18.9098C6.79759 18.9098 3.91813 17.118 2.37755 14.4548M1.18555 9.99984C1.18555 5.07899 5.17469 1.08984 10.0955 1.08984C13.0767 1.08984 15.7159 2.55395 17.3334 4.80234L17.8918 5.54484"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path d="M18.6013 1.49484H19.4113V6.35484H18.6013V1.49484Z" fill="white" stroke="currentColor" strokeWidth="0.81" />
    <path d="M2.40066 18.5052H1.59066V13.6452H2.40066V18.5052Z" fill="white" stroke="currentColor" strokeWidth="0.81" />
    <path
      d="M14.5515 6.35281L14.5515 5.54281L19.4115 5.54281L19.4115 6.35281L14.5515 6.35281Z"
      fill="inherit"
      stroke="currentColor"
      strokeWidth="0.81"
    />
    <path
      d="M6.45047 13.6433L6.45047 14.4533L1.59047 14.4533L1.59047 13.6433L6.45047 13.6433Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="0.81"
    />
  </svg>
);

export default RevertIcon;
