import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MAX_LANES_COUNT, MIN_LANES_COUNT } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import CounterModule from '../CounterModule';

type TLanesTotalProps = {
  className?: string;
  isChangeAllowed?: boolean;
};

const LanesTotal: FC<TLanesTotalProps> = ({ className, isChangeAllowed }) => {
  const appDispatch = useAppDispatch();

  const currentLanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const lanesCount = useMemo(() => currentLanes?.length ?? 0, [currentLanes]);

  const handleLaneCountIncrease = () => {
    appDispatch(experimentRunDesignActions.addNewLane());
  };

  const handleLaneCountDecrease = () => {
    appDispatch(experimentRunDesignActions.removeLane());
  };
  return (
    <CounterModule
      className={className}
      count={lanesCount}
      onIncrease={handleLaneCountIncrease}
      onDecrease={handleLaneCountDecrease}
      description="Active lanes"
      isLocked={someRunDesignCardIsEdit}
      minCount={MIN_LANES_COUNT}
      maxCount={MAX_LANES_COUNT}
      showControls={!!isChangeAllowed}
    />
  );
};

export default LanesTotal;
