import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import styles from './CellTypes.module.scss';

const cn = classnames.bind(styles);

type TCellTypeCircle = {
  className?: string;
  isActive?: boolean;
};

// TODO: NEWFLOW remove in new flow
const CellTypeCircle: FC<TCellTypeCircle> = ({ className, isActive }) => (
  <span className={cn('cell-type', className, { 'cell-type_active': isActive })} />
);

export default memo(CellTypeCircle);
