import { FC, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useOpenModal } from '@/hooks/useOpenModal';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign';

import ListWithActiveTab from '@/components/common/ListWithActiveTab';

import NoDataFound from '@/components/common/NoDataFound';
import { RunDesignContext } from '../../../context';

import ReagentsCard from '../ReagentsCard';
import SettingsModal from '../../../DesignTimeline/components/SettingsModal';
import { editReducer } from '../../../DesignTimeline/reducer';
import { TEditReducerAction } from '../../../DesignTimeline/reducer/types';

type TReagentsForAssaysBlock = {
  componentList: TRunDesignComponent[];
  withWaves?: boolean;
  noDataMessage: string;
};

const ReagentsForAssaysBlock: FC<TReagentsForAssaysBlock> = ({ componentList, withWaves, noDataMessage }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig, clearCustomFooterConfig, incubationChangeTrigger } = useContext(RunDesignContext);

  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);
  const runDesignCardIndexExpand = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexExpand);
  const [currentComponentId, setCurrentComponentId] = useState<Nullable<string>>(null);
  const { isOpen, setIsOpen, onClose } = useOpenModal();
  const [fullComponentsList, dispatchComponentListAction] = useReducer<
    Reducer<Nullable<TRunDesignComponent[]>, TEditReducerAction>
  >(editReducer, null);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleChangeCurrentComponentId = (id: string) => setCurrentComponentId(id);

  const listRef = useRef(null);

  const openSettingsModal = useCallback((id: string) => {
    setCurrentComponentId(id);
    setIsOpen(true);
  }, []);

  // open via incubation count change trigger
  useEffect(() => {
    setIsOpen(!isInitialRender);
    setIsInitialRender(false);
  }, [incubationChangeTrigger]);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: listRef,
      isEditMode: someRunDesignCardIsEdit,
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig, listRef.current]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      isEditMode: someRunDesignCardIsEdit,
      cancel: {
        clickHandler: () => appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1)),
      },
    });
  }, [someRunDesignCardIsEdit]);

  const handleEditMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexEdit === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(newIndex));
    },
    [runDesignCardIndexEdit]
  );

  const handleExpandMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexExpand === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexExpand(newIndex));
    },
    [runDesignCardIndexExpand]
  );

  const reagentsCardList = useMemo(
    () =>
      componentList.map((component, componentIndex) => (
        <ReagentsCard
          key={component.id}
          component={component}
          handleEditMode={handleEditMode}
          handleExpandMode={handleExpandMode}
          runDesignCardIndex={componentIndex}
          isEditMode={runDesignCardIndexEdit === componentIndex}
          isExpandMode={runDesignCardIndexExpand === componentIndex}
          withWaves={withWaves}
          openSettingsModal={openSettingsModal}
        />
      )),
    [JSON.stringify(componentList), runDesignCardIndexEdit, runDesignCardIndexExpand, handleExpandMode, handleEditMode]
  );

  const activeCardIndex = useMemo(() => {
    if (runDesignCardIndexEdit >= 0) {
      return runDesignCardIndexEdit;
    }

    return runDesignCardIndexExpand;
  }, [runDesignCardIndexEdit, runDesignCardIndexExpand]);

  return (
    <>
      <SettingsModal
        open={isOpen}
        currentComponentId={currentComponentId}
        setCurrentComponentId={handleChangeCurrentComponentId}
        onClose={onClose}
        componentList={fullComponentsList}
        dispatchComponentListAction={dispatchComponentListAction}
      />

      {!componentList.length && <NoDataFound size="big" textData={noDataMessage} className="center-block" />}
      {!!componentList.length && (
        <ListWithActiveTab
          customListRef={listRef}
          activeCardIndex={activeCardIndex}
          cardContentList={reagentsCardList}
        />
      )}
    </>
  );
};

export default ReagentsForAssaysBlock;
