import { ComponentType, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { awsActions, awsSelectors } from '@/store/slices/aws';

import SetupTOTPModal from './SetupTOTPModal';

export function withTOTP(WrappedComponent: ComponentType<WithAuthenticatorProps>) {
  return function ComponentWithTOTP({ signOut, user }: WithAuthenticatorProps) {
    const appDispatch = useAppDispatch();

    const [mfaType, setMfaType] = useState('');

    const isNeedCheckMFA = useSelector(awsSelectors.selectIsNeedCheckMFA);
    const isSkippedMFA = useSelector(awsSelectors.selectIsSkippedMFA);

    const handleComplete = useCallback(async () => {
      await Auth.setPreferredMFA(user, 'TOTP');
      setMfaType('TOTP');
      appDispatch(awsActions.setCheckMFA(false));
    }, [user]);

    const handleSkip = useCallback(() => {
      appDispatch(awsActions.setCheckMFA(false));
    }, []);

    const checkMFA = useCallback(async () => {
      if (!user) {
        return;
      }
      try {
        const isMfaRequired = user.getSignInUserSession()?.getIdToken().payload?.mfa_required;
        appDispatch(awsActions.setSkippedMFA(!isMfaRequired));
        user.getUserData((_, userData) => {
          if (!userData) {
            Auth.signOut();
            return;
          }
          setMfaType(userData.PreferredMfaSetting);
        });
      } catch (err) {
        showErrorToast(getErrorMessage(err));
      }
    }, [user]);

    useEffect(() => {
      checkMFA();
    }, [user]);

    if (isNeedCheckMFA || (!mfaType && !isSkippedMFA)) {
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <SetupTOTPModal
            isModalOpen
            onComplete={handleComplete}
            onSkip={handleSkip}
            isRequired
            isSkipped={isSkippedMFA}
          />
        </div>
      );
    }

    return <WrappedComponent signOut={signOut} user={user} />;
  };
}
