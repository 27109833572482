import { FC } from 'react';
import classnames from 'classnames/bind';

import LanesTotal from '../../../components/LanesTotal';
import TypeOfSampleLoading from '../../../components/TypeOfSampleLoading';
import ComponentHeaderWrapper from '../../../components/ComponentHeaderWrapper';

import styles from './SampleInformationComponent.module.scss';

const cn = classnames.bind(styles);

const SampleInformationHeaderNew: FC = () => (
  <ComponentHeaderWrapper title="Sample loading">
    <LanesTotal isChangeAllowed className={cn('lanes')} />
    <div className={cn('type-sample-loading__wrapper')}>
      <TypeOfSampleLoading />
    </div>
  </ComponentHeaderWrapper>
);

export default SampleInformationHeaderNew;
