import { Suspense, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import gsap from 'gsap';
import Flip from 'gsap/Flip';
import { Tooltip } from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';

import { syncResize } from '@/helpers/resize';
import { ETooltipTypeMap } from '@/helpers/tooltip';
import { urlPostfixList, urlPostfixListNewFlow } from '@/helpers/runDesigns/constants';

import { useLinks } from '@/hooks/useLinks';

import { uiSelectors } from '@/store/slices/ui';

import { withJwtTokenListener } from '@/hoc/withJwtTokenListener';
import { withValidBrowser } from '@/hoc/withValidBrowser';
import { withTOTP } from '@/hoc/withTOTP';

import ConfirmationModalProvider from '@/components/common/ConfirmationModalProvider';
import PageLoader from '@/components/common/PageLoader';
import Layout from '@/components/Layout';
import LayoutV2 from '@/components/Layout/LayoutV2';
import LayoutEmpty from '@/components/Layout/LayoutEmpty';

import ProjectList from '@/pages/ProjectList';
import Project from '@/pages/Project';
import ExperimentsDashboard from '@/pages/ExperimentsDashboard';
import JobsDashboard from '@/pages/JobsDashboard';
import UsersDashboard from '@/pages/UsersDashboard';
import TeamsDashboard from '@/pages/TeamsDashboard';
import Experiment from '@/pages/Experiment';
import Analytics from '@/pages/Analytics';
import NotFoundPage from '@/pages/404';
import Dataset from '@/pages/Dataset';
import Changelog from '@/pages/Changelog';
import InstrumentsDashboard from '@/pages/InstrumentsDashboard';
import InstrumentDashboard from '@/pages/InstrumentDashboard';
import Home from '@/pages/Home';
import {
  DesignSelector,
  DesignDetails,
  DesignTimeline,
  SampleInformation,
  ReagentsForAssays,
  IncubationReagents,
  CagingSettings,
  OpticalSettings,
} from '@/pages/experiment-run-design';

import '@/assets/styles/markdown-light.css';
import './styles/global.scss';
import RunDetails from '@/pages/experiment-run-design/RunDetails';
import { store } from './store';
import { awsActions } from './store/slices/aws';
import { removeZeroWidthSpace } from './helpers';
import RunDesignDataLayoutWrapper from './pages/experiment-run-design-new-flow/components/RunDesignDataLayoutWrapper';
import DesignWorkflow from './pages/experiment-run-design-new-flow/DesignWorkflow';
import FlowCellSetup from './pages/experiment-run-design-new-flow/FlowCellSetup';
import DesignSelectorNewFlow from './pages/experiment-run-design-new-flow/DesignSelector';
import RunInformation from './pages/experiment-run-design-new-flow/RunInformation';

gsap.registerPlugin(Flip);

const App = () => {
  const location = useLocation();
  const { isDatasetPage } = useLinks();

  const [tooltipKey, setTooltipKey] = useState(location.key);
  const firstRender = useRef(true);

  useEffect(() => {
    if (!isDatasetPage) {
      setTooltipKey(location.key);
    }
  }, [location.key, isDatasetPage]);

  useEffect(() => {
    function onResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    onResize();
    syncResize.subscribe(onResize);

    return () => {
      syncResize.unsubscribe(onResize);
    };
  }, []);

  useEffect(() => {
    // Remove all the cookies that happens to be set by other app. If they're not deleted, CDN is confused what cookie to use.
    document.cookie = 'CloudFront-Signature=; domain=.cellanome.com; path=/; max-age=0;';
    document.cookie = 'CloudFront-Policy=; domain=.cellanome.com; path=/; max-age=0;';
    document.cookie = 'CloudFront-Key-Pair-Id=; domain=.cellanome.com; path=/; max-age=0;';
  }, []);

  const scrollEnabled = useSelector(uiSelectors.selectIsMainScrollEnabled);
  useEffect(() => {
    document.documentElement.classList.toggle('is-hidden', !scrollEnabled);
  }, [scrollEnabled]);

  useEffect(() => {
    const sanitizeClipboard = (e: ClipboardEvent) => {
      const selection = window.getSelection()?.toString();

      if (selection) {
        // Remove zero-width spaces added by addWordBreakAfterUnderscores function
        const sanitized = removeZeroWidthSpace(selection);
        e.clipboardData?.setData('text/plain', sanitized);
        e.preventDefault(); // Prevent default copy behavior
      }
    };

    document.addEventListener('copy', sanitizeClipboard);

    return () => {
      document.removeEventListener('copy', sanitizeClipboard);
    };
  }, []);

  return (
    <Suspense fallback={<PageLoader firstRender={firstRender} />}>
      <ConfirmationModalProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<ExperimentsDashboard />} />
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/project/:projectId" element={<Project />} />
            <Route path="/project/:projectId/experiment/:experimentId" element={<Experiment />} />
            <Route path="/experiment/:experimentId" element={<Experiment />} />
            <Route path="/project/:projectId/experiment/:experimentId/data/analytics" element={<Analytics />} />
            <Route path="/experiment/:experimentId/data/analytics" element={<Analytics />} />
            <Route path="/project/:projectId/experiment/:experimentId/data/analytics/dataset" element={<Dataset />} />
            <Route path="/experiment/:experimentId/data/analytics/dataset" element={<Dataset />} />
            <Route path="/changelog/:changelogId" element={<Changelog />} />
            <Route path="/jobs-dashboard" element={<JobsDashboard />} />
            <Route path="/users-dashboard" element={<UsersDashboard />} />
            <Route path="/teams-dashboard" element={<TeamsDashboard />} />
            <Route path="/instruments" element={<InstrumentsDashboard />} />
            <Route path="/instrument/:instrumentId" element={<InstrumentDashboard />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          <Route path="/" element={<LayoutV2 />}>
            <Route path="/home" element={<Home />} />
          </Route>

          <Route path="/" element={<LayoutEmpty />}>
            <Route path="/run-design" element={<DesignSelectorNewFlow />} />
            <Route path="/run-design" element={<RunDesignDataLayoutWrapper />}>
              <Route path=":sourceType/:sourceId" element={<RunInformation />} />
              <Route path=":sourceType/:sourceId">
                <Route path={urlPostfixListNewFlow.flowCellSetup} element={<FlowCellSetup />} />
                <Route path={urlPostfixListNewFlow.designWorkflow} element={<DesignWorkflow />} />
              </Route>
            </Route>
            <Route path="/design-experiment" element={<DesignSelector />} />
            <Route path="/design-experiment">
              <Route path=":sourceType/:sourceId" element={<DesignDetails />} />
              <Route path=":sourceType/:sourceId" element={<RunDetails />}>
                <Route path={urlPostfixList.timeline} element={<DesignTimeline />} />
                <Route path={urlPostfixList.sample} element={<SampleInformation />} />
                <Route path={urlPostfixList.reagents} element={<ReagentsForAssays />} />
                <Route path={urlPostfixList.incubation} element={<IncubationReagents />} />
                <Route path={urlPostfixList.cagingSettings} element={<CagingSettings />} />
                <Route path={urlPostfixList.opticalSettings} element={<OpticalSettings />} />
              </Route>
            </Route>
          </Route>
        </Routes>

        <Tooltip
          id={ETooltipTypeMap.default}
          opacity={1}
          clickable
          openEvents={{ mouseenter: true }}
          // a temporary solution to force re-render the tooltip when changing pages to ensure that the tooltip is displayed on the page
          key={`${tooltipKey}-default`}
        />
        <Tooltip
          id={ETooltipTypeMap.click}
          opacity={1}
          clickable
          // op
          openEvents={{ click: true }}
          // a temporary solution to force re-render the tooltip when changing pages to ensure that the tooltip is displayed on the page
          key={`${tooltipKey}-click`}
        />
      </ConfirmationModalProvider>
    </Suspense>
  );
};

export default withValidBrowser(
  withJwtTokenListener(
    withAuthenticator(withTOTP(App), {
      hideSignUp: true,
      loginMechanisms: ['email'],
      variation: 'modal',
      services: {
        handleSignIn: ({ password, username }) => {
          store.dispatch(awsActions.setCheckMFA(true));
          return Auth.signIn(username.toLowerCase(), password);
        },
        handleConfirmSignIn: async ({ code, mfaType, user }) => {
          store.dispatch(awsActions.setCheckMFA(false));
          return Auth.confirmSignIn(user, code, mfaType as Nullable<'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'>);
        },
        handleForgotPassword: (username) => Auth.forgotPassword(username.toLowerCase()),
        handleForgotPasswordSubmit: ({ username, code, password }) =>
          Auth.forgotPasswordSubmit(username.toLowerCase(), code, password),
      },
    })
  )
);
