import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import AdvancedButton from '@/pages/experiment-run-design-new-flow/components/AdvancedButton';

import styles from './CellCageBaseBlock.module.scss';

const cn = classnames.bind(styles);

const CellCageAdvancedButton: FC = () => {
  const appDispatch = useAppDispatch();
  const isAdvancedMode = useSelector(experimentRunDesignSelectors.selectIsAdvancedMode);

  const handleViewAdvancedClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setIsAdvancedMode(!isAdvancedMode));
  }, [isAdvancedMode]);
  return <AdvancedButton className={cn('advanced')} isAdvanced={isAdvancedMode} onClick={handleViewAdvancedClick} />;
};

export default CellCageAdvancedButton;
