import { FC, ReactNode } from 'react';

import { TDefaultPageControlsConfig } from './PageControls/types';
import FooterSaveCancelActions from './PageControls/FooterSaveCancelActions';
import PageControls from './PageControls';

type TPageFooterProps = {
  config?: TDefaultPageControlsConfig;
  renderFooter?: Nullable<(props: TDefaultPageControlsConfig) => ReactNode>;
};

const PageFooter: FC<TPageFooterProps> = ({ renderFooter, config }) => {
  if (!config) {
    return null;
  }

  if (renderFooter) {
    return renderFooter(config);
  }

  // TODO: NEWFLOW need remove FooterSaveCancelActions and PageControls
  if (config.isEditMode) {
    return <FooterSaveCancelActions config={config} />;
  }

  return <PageControls config={config} />;
};

export default PageFooter;
